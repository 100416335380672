import { ActionTree } from "vuex";
import { compareAsc } from "date-fns";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruBatch } from "@/core/modules/batch";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { MapInterface } from "@/features/modules/map/models/Map.interface";
import { mapStore } from "@/features/modules/map/store";
import { mapStoreTypes } from "@/features/modules/map/store/types";
import { SettingInterface } from "@/features/modules/setting/models/Setting.interface";
import { settingStore } from "@/features/modules/setting/store";
import { settingStoreTypes } from "@/features/modules/setting/store/types";
import { TemplateInterface } from "@/features/modules/template/models/Template.interface";
import { templateStore } from "@/features/modules/template/store";
import { templateStoreTypes } from "@/features/modules/template/store/types";
import { VariableInterface } from "@/features/modules/variable/models/Variable.interface";
import { variableStore } from "@/features/modules/variable/store";
import { variableStoreTypes } from "@/features/modules/variable/store/types";
import { WebRenderInterface, webRenderToFirestore } from "@/features/modules/web-render/models/WebRender.interface";
import { webRenderStore } from "@/features/modules/web-render/store";
import { webRenderStoreTypes } from "@/features/modules/web-render/store/types";
import { WorkCategoryInterface } from "../models/WorkCategory.interface";
import { WorkCategoryModelInterface } from "../models/WorkCategoryModel.interface";
import { workCategoryStoreTypes } from "./types";
import { WorkInterface } from "@/features/modules/work/models/Work.interface";
import { WorkModelInterface } from "@/features/modules/work/models/WorkModel.interface";
import { workStore } from "@/features/modules/work/store";
import { workStoreTypes } from "@/features/modules/work/store/types";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";
import { setWebDocumentFields } from "@/features/modules/web-document";

import { processWorksPage } from "@/features/modules/work/store/actions";

import { firebaseFirestore } from "@/core/plugins/firebase";
import { createWebRenderFromWebDocument } from "@/features/modules/helpers";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [workCategoryStoreTypes.actions.getWorkCategories](): Promise<WorkCategoryInterface[]> {
    try {
      return await koruDb.getModule<WorkCategoryModelInterface>("workCategories").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workCategoryStoreTypes.actions.getWorkCategory](context, workCategoryId: string): Promise<WorkCategoryInterface> {
    try {
      return await koruDb.getModule<WorkCategoryModelInterface>("workCategories").getDocument(workCategoryId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workCategoryStoreTypes.actions.createWorkCategory](context, workCategory: WorkCategoryInterface): Promise<string> {
    try {
      await setWebDocumentFields(workCategory);
      setDocumentFields(workCategory.id, workCategory);

      workCategory.id = await koruDb.getModule<WorkCategoryModelInterface>("workCategories").createDocument(workCategory, true);

      await context.dispatch(workCategoryStoreTypes.actions.processWorkCategoryUpdate, workCategory);

      return workCategory.id;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workCategoryStoreTypes.actions.updateWorkCategory](context, workCategory: WorkCategoryInterface): Promise<void> {
    try {
      const oldWorkCategory: WorkCategoryInterface = await koruDb
        .getModule<WorkCategoryModelInterface>("workCategories")
        .getDocument(workCategory.id);
      if (hasDocumentChanged(workCategory, oldWorkCategory)) throw new Error("sync");

      await setWebDocumentFields(workCategory);
      setDocumentFields(workCategory.id, workCategory);
      await koruDb.getModule<WorkCategoryModelInterface>("workCategories").updateDocument(workCategory, true);

      await context.dispatch(workCategoryStoreTypes.actions.processWorkCategoryUpdate, workCategory);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workCategoryStoreTypes.actions.processWorkCategoryUpdate](context, workCategory: WorkCategoryInterface): Promise<void> {
    try {
      const payload: Record<string, unknown> = {
        webDocument: workCategory,
        contentRenderer: await createWorkCategoryContentRenderer(workCategory.id),
      };
      const updatedWorkCategory: WorkCategoryInterface = (await webRenderStore.action(
        webRenderStoreTypes.actions.processWebRenderForWebDocument,
        payload
      )) as WorkCategoryInterface;

      await koruDb.getModule<WorkCategoryModelInterface>("workCategories").updateDocument(updatedWorkCategory, false);

      const works: WorkInterface[] = await koruDb.getModule<WorkModelInterface>("works").getWorksByWorkCategory(workCategory.id);
      await workStore.action(workStoreTypes.actions.processWorksUpdate, works);

      await processWorksPage();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workCategoryStoreTypes.actions.processWorkCategoriesUpdate](context, workCategories: WorkCategoryInterface[]): Promise<void> {
    try {
      const templates: TemplateInterface[] = (await templateStore.action(templateStoreTypes.actions.getTemplates)) as TemplateInterface[];
      const variables: VariableInterface[] = (await variableStore.action(variableStoreTypes.actions.getVariables)) as VariableInterface[];
      const maps: MapInterface[] = (await mapStore.action(mapStoreTypes.actions.getMaps)) as MapInterface[];

      const settings: SettingInterface = (await settingStore.action(settingStoreTypes.actions.getSetting)) as SettingInterface;

      const koruBatch = new KoruBatch();

      for (const workCategory of workCategories) {
        const result: Record<string, unknown> = await createWebRenderFromWebDocument(
          workCategory,
          templates,
          variables,
          maps,
          settings,
          await createWorkCategoryContentRenderer(workCategory.id),
          undefined
        );

        const webRenders: WebRenderInterface[] = result.webRenders as WebRenderInterface[];

        for (const webRender of webRenders) {
          if (webRender.id == "new") {
            koruBatch.set(firebaseFirestore.collection("webRenders").doc(), webRenderToFirestore(webRender));
          } else {
            koruBatch.update(firebaseFirestore.collection("webRenders").doc(webRender.id), webRenderToFirestore(webRender));
          }
        }
      }

      await koruBatch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workCategoryStoreTypes.actions.deleteWorkCategory](context, workCategory: WorkCategoryInterface): Promise<void> {
    try {
      const works = (await workStore.action(workStoreTypes.actions.getWorksByWorkCategory, workCategory.id)) as WorkInterface[];
      if (works.length > 0) throw new Error("children");

      await koruDb.getModule<WorkCategoryModelInterface>("workCategories").deleteDocument(workCategory, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workCategoryStoreTypes.actions.reorderWorkCategories](context, workCategories: WorkCategoryInterface[]): Promise<void> {
    try {
      await koruDb.getModule<WorkCategoryModelInterface>("workCategories").reorderWorkCategories(workCategories);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

export async function createWorkCategoryContentRenderer(workCategoryId: string): Promise<(language: string) => Promise<string>> {
  const works: WorkInterface[] = (await workStore.action(workStoreTypes.actions.getWorksByWorkCategory, workCategoryId)) as WorkInterface[];

  return async (language: string): Promise<string> => {
    let markup = "";

    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);

    for (const work of works) {
      if (work.publicAt !== undefined && compareAsc(work.publicAt, today) > 0) continue;

      markup += '<div class="col-lg-4 col-md-6">';
      markup += '<div class="single__gallery__thumb" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">';
      markup += `<a href="${work.url[language]}" id="item__link"></a>`;
      markup += '<div class="rario ratio-4x3">';
      markup += `<img src="${work.featuredImage?.url}" alt="">`;
      markup += "</div>";
      markup += '<div class="gallery__overlay">';
      markup += '<div class="overlay__text__wrap">';
      markup += "<span></span>";
      markup += '<div class="gallery__text">';
      markup += `<p>${work.workCategory?.title[language]}</p>`;
      markup += `<p>${work.title[language]}</p>`;
      markup += "</div>";
      markup += "</div>";
      markup += "</div>";
      markup += "</div>";
      markup += "</div>";
    }

    return Promise.resolve(markup);
  };
}
