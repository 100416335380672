export const itFeaturesLabels = {
  buttons: {
    applyToOtherLanguages: "Applica ad altre lingue",
    goToPoints: "Vai ai Punti",
  },
  navigation: {
    blocks: "Blocchi",
    content: "Contenuto",
    maps: "Mappe",
    pages: "Pagine",
    portfolio: "Portfolio",
    settings: "Impostazioni",
    structure: "Struttura",
    templates: "Modelli",
    variables: "Variabili",
    works: "Lavori",
    workCategories: "Categorie",
  },
  roleModules: {
    block: "Blocchi",
    map: "Mappe",
    mapPoint: "Mappe - Punti",
    page: "Pagine",
    setting: "Impostazioni",
    template: "Modelli",
    variable: "Variabili",
    workCategory: "Portfolio - Categorie",
    work: "Portfolio - Lavori",
  },
  toast: {
    error: {
      deleteImage: "Impossibile eliminare l'immagine",
      geocodeError: "Impossibile trovare l'indirizzo",
    },
    success: {
      deleteImage: "Immagine eliminata con successo",
    },
  },
};
