import { createStoreModule } from "@/core/modules/helpers";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";
import { BackupStateInterface } from "../models/BackupState.interface";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";
import { mutations } from "./mutations";

export const backupStore: KoruStoreModuleInterface<BackupStateInterface> = createStoreModule(
  "backupState",
  initialState,
  getters,
  actions,
  mutations
);

export const useBackupStore = (): KoruStoreModuleInterface<BackupStateInterface> => {
  return backupStore;
};
