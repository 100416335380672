
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useRoute } from "vue-router";

import { DocumentInterface } from "@/core/modules/database/models/Document.interface";
import { koruStoreTypes } from "@/core/modules/store/types";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";

import { getObjectValueWithField, tryOrError, tryOrToast, userCanCreate, userCanDelete, userCanRead, userCanUpdate } from "@/core/modules/helpers";
import { RowCommandInterface } from "@/core/modules/layout/models/RowCommand.interface";

export default defineComponent({
  name: "KoruBrowser",
  props: {
    canCreate: { type: Boolean, default: true },
    canDelete: { type: Boolean, default: true },
    canEdit: { type: Boolean, default: true },
    canView: { type: Boolean, default: true },
    deleteDescriptionField: { type: String, default: undefined },
    // eslint-disable-next-line prettier/prettier
    deleteAction: { type: Function, default: () => { return; } },
    // eslint-disable-next-line @typescript-eslint/no-array-constructor
    filterFields: { type: Array, default: new Array<string>() },
    filters: { type: Object, default: new Object() },
    forceStateFields: { type: Array, default: undefined },
    // eslint-disable-next-line prettier/prettier
    loadAction: { type: Function, default: () => { return; } },
    objectName: { type: String, default: "" },
    parentQuery: { type: String, default: "" },
    path: { type: String, default: "" },
    // eslint-disable-next-line @typescript-eslint/no-array-constructor
    rowCommands: { type: Array, default: new Array() },
    sortField: { type: String, default: "" },
    sortOrder: { type: Number, default: 1 },
    suffix: { type: String, default: "list" },
    // eslint-disable-next-line @typescript-eslint/no-array-constructor
    values: { type: Array, default: new Array() },
  },
  emits: ["update:filters", "update:values"],
  setup(props, { emit }) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();

    const route = useRoute();
    const requiredModule: string = route.meta.requiredModule as string;

    const valuesRef = computed({
      get: () => props.values,
      set: (val) => emit("update:values", val),
    });

    const filterFieldsRef: Ref<string[]> = ref(props.filterFields as string[]);
    const filtersRef = computed({
      get: () => props.filters,
      set: (val) => emit("update:filters", val),
    });

    const filtersLength = computed(() => {
      return Object.keys(filtersRef.value as Record<string, unknown>).length;
    });

    onMounted(async () => {
      await tryOrError(
        async () => {
          await props.loadAction();

          if (props.forceStateFields != undefined) {
            props.forceStateFields.forEach((item: unknown) => {
              const forceStateField = item as Record<string, string>;
              filtersRef.value[forceStateField.field].value = forceStateField.value;
            });
          }
        },
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    });

    const deleteAction = async (item: Record<string, unknown>) => {
      await tryOrToast(
        async () => {
          koruStore.commit(koruStoreTypes.mutations.loadingStart);
          await props.deleteAction(item);

          const deleteIndex = valuesRef.value.indexOf(item, 0);
          if (deleteIndex > -1) {
            valuesRef.value.splice(deleteIndex, 1);
          }
        },
        "delete",
        t,
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    };

    const clearFilters = () => {
      filtersRef.value["global"].value = null;
      filterFieldsRef.value.forEach((field: unknown) => {
        if (filtersRef.value[field as string] != undefined) {
          filtersRef.value[field as string].value = undefined;
          if (filtersRef.value[field as string].constraints) {
            for (const constraint of filtersRef.value[field as string].constraints) {
              constraint.value = undefined;
            }
            filtersRef.value[field as string].constraints.length = 1;
          }
        }
      });
      Object.keys(filtersRef.value).forEach((key: string) => {
        filtersRef.value[key].value = undefined;
      });
    };

    const showDeleteItemDialog = ref(false);
    const deleteItem = ref({});

    const deleteItemAction = () => {
      showDeleteItemDialog.value = false;
      deleteAction(deleteItem.value);
    };

    const getRowCommands = (document: DocumentInterface) => {
      const renderRowCommands: Record<string, unknown>[] = [];

      if (props.canEdit && (requiredModule == undefined || userCanUpdate(requiredModule, document))) {
        renderRowCommands.push({
          label: "buttons.edit",
          icon: "pi pi-pencil",
          command: (object: Record<string, unknown>) => {
            koruStore.dispatch(
              koruStoreTypes.actions.navigateTo,
              props.path + "/" + (object.reference as Record<string, unknown>).id + props.parentQuery
            );
          },
        });
      } else if (props.canView && (requiredModule == undefined || userCanRead(requiredModule, document))) {
        renderRowCommands.push({
          label: "buttons.view",
          icon: "pi pi-eye",
          command: (object: Record<string, unknown>) => {
            koruStore.dispatch(
              koruStoreTypes.actions.navigateTo,
              props.path + "/" + (object.reference as Record<string, unknown>).id + props.parentQuery
            );
          },
        });
      }

      if (props.canDelete && (requiredModule == undefined || userCanDelete(requiredModule, document))) {
        renderRowCommands.push({
          label: "buttons.delete",
          icon: "pi pi-trash",
          command: (object: Record<string, unknown>) => {
            deleteItem.value = object.reference as Record<string, unknown>;
            showDeleteItemDialog.value = true;
          },
        });
      }

      const rowCommandsLength = renderRowCommands.length;
      props.rowCommands.forEach((rowCommand: unknown) => {
        const rowCommandObject: RowCommandInterface = rowCommand as RowCommandInterface;
        if (rowCommandObject.condition(document) == true) {
          if (renderRowCommands.length == rowCommandsLength) {
            renderRowCommands.push({ label: "separator" });
          }
          renderRowCommands.push(rowCommand as Record<string, unknown>);
        }
      });

      return renderRowCommands;
    };

    return {
      clearFilters,
      deleteItemAction,
      deleteItem,
      filterFieldsRef,
      filtersLength,
      filtersRef,
      getObjectValueWithField,
      getRowCommands,
      koruStore,
      koruStoreTypes,
      requiredModule,
      showDeleteItemDialog,
      t,
      userCanCreate,
    };
  },
});
