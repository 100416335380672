import { ActionTree } from "vuex";

import { BlockInterface } from "../models/Block.interface";
import { BlockModelInterface } from "../models/BlockModel.interface";
import { blockStoreTypes } from "./types";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { TemplateInterface } from "@/features/modules/template/models/Template.interface";
import { templateStore } from "@/features/modules/template/store";
import { templateStoreTypes } from "@/features/modules/template/store/types";
import { webRenderStore } from "@/features/modules/web-render/store";
import { webRenderStoreTypes } from "@/features/modules/web-render/store/types";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [blockStoreTypes.actions.getBlocks](): Promise<BlockInterface[]> {
    try {
      return await koruDb.getModule<BlockModelInterface>("blocks").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [blockStoreTypes.actions.getBlock](context, blockId: string): Promise<BlockInterface> {
    try {
      return await koruDb.getModule<BlockModelInterface>("blocks").getDocument(blockId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [blockStoreTypes.actions.createBlock](context, block: BlockInterface): Promise<string> {
    try {
      setDocumentFields(block.id, block);
      return await koruDb.getModule<BlockModelInterface>("blocks").createDocument(block, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [blockStoreTypes.actions.updateBlock](context, block: BlockInterface): Promise<void> {
    try {
      const oldBlock: BlockInterface = await koruDb.getModule<BlockModelInterface>("blocks").getDocument(block.id);
      if (hasDocumentChanged(block, oldBlock)) throw new Error("sync");

      setDocumentFields(block.id, block);
      await koruDb.getModule<BlockModelInterface>("blocks").updateDocument(block, true);

      await webRenderStore.action(webRenderStoreTypes.actions.processWebRenderForBlockUpdate, block.id);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [blockStoreTypes.actions.deleteBlock](context, block: BlockInterface): Promise<void> {
    try {
      const templates = (await templateStore.action(templateStoreTypes.actions.getTemplatesByBlock, block.id)) as TemplateInterface[];
      if (templates.length > 0) throw new Error("children");

      await koruDb.getModule<BlockModelInterface>("blocks").deleteDocument(block, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [blockStoreTypes.actions.reorderBlocks](context, blocks: BlockInterface[]): Promise<void> {
    try {
      await koruDb.getModule<BlockModelInterface>("blocks").reorderBlocks(blocks);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
