
import { computed, defineComponent } from "vue";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { useAuthStore } from "@/core/modules/auth/store";
import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruRouter } from "@/core/modules/router";
import { UserInterface } from "@/core/modules/user/models/User.interface";

import LocaleSelector from "@/core/modules/locale/components/locale-selector.vue";
import NotificationSelector from "@/core/modules/notification/components/notification-selector.vue";
import ThemeSelector from "@/core/modules/theme/components/theme-selector.vue";

export default defineComponent({
  name: "TopBar",
  components: {
    LocaleSelector,
    NotificationSelector,
    ThemeSelector,
  },
  emits: [
    "menu-button-click",
    "search-click",
    "topbar-notification",
    "topbar-user-menu",
    "topbar-lang-menu",
    "topbar-theme-menu",
    "right-menubutton-click",
  ],
  props: {
    topbarNotificationMenuActive: Boolean,
    topbarUserMenuActive: Boolean,
    topbarLangMenuActive: Boolean,
    topbarThemeMenuActive: Boolean,
    colorScheme: String,
  },
  setup() {
    const koruConfig = useKoruConfig();
    const koruRouter = useKoruRouter();
    const authStore = useAuthStore();
    const { t } = useKoruLocale();

    const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
    const username = computed(() => {
      return user?.firstName || t("gen.guest");
    });
    const logoutAction = () => {
      authStore.action(authStoreTypes.actions.logout).then(() => {
        koruRouter.push("/login");
      });
    };

    return {
      koruConfig,
      logoutAction,
      t,
      user,
      username,
    };
  },
  methods: {
    onMenuButtonClick(event: Event): void {
      this.$emit("menu-button-click", event);
    },
    onSearchClick(event: Event): void {
      this.$emit("search-click", event);
    },
    onTopbarNotificationMenuButtonClick(event: Event): void {
      this.$emit("topbar-notification", event);
    },
    onTopbarUserMenuButtonClick(event: Event): void {
      this.$emit("topbar-user-menu", event);
    },
    onTopbarThemeMenuButtonClick(event: Event): void {
      this.$emit("topbar-theme-menu", event);
    },
    onTopbarLangMenuButtonClick(event: Event): void {
      this.$emit("topbar-lang-menu", event);
    },
    onRightMenuClick(event: Event): void {
      this.$emit("right-menubutton-click", event);
    },
  },
});
