import { RouteRecordRaw } from "vue-router";

export const mapPointRoutes: Array<RouteRecordRaw> = [
  {
    path: "/maps/:mapId/points",
    name: "MapPointList",
    component: () => import("../views/MapPointList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "mapPoint",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/maps/:mapId/points/:mapPointId",
    name: "MapPointEdit",
    component: () => import("../views/MapPointEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "mapPoint",
      requiredRights: ["readAll", "readOwn", "updateAll", "updateOwn"],
    },
  },
];
