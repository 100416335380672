import { createApp, h } from "vue";

import { koruAuth } from "@/core/modules/auth";
import { koruLocale } from "@/core/modules/locale";
import { KoruLayout } from "@/core/modules/layout";
import { koruRouter } from "@/core/modules/router";
import { koruStore } from "@/core/modules/store";
import { registerDatabaseModule, registerRoutes, registerStoreModule, resetLocalStorageIfNeeded } from "@/core/modules/helpers";

import { authRoutes } from "@/core/modules/auth/router";
import { backupRoutes } from "@/core/modules/backup/router";
import { blockRoutes } from "@/features/modules/block/router";
import { changelogRoutes } from "@/core/modules/changelog/router";
import { dashboardRoutes } from "@/features/modules/dashboard/router";
import { feedbackRoutes } from "@/core/modules/feedback/router";
import { installerRoutes } from "@/core/modules/installer/router";
import { logRoutes } from "@/core/modules/log/router";
import { mapRoutes } from "@/features/modules/map/router";
import { mapPointRoutes } from "@/features/modules/map-point/router";
import { notificationRoutes } from "@/core/modules/notification/router";
import { pageRoutes } from "@/features/modules/page/router";
import { profileRoutes } from "@/core/modules/profile/router";
import { roleRoutes } from "@/core/modules/role/router";
import { settingRoutes } from "@/features/modules/setting/router";
import { templateRoutes } from "@/features/modules/template/router";
import { userRoutes } from "@/core/modules/user/router";
import { variableRoutes } from "@/features/modules/variable/router";
import { whoRoutes } from "@/core/modules/who/router";
import { workbenchRoutes } from "@/core/modules/workbench/router";
import { workCategoryRoutes } from "@/features/modules/work-category/router";
import { workRoutes } from "@/features/modules/work/router";

import { useAuthStore } from "@/core/modules/auth/store";
import { useBackupStore } from "@/core/modules/backup/store";
import { useBlockStore } from "@/features/modules/block/store";
import { useChangelogStore } from "@/core/modules/changelog/store";
import { useFeedbackStore } from "@/core/modules/feedback/store";
import { useInstallerStore } from "@/core/modules/installer/store";
import { useLocaleStore } from "@/core/modules/locale/store";
import { useLogStore } from "@/core/modules/log/store";
import { useMapStore } from "@/features/modules/map/store";
import { useMapPointStore } from "@/features/modules/map-point/store";
import { useNotificationStore } from "@/core/modules/notification/store";
import { usePageStore } from "@/features/modules/page/store";
import { useRoleStore } from "@/core/modules/role/store";
import { useSectionStore } from "@/features/modules/section/store";
import { useSettingStore } from "@/features/modules/setting/store";
import { useTemplateStore } from "@/features/modules/template/store";
import { useThemeStore } from "@/core/modules/theme/store";
import { useUserStore } from "@/core/modules/user/store";
import { useVariableStore } from "@/features/modules/variable/store";
import { useWebRenderStore } from "@/features/modules/web-render/store";
import { useWorkbenchStore } from "@/core/modules/workbench/store";
import { useWorkCategoryStore } from "@/features/modules/work-category/store";
import { useWorkStore } from "@/features/modules/work/store";

import { backupModel } from "@/core/modules/backup/models/firestore/Backup.model";
import { blockModel } from "@/features/modules/block/models/firestore/Block.model";
import { changelogModel } from "@/core/modules/changelog/models/firestore/Changelog.model";
import { feedbackModel } from "@/core/modules/feedback/models/firestore/Feedback.model";
import { logModel } from "@/core/modules/log/models/firestore/Log.model";
import { mapModel } from "@/features/modules/map/models/firestore/Map.model";
import { mapPointModel } from "@/features/modules/map-point/models/firestore/MapPoint.model";
import { notificationModel } from "@/core/modules/notification/models/firestore/Notification.model";
import { pageModel } from "@/features/modules/page/models/firestore/Page.model";
import { roleModel } from "@/core/modules/role/models/firestore/Role.model";
import { settingModel } from "@/features/modules/setting/models/firestore/Setting.model";
import { templateModel } from "@/features/modules/template/models/firestore/Template.model";
import { userModel } from "@/core/modules/user/models/firestore/User.model";
import { variableModel } from "@/features/modules/variable/models/firestore/Variable.model";
import { webRenderModel } from "@/features/modules/web-render/models/firestore/WebRender.model";
import { workCategoryModel } from "@/features/modules/work-category/models/firestore/WorkCategory.model";
import { workModel } from "@/features/modules/work/models/firestore/Work.model";

import Base from "@/core/layout/Base.vue";
import Bedrock from "@/core/layout/Bedrock.vue";

/////////////////////////////////////////////////////////////
//                       create app                        //
/////////////////////////////////////////////////////////////
export const koruApp = createApp({
  computed: {
    ViewComponent() {
      switch (this.$route.name) {
        case "Login":
          return Base;
        case "Forgot":
          return Base;
        case "Recover":
          return Base;
        case "NotFound":
          return Base;
        case "AccessDenied":
          return Base;
        case "Error":
          return Base;
        case "Installer":
          return Base;
        case undefined:
          return Base;
        default:
          return Bedrock;
      }
    },
  },
  render() {
    return h(this.ViewComponent);
  },
});

/////////////////////////////////////////////////////////////
//                     register routes                     //
/////////////////////////////////////////////////////////////
registerRoutes(authRoutes);
registerRoutes(backupRoutes);
registerRoutes(changelogRoutes);
registerRoutes(feedbackRoutes);
registerRoutes(installerRoutes);
registerRoutes(logRoutes);
registerRoutes(notificationRoutes);
registerRoutes(profileRoutes);
registerRoutes(roleRoutes);
registerRoutes(userRoutes);
registerRoutes(whoRoutes);
registerRoutes(workbenchRoutes);

registerRoutes(blockRoutes);
registerRoutes(dashboardRoutes);
registerRoutes(mapRoutes);
registerRoutes(mapPointRoutes);
registerRoutes(pageRoutes);
registerRoutes(settingRoutes);
registerRoutes(templateRoutes);
registerRoutes(variableRoutes);
registerRoutes(workCategoryRoutes);
registerRoutes(workRoutes);

/////////////////////////////////////////////////////////////
//                register database modules                //
/////////////////////////////////////////////////////////////
registerDatabaseModule(backupModel);
registerDatabaseModule(changelogModel);
registerDatabaseModule(feedbackModel);
registerDatabaseModule(logModel);
registerDatabaseModule(notificationModel);
registerDatabaseModule(roleModel);
registerDatabaseModule(userModel);

registerDatabaseModule(blockModel);
registerDatabaseModule(mapModel);
registerDatabaseModule(mapPointModel);
registerDatabaseModule(pageModel);
registerDatabaseModule(settingModel);
registerDatabaseModule(templateModel);
registerDatabaseModule(variableModel);
registerDatabaseModule(webRenderModel);
registerDatabaseModule(workCategoryModel);
registerDatabaseModule(workModel);

/////////////////////////////////////////////////////////////
//                 register store modules                  //
/////////////////////////////////////////////////////////////
registerStoreModule(useAuthStore());
registerStoreModule(useBackupStore());
registerStoreModule(useChangelogStore());
registerStoreModule(useFeedbackStore());
registerStoreModule(useInstallerStore());
registerStoreModule(useLocaleStore());
registerStoreModule(useLogStore());
registerStoreModule(useNotificationStore());
registerStoreModule(useRoleStore());
registerStoreModule(useThemeStore());
registerStoreModule(useUserStore());
registerStoreModule(useWorkbenchStore());

registerStoreModule(useBlockStore());
registerStoreModule(useMapStore());
registerStoreModule(useMapPointStore());
registerStoreModule(usePageStore());
registerStoreModule(useSectionStore());
registerStoreModule(useSettingStore());
registerStoreModule(useTemplateStore());
registerStoreModule(useVariableStore());
registerStoreModule(useWebRenderStore());
registerStoreModule(useWorkCategoryStore());
registerStoreModule(useWorkStore());

/////////////////////////////////////////////////////////////
//                          boot                           //
/////////////////////////////////////////////////////////////
async function boot() {
  try {
    await koruAuth.init();

    if (koruApp.config.globalProperties.mounted !== true) {
      KoruLayout.init(koruApp);

      koruApp.use(koruLocale);
      koruApp.use(koruRouter);
      koruApp.use(koruStore);

      koruApp.mount("#app");
      koruApp.config.globalProperties.mounted = true;

      resetLocalStorageIfNeeded();
    }
  } catch {
    console.log("Unable to boot KORU");
  }
}

boot();
