import { GetterTree } from "vuex";

import { BackupStateInterface } from "../models/BackupState.interface";
import { backupStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";

export const getters: GetterTree<BackupStateInterface, KoruStateInterface> = {
  [backupStoreTypes.getters.getUploadedData]: (state: BackupStateInterface): string | undefined => {
    return state.uploadedData;
  },
};
