import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";
import {
  LinkedTemplateInterface,
  linkedTemplateFromFirestore,
  linkedTemplateToFirestore,
} from "@/features/modules/template/models/LinkedTemplate.interface";

export interface WebDocumentInterface extends DocumentInterface {
  name: string;
  template: LinkedTemplateInterface | undefined;
  title: Record<string, string>;
  slug: Record<string, string>;
  content: Record<string, string>;
  metaKeywords: Record<string, string[]> | undefined;
  metaDescription: Record<string, string> | undefined;
  url: Record<string, string>;
  publicAt: Date | undefined;
}

export const createWebDocument = <T>(data: Record<string, unknown>): T => {
  const newWebDocument: WebDocumentInterface = createDocument<WebDocumentInterface>({
    name: "",
    template: undefined,
    title: {},
    slug: {},
    metaKeywords: {},
    metaDescription: {},
    url: {},
    publicAt: undefined,
  });

  return Object.assign(data, newWebDocument) as T;
};

export function webDocumentFromFirestore<T extends WebDocumentInterface>(
  id: string,
  data: Record<string, unknown>,
  document: Record<string, unknown>
): T {
  const webDocument: WebDocumentInterface = documentFromFirestore<WebDocumentInterface>(
    id,
    {
      name: document.name as string,
      template: document.template != null ? linkedTemplateFromFirestore(document.template as Record<string, unknown>) : undefined,
      title: document.title || {},
      slug: document.slug || {},
      metaKeywords: document.metaKeywords != null ? document.metaKeywords : undefined,
      metaDescription: document.metaDescription != null ? document.metaDescription : undefined,
      url: document.url || {},
      publicAt: document.publicAt != null ? (document.publicAt as firebase.firestore.Timestamp).toDate() : undefined,
    },
    document
  );

  return Object.assign(data, webDocument) as T;
}

export function webDocumentToFirestore(data: Record<string, unknown>, webDocument: WebDocumentInterface): Record<string, unknown> {
  const toFirestore: Record<string, unknown> = documentToFirestore(
    {
      name: webDocument.name,
      template: webDocument.template != undefined ? linkedTemplateToFirestore(webDocument.template) : null,
      title: webDocument.title || null,
      slug: webDocument.slug || null,
      metaKeywords: webDocument.metaKeywords || null,
      metaDescription: webDocument.metaDescription || null,
      url: webDocument.url || null,
      publicAt: webDocument.publicAt != undefined ? firebase.firestore.Timestamp.fromDate(webDocument.publicAt) : null,
    },
    webDocument
  );

  return Object.assign(toFirestore, data);
}
