import { MutationTree } from "vuex";

import { BackupStateInterface } from "../models/BackupState.interface";
import { backupStoreTypes } from "./types";

export const mutations: MutationTree<BackupStateInterface> = {
  [backupStoreTypes.mutations.resetUploadedData]: (state: BackupStateInterface): void => {
    state.uploadedData = undefined;
  },
  [backupStoreTypes.mutations.setUploadedData]: (state: BackupStateInterface, data: string): void => {
    state.uploadedData = data;
  },
};
