import { ActionTree } from "vuex";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { firebaseFirestore, firebaseFunctions } from "@/core/plugins/firebase";

import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { WorkbenchStateInterface } from "../models/WorkbenchState.interface";
import { workbenchStoreTypes } from "./types";

import { exportBackup, importBackup } from "@/core/plugins/import-export";

export const actions: ActionTree<WorkbenchStateInterface, KoruStateInterface> = {
  async [workbenchStoreTypes.actions.exportData](context, values: { collections: string[]; selectedCollections: string[] }): Promise<void> {
    try {
      if (values.selectedCollections.length <= 0) {
        console.log("No collections");
        return;
      }
      const data: unknown = await exportBackup(values.selectedCollections);
      const jsonString: string = JSON.stringify(data, undefined, 2);

      const link = document.createElement("a");
      link.download =
        values.selectedCollections.length == values.collections.length ? "all-collections.json" : values.selectedCollections.join("-") + ".json";
      const blob = new Blob([jsonString], { type: "application/json" });
      link.href = window.URL.createObjectURL(blob);
      link.click();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workbenchStoreTypes.actions.importData](context): Promise<void> {
    try {
      const options = { autoParseDates: true, autoParseGeos: false, showLogs: true };
      const uploadedData: string | undefined = await context.getters[workbenchStoreTypes.getters.getUploadedData];
      if (uploadedData == undefined) {
        console.log("Data not set!");
        return;
      }
      // const result: { status: boolean; message: string }
      await importBackup(JSON.parse(uploadedData), options);
    } catch (error: unknown) {
      context.commit(workbenchStoreTypes.mutations.resetUploadedData);
      throw new Error((error as Error).message);
    } finally {
      context.commit(workbenchStoreTypes.mutations.resetUploadedData);
    }
  },
  async [workbenchStoreTypes.actions.deleteData](context, selectedCollections: string[]): Promise<void> {
    try {
      const batch = firebaseFirestore.batch();

      for (const collection of selectedCollections) {
        const snapshot: firebase.firestore.QuerySnapshot = await firebaseFirestore.collection(collection).get();
        if (!snapshot.empty) {
          snapshot.docs.forEach((doc: firebase.firestore.DocumentData) => {
            batch.delete(doc.ref);
          });
        }
      }
      await batch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workbenchStoreTypes.actions.sendMail](): Promise<void> {
    try {
      const sendMailFunction = firebaseFunctions.httpsCallable("sendMail");
      const body = "<p>Ciao, questa è una prova di messaggio. Spero che vada tutto bene.";
      const templateData: Record<string, string> = {
        recipient: "Marco Cliente<info@lisatti.com>",
        subject: "KORU - Nuovo Account",
        body: body,
      };
      await sendMailFunction(templateData);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
