export const pageStoreTypes = {
  actions: {
    getPages: "GET_PAGES",
    getPagesByTemplate: "GET_PAGES_BY_TEMPLATE",
    getPagesByMapReferenceNumber: "GET_PAGES_BY_MAP_REFERENCE_NUMBER",
    getPage: "GET_PAGE",
    createPage: "CREATE_PAGE",
    updatePage: "UPDATE_PAGE",
    deletePage: "DELETE_PAGE",
  },
};
