import { ActionTree } from "vuex";

import { ImageInterface } from "@/features/modules/common/models/Image.interface";
import { koruStorage } from "@/core/modules/storage";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { SectionStateInterface } from "../models/SectionState.interface";
import { sectionStoreTypes } from "./types";
import { uniqueId } from "@/core/plugins/unique-id";

export const actions: ActionTree<SectionStateInterface, KoruStateInterface> = {
  async [sectionStoreTypes.actions.uploadImage](context, payload: Record<string, unknown>): Promise<ImageInterface> {
    try {
      const file: File = payload.file as File;
      const folder: string = payload.folder as string;

      if (file == undefined || folder == undefined) {
        throw new Error("invalid parameters");
      }

      const extension = file.name.split(".").pop();
      const uniqueName = uniqueId() + "." + extension;

      const downloadUrl: string = await koruStorage.upload(folder + uniqueName, file, (progress: number) => {
        context.commit(sectionStoreTypes.mutations.setUploadProgressValue, progress);
      });
      const sectionImage: ImageInterface = {
        displayName: file.name,
        uniqueName: uniqueName,
        type: extension?.toUpperCase() || "",
        size: file.size,
        url: downloadUrl,
      };

      return sectionImage;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [sectionStoreTypes.actions.deleteImage](context, payload: Record<string, unknown>): Promise<void> {
    try {
      const uniqueName: string = payload.uniqueName as string;
      const folder: string = payload.folder as string;

      if (uniqueName == undefined || folder == undefined) {
        throw new Error("invalid parameters");
      }

      await koruStorage.delete(folder + uniqueName);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
