import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { koruDb } from "@/core/modules/database";
import { linkedRoleToFirestore } from "@/core/modules/role/models/LinkedRole.interface";
import { RoleInterface, roleFromFirestore, roleToFirestore } from "@/core/modules/role/models/Role.interface";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

import {
  createDocumentHelper,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  getDocumentsHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";

export const roleModel: DatabaseModuleInterface<RoleInterface> = {
  collectionName: "roles",
  documentFromFirestore: roleFromFirestore,
  documentToFirestore: roleToFirestore,

  async getDocuments(): Promise<RoleInterface[]> {
    return await getDocumentsHelper<RoleInterface>(this.collectionName, "name", "asc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<RoleInterface> {
    return await getDocumentHelper<RoleInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: RoleInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<RoleInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async updateDocument(document: RoleInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<RoleInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: RoleInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
};

const linkedUpdates = async (batch: firebase.firestore.WriteBatch, role: RoleInterface): Promise<void> => {
  const linkedRole = linkedRoleToFirestore({
    id: role.id,
    name: role.name,
    readAll: role.readAll,
    readOwn: role.readOwn,
    create: role.create,
    updateAll: role.updateAll,
    updateOwn: role.updateOwn,
    deleteAll: role.deleteAll,
    deleteOwn: role.deleteOwn,
  });

  const users: UserInterface[] = await koruDb.getModule<UserModelInterface>("users").getUsersByRole(role.id);
  users.forEach((user: UserInterface) => {
    batch.update(getCollectionReference("users").doc(user.id), { role: linkedRole });
  });
};
