import { createStoreModule } from "@/core/modules/helpers";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";
import { WorkStateInterface } from "../models/WorkState.interface";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";
import { mutations } from "./mutations";

// eslint-disable-next-line prettier/prettier
export const workStore: KoruStoreModuleInterface<WorkStateInterface> = createStoreModule(
  "workState",
  initialState,
  getters,
  actions,
  mutations
);

export const useWorkStore = (): KoruStoreModuleInterface<WorkStateInterface> => {
  return workStore;
};
