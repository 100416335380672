import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { ChangelogModelInterface } from "../ChangelogModel.interface";
import { ReleaseInterface, releaseFromFirestore, releaseToFirestore } from "@/core/modules/changelog/models/Release.interface";

import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  getDocumentsHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";

export const changelogModel: ChangelogModelInterface = {
  collectionName: "changelog",
  documentFromFirestore: releaseFromFirestore,
  documentToFirestore: releaseToFirestore,

  async getDocuments(): Promise<ReleaseInterface[]> {
    return await getDocumentsHelper<ReleaseInterface>(this.collectionName, "date", "desc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<ReleaseInterface> {
    return await getDocumentHelper<ReleaseInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: ReleaseInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<ReleaseInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(document: ReleaseInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<ReleaseInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: ReleaseInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },

  async getPublicReleases(): Promise<ReleaseInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<ReleaseInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("public", "==", true)
        .orderBy("date", "desc")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: ReleaseInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

const linkedUpdates = async (): Promise<void> => {
  Promise.resolve();
};
