
import { defineComponent, ref } from "vue";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { ThemeInterface } from "../models/Theme.interface";
import { themeStoreTypes } from "../store/types";
import { useAuthStore } from "@/core/modules/auth/store";
import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { useThemeStore } from "../store";

export default defineComponent({
  name: "ThemeSelector",
  setup() {
    const { t } = useKoruLocale();
    const koruConfig = useKoruConfig();
    const authStore = useAuthStore();
    const themeStore = useThemeStore();

    const selectedTheme = ref();
    const themes: ThemeInterface[] = themeStore.getter(themeStoreTypes.getters.getThemes);

    const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
    themes.forEach((theme: ThemeInterface) => {
      if (theme.name == user.detail.theme) {
        selectedTheme.value = theme;
        return;
      }
    });

    return {
      koruConfig,
      selectedTheme,
      t,
      themes,
      themeStore,
    };
  },
  mounted() {
    this.selectTheme(this.selectedTheme);
  },
  methods: {
    selectTheme(theme: ThemeInterface): void {
      this.selectedTheme = theme;
      this.themeStore.action(themeStoreTypes.actions.setSelectedTheme, theme);
      this.changeColorScheme(theme);
    },
    changeColorScheme(theme: ThemeInterface): void {
      this.changeStyleSheetUrl("layout-css", "layout-" + theme.name + ".css", 1);
      this.changeStyleSheetUrl("theme-css", "theme-" + theme.name + ".css", 1);
      this.changeLogo(theme.icon);
    },
    changeStyleSheetUrl(id: string, value: string, from: number): void {
      const element = document.getElementById(id);
      const urlTokens = element?.getAttribute("href")?.split("/");
      if (urlTokens !== undefined) {
        if (from === 1) {
          // which function invoked this function
          urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
          // which function invoked this function
          if (value !== null) {
            urlTokens[urlTokens.length - 2] = value;
          }
        } else if (from === 3) {
          // which function invoked this function
          urlTokens[urlTokens.length - 2] = value;
        }
        const newURL = urlTokens.join("/");
        this.replaceLink(element, newURL);
      }
    },
    replaceLink(linkElement: Element | null, href: string): void {
      if (linkElement == null) {
        return;
      }
      if (this.isIE()) {
        linkElement.setAttribute("href", href);
      } else {
        const id = linkElement.getAttribute("id");
        const cloneLinkElement = linkElement.cloneNode(true) as HTMLElement;
        cloneLinkElement.setAttribute("href", href);
        cloneLinkElement.setAttribute("id", id + "-clone");
        linkElement.parentNode?.insertBefore(cloneLinkElement, linkElement.nextSibling);
        cloneLinkElement.addEventListener("load", () => {
          linkElement.remove();
          if (id !== null) {
            cloneLinkElement.setAttribute("id", id);
          }
        });
      }
    },
    changeLogo(themeIcon: string): void {
      const mobileLogoLink = document.getElementById("logo-mobile") as HTMLImageElement;
      const footerLogoLink = document.getElementById("footer-logo") as HTMLImageElement;
      const logoUrl = `/assets/layout/images/${this.koruConfig.app.logo}-logo-${themeIcon}.svg`;
      if (mobileLogoLink) {
        mobileLogoLink.src = logoUrl;
      }
      if (footerLogoLink) {
        footerLogoLink.src = logoUrl;
      }
    },
    isIE(): boolean {
      return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    },
  },
});
