import {
  WebDocumentInterface,
  createWebDocument,
  webDocumentFromFirestore,
  webDocumentToFirestore,
} from "@/features/modules/web-document/models/WebDocument.interface";

export interface WorkCategoryInterface extends WebDocumentInterface {
  order: number;
}

export const createNewWorkCategory = (): WorkCategoryInterface => {
  return createWebDocument<WorkCategoryInterface>({
    interfaceType: "WorkCategory",
    order: 999,
  });
};

export function workCategoryFromFirestore(data: Record<string, unknown>, id?: string): WorkCategoryInterface {
  return webDocumentFromFirestore<WorkCategoryInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "WorkCategory",
      order: data.order || 0,
    },
    data
  );
}

export function workCategoryToFirestore(workCategory: WorkCategoryInterface): Record<string, unknown> {
  return webDocumentToFirestore(
    {
      order: workCategory.order,
    },
    workCategory
  );
}
