import { GetterTree } from "vuex";

import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { koruStoreTypes } from "@/core/modules/store/types";
import { WorkStateInterface } from "../models/WorkState.interface";
import { workStoreTypes } from "./types";

export const getters: GetterTree<WorkStateInterface, KoruStateInterface> = {
  [workStoreTypes.getters.isLoading]: (state: WorkStateInterface, getters: GetterTree<KoruStateInterface, KoruStateInterface>): boolean => {
    return getters[koruStoreTypes.getters.isLoading] as unknown as boolean;
  },
  [workStoreTypes.getters.isUploading]: (state: WorkStateInterface): boolean => {
    return state.isUploading;
  },
  [workStoreTypes.getters.getUploadProgressValue]: (state: WorkStateInterface): number => {
    return state.uploadProgressValue;
  },
};
