import { KoruStateInterface } from "./models/KoruState.interface";
import { koruStoreTypes } from "./types";

export const mutations = {
  [koruStoreTypes.mutations.closeEditingDialog]: (state: KoruStateInterface): void => {
    state.editingDialog = false;
    state.route = undefined;
  },
  [koruStoreTypes.mutations.editingStart]: (state: KoruStateInterface): void => {
    state.editing = true;
  },
  [koruStoreTypes.mutations.editingStop]: (state: KoruStateInterface): void => {
    state.editing = false;
    state.editingDialog = false;
  },
  [koruStoreTypes.mutations.loadingStart]: (state: KoruStateInterface): void => {
    state.loading = true;
  },
  [koruStoreTypes.mutations.loadingStop]: (state: KoruStateInterface): void => {
    state.loading = false;
  },
  [koruStoreTypes.mutations.openEditingDialog]: (state: KoruStateInterface, route: string): void => {
    state.route = route;
    state.editingDialog = true;
  },
  [koruStoreTypes.mutations.setPreviousRoute]: (state: KoruStateInterface, route: string): void => {
    state.previousRoute = route;
  },
};
