import { ActionTree } from "vuex";

import { ChangelogModelInterface } from "@/core/modules/changelog/models/ChangelogModel.interface";
import { changelogStoreTypes } from "./types";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruConfig } from "@/core/modules/config";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { NotificationInterface, createNewNotification } from "@/core/modules/notification/models/Notification.interface";
import { notificationStore } from "@/core/modules/notification/store";
import { notificationStoreTypes } from "@/core/modules/notification/store/types";
import { ReleaseInterface } from "../models/Release.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [changelogStoreTypes.actions.getReleases](): Promise<ReleaseInterface[]> {
    try {
      return await koruDb.getModule<ChangelogModelInterface>("changelog").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [changelogStoreTypes.actions.getPublicReleases](): Promise<ReleaseInterface[]> {
    try {
      return await koruDb.getModule<ChangelogModelInterface>("changelog").getPublicReleases();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [changelogStoreTypes.actions.getRelease](context, releaseId: string): Promise<ReleaseInterface> {
    try {
      return await koruDb.getModule<ChangelogModelInterface>("changelog").getDocument(releaseId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [changelogStoreTypes.actions.createRelease](context, release: ReleaseInterface): Promise<void> {
    try {
      setDocumentFields(release.id, release);
      await koruDb.getModule<ChangelogModelInterface>("changelog").createDocument(release, false);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [changelogStoreTypes.actions.updateRelease](context, release: ReleaseInterface): Promise<void> {
    try {
      const oldRelease: ReleaseInterface = await koruDb.getModule<ChangelogModelInterface>("changelog").getDocument(release.id);
      if (hasDocumentChanged(release, oldRelease)) throw new Error("sync");

      setDocumentFields(release.id, release);
      await koruDb.getModule<ChangelogModelInterface>("changelog").updateDocument(release, false);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [changelogStoreTypes.actions.deleteRelease](context, release: ReleaseInterface): Promise<void> {
    try {
      await koruDb.getModule<ChangelogModelInterface>("changelog").deleteDocument(release, false);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [changelogStoreTypes.actions.sendNotification](context, release: ReleaseInterface): Promise<void> {
    try {
      const notification: NotificationInterface = createNewNotification();
      notification.type = "releaseCreated";
      notification.reference = release.id;
      notification.message = release.version;
      notification.owners = await koruDb
        .getModule<UserModelInterface>("users")
        .getUserIdsByModuleAndRight(
          koruConfig.notification.types.releaseCreated.requiredModule,
          koruConfig.notification.types.releaseCreated.requiredRight
        );
      notification.unread = notification.owners;
      await notificationStore.action(notificationStoreTypes.actions.createNotification, notification);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
