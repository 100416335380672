import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";
import { koruAuth } from "@/core/modules/auth";
import { koruConfig } from "@/core/modules/config";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { NotificationInterface, createNewNotification } from "@/core/modules/notification/models/Notification.interface";
import { NotificationModelInterface } from "@/core/modules/notification/models/NotificationModel.interface";
import { UserInterface } from "../models/User.interface";
import { UserModelInterface } from "../models/UserModel.interface";
import { userStoreTypes } from "./types";

import { checkUserDeleteConstraints } from "@/features/modules/helpers";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [userStoreTypes.actions.getUsers](): Promise<UserInterface[]> {
    try {
      return await koruDb.getModule<UserModelInterface>("users").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.getUsersByRole](context, roleId: string): Promise<UserInterface[]> {
    try {
      return await koruDb.getModule<UserModelInterface>("users").getUsersByRole(roleId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.getUser](context, userId: string): Promise<UserInterface> {
    try {
      return await koruDb.getModule<UserModelInterface>("users").getDocument(userId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.createUser](context, user: UserInterface): Promise<void> {
    try {
      setDocumentFields(user.id, user);
      user.id = await koruAuth.registerUser(user);
      await koruDb.getModule<UserModelInterface>("users").createDocument(user, true);

      const userNotification: NotificationInterface = createNewNotification();
      userNotification.type = "userCreated";
      userNotification.reference = user.id;
      userNotification.message = `${user.firstName} ${user.lastName}`;
      userNotification.owners = await koruDb
        .getModule<UserModelInterface>("users")
        .getUserIdsByModuleAndRight(
          koruConfig.notification.types.userCreated.requiredModule,
          koruConfig.notification.types.userCreated.requiredRight
        );
      userNotification.unread = userNotification.owners;
      await koruDb.getModule<NotificationModelInterface>("notifications").createDocument(userNotification, false);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.updateUser](context, user: UserInterface): Promise<void> {
    try {
      const oldUser: UserInterface = await koruDb.getModule<UserModelInterface>("users").getDocument(user.id);
      if (hasDocumentChanged(user, oldUser)) throw new Error("sync");

      if (oldUser.email != user.email) await koruAuth.updateUser(user);

      setDocumentFields(user.id, user);
      await koruDb.getModule<UserModelInterface>("users").updateDocument(user, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.toggleUser](context, user: UserInterface): Promise<void> {
    try {
      if (user.blocked == false) {
        user.blocked = true;
        await koruAuth.blockUser(user.id);
      } else {
        user.blocked = false;
        await koruAuth.unblockUser(user.id);
      }
      setDocumentFields(user.id, user);
      await koruDb.getModule<UserModelInterface>("users").updateDocument(user, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.deleteUser](context, user: UserInterface): Promise<void> {
    try {
      const checkResult = await checkUserDeleteConstraints(user.id);
      if (checkResult != undefined) throw new Error(checkResult);

      await koruAuth.deleteUser(user.id);
      await koruDb.getModule<UserModelInterface>("users").deleteDocument(user, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.isEmailUnique](context, { email, oldEmail }): Promise<number> {
    try {
      return await koruDb.getModule<UserModelInterface>("users").getUserCountByEmail(email, oldEmail);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
