export const changelogStoreTypes = {
  actions: {
    getReleases: "GET_RELEASES",
    getPublicReleases: "GET_PUBLIC_RELEASES",
    getRelease: "GET_RELEASE",
    createRelease: "CREATE_RELEASE",
    updateRelease: "UPDATE_RELEASE",
    deleteRelease: "DELETE_RELEASE",
    sendNotification: "SEND_NOTIFICATION",
  },
};
