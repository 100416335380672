import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { PageInterface } from "@/features/modules/page/models/Page.interface";
import { pageStore } from "@/features/modules/page/store";
import { pageStoreTypes } from "@/features/modules/page/store/types";
import { TemplateInterface } from "../models/Template.interface";
import { TemplateModelInterface } from "../models/TemplateModel.interface";
import { templateStoreTypes } from "./types";
import { webRenderStore } from "@/features/modules/web-render/store";
import { webRenderStoreTypes } from "@/features/modules/web-render/store/types";

import { additionalTemplateChildren } from "@/features/modules/additional";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [templateStoreTypes.actions.getTemplates](): Promise<TemplateInterface[]> {
    try {
      return await koruDb.getModule<TemplateModelInterface>("templates").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [templateStoreTypes.actions.getTemplatesByBlock](context, templateId: string): Promise<TemplateInterface[]> {
    try {
      return await koruDb.getModule<TemplateModelInterface>("templates").getTemplatesByBlock(templateId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [templateStoreTypes.actions.getTemplate](context, templateId: string): Promise<TemplateInterface> {
    try {
      return await koruDb.getModule<TemplateModelInterface>("templates").getDocument(templateId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [templateStoreTypes.actions.createTemplate](context, template: TemplateInterface): Promise<string> {
    try {
      setDocumentFields(template.id, template);
      return await koruDb.getModule<TemplateModelInterface>("templates").createDocument(template, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [templateStoreTypes.actions.updateTemplate](context, template: TemplateInterface): Promise<void> {
    try {
      const oldTemplate: TemplateInterface = await koruDb.getModule<TemplateModelInterface>("templates").getDocument(template.id);
      if (hasDocumentChanged(template, oldTemplate)) throw new Error("sync");

      setDocumentFields(template.id, template);
      await koruDb.getModule<TemplateModelInterface>("templates").updateDocument(template, true);

      await webRenderStore.action(webRenderStoreTypes.actions.processWebRenderForTemplateUpdate, template.id);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [templateStoreTypes.actions.deleteTemplate](context, template: TemplateInterface): Promise<void> {
    try {
      const pages = (await pageStore.action(pageStoreTypes.actions.getPagesByTemplate, template.id)) as PageInterface[];
      if (pages.length > 0) throw new Error("children");

      const additionalCheck: boolean = await additionalTemplateChildren(template.id);
      if (!additionalCheck) throw new Error("children");

      await koruDb.getModule<TemplateModelInterface>("templates").deleteDocument(template, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
