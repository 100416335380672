import { RouteRecordRaw } from "vue-router";

export const variableRoutes: Array<RouteRecordRaw> = [
  {
    path: "/variables",
    name: "VariableList",
    component: () => import("../views/VariableList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "variable",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/variables/:id",
    name: "VariableEdit",
    component: () => import("../views/VariableEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "variable",
      requiredRights: ["readAll", "readOwn", "updateAll", "updateOwn"],
    },
  },
];
