import { GetterTree } from "vuex";

import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { koruStoreTypes } from "@/core/modules/store/types";
import { SectionStateInterface } from "../models/SectionState.interface";
import { sectionStoreTypes } from "./types";

export const getters: GetterTree<SectionStateInterface, KoruStateInterface> = {
  [sectionStoreTypes.getters.isLoading]: (state: SectionStateInterface, getters: GetterTree<KoruStateInterface, KoruStateInterface>): boolean => {
    return getters[koruStoreTypes.getters.isLoading] as unknown as boolean;
  },
  [sectionStoreTypes.getters.isUploading]: (state: SectionStateInterface): boolean => {
    return state.isUploading;
  },
  [sectionStoreTypes.getters.getUploadProgressValue]: (state: SectionStateInterface): number => {
    return state.uploadProgressValue;
  },
};
