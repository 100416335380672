import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";

export interface NotificationInterface extends DocumentInterface {
  type: string;
  reference: string | undefined;
  message: string | undefined;
  owners: string[];
  unread: string[];
}

export const createNewNotification = (): NotificationInterface => {
  return createDocument<NotificationInterface>({
    interfaceType: "Notification",
    type: "",
    reference: undefined,
    message: undefined,
    owners: [],
    unread: [],
  });
};

export function notificationFromFirestore(data: Record<string, unknown>, id?: string): NotificationInterface {
  return documentFromFirestore<NotificationInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Notification",
      type: data.type as string,
      reference: (data.reference as string) || undefined,
      message: (data.message as string) || undefined,
      owners: (data.owners as string[]) || [],
      unread: (data.unread as string[]) || [],
    },
    data
  );
}

export function notificationToFirestore(notification: NotificationInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      type: notification.type,
      reference: notification.reference || null,
      message: notification.message || null,
      owners: notification.owners || [],
      unread: notification.unread || [],
    },
    notification
  );
}
