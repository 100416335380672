import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";

export interface VariableInterface extends DocumentInterface {
  name: string;
  value: Record<string, string>;
}

export const createNewVariable = (): VariableInterface => {
  return createDocument<VariableInterface>({
    interfaceType: "Variable",
    name: "",
    value: {},
  });
};

export function variableFromFirestore(data: Record<string, unknown>, id?: string): VariableInterface {
  return documentFromFirestore<VariableInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Variable",
      name: data.name as string,
      value: data.value || {},
    },
    data
  );
}

export function variableToFirestore(variable: VariableInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      name: variable.name,
      value: variable.value,
    },
    variable
  );
}
