import { koruConfig } from "@/core/modules/config";
import { MapInterface } from "@/features/modules/map/models/Map.interface";
import { SectionInterface } from "@/features/modules/section/models/Section.interface";
import { SettingInterface } from "@/features/modules/setting/models/Setting.interface";
import { TemplateInterface } from "@/features/modules/template/models/Template.interface";
import { VariableInterface } from "@/features/modules/variable/models/Variable.interface";

import { WebDocumentInterface } from "@/features/modules/web-document/models/WebDocument.interface";

import sectionTemplates from "@/data/section-templates.json";

export function generateUrls(webDocument: WebDocumentInterface, settings: SettingInterface, language: string, isHomepage = false): string[] {
  let urls: string[] = [];

  if (settings.languages.length > 1) {
    if (isHomepage) {
      // eslint-disable-next-line prettier/prettier
      urls = [
        `/${language}`,
        `/${language}/`,
        `/${language}/index.html`,
      ];
      if (language == settings.defaultLanguage) {
        urls.push("/");
      }
    } else {
      // eslint-disable-next-line prettier/prettier
      urls = [
        `/${language}/${webDocument.slug[language]}`,
        `/${language}/${webDocument.slug[language]}/`,
        `/${language}/${webDocument.slug[language]}/index.html`,
      ];
    }
  } else {
    if (isHomepage) {
      // eslint-disable-next-line prettier/prettier
      urls = [
        `/`,
        `/index.html`,
      ];
    } else {
      // eslint-disable-next-line prettier/prettier
      urls = [
        `/${webDocument.slug[language]}`,
        `/${webDocument.slug[language]}/`,
        `/${webDocument.slug[language]}/index.html`,
      ];
    }
  }

  return urls;
}

export async function generateMarkup(
  webDocument: WebDocumentInterface,
  templates: TemplateInterface[],
  variables: VariableInterface[],
  maps: MapInterface[],
  settings: SettingInterface,
  language: string,
  contentRenderer: ((language: string) => Promise<string>) | undefined = undefined,
  customVariables: ((language: string) => Record<string, string>) | undefined = undefined
): Promise<string> {
  let markup = "";
  const responsiveClassName: string = koruConfig.settings.responsiveClassName as string;

  // find template
  const template = templates.find((template) => template.id == webDocument.template?.id);
  if (template == undefined) throw new Error("Template not found");

  // blocks
  for (const block of template.blocks[language]) {
    if (block.id == "content") {
      if (contentRenderer != undefined) {
        markup += await contentRenderer(language);
      } else {
        if (Object.keys(webDocument).includes("sections")) {
          for (const section of (webDocument.sections as Record<string, SectionInterface[]>)[language]) {
            markup += `<div class="${responsiveClassName.replaceAll("!%WIDTH!", section.width.toString())}">`;

            // eslint-disable-next-line prettier/prettier
            const sectionSkeleton: string = (sectionTemplates as unknown as Record<string, Record<string, string>>)[section.type][webDocument.interfaceType];
            markup += sectionSkeleton.replaceAll("!%VALUE!%", (section.url || section.value) as string);

            markup += "</div>";
          }
        }
      }
    } else {
      markup += block.code[language];
    }
  }

  // title
  let title = settings.defaultTitle[language];
  if (webDocument.title[language] != undefined && webDocument.title[language] != "") {
    title = `${webDocument.title[language]} - ${settings.defaultTitle[language]}`;
  }
  markup = markup.replaceAll("!%TITLE%!", title);

  // meta description
  let metaDescription: string = settings.defaultDescription[language];
  if (webDocument.metaDescription != undefined && webDocument.metaDescription[language] != undefined && webDocument.metaDescription[language] != "") {
    metaDescription = webDocument.metaDescription[language];
  }
  markup = markup.replaceAll("!%METADESCRIPTION%!", metaDescription);

  // meta keywords
  let metaKeywords: string[] = settings.defaultKeywords[language];
  if (webDocument.metaKeywords != undefined && webDocument.metaKeywords[language] != undefined && webDocument.metaKeywords[language].length > 0) {
    metaKeywords = webDocument.metaKeywords[language];
  }
  markup = markup.replaceAll("!%METAKEYWORDS%!", metaKeywords.join(","));

  // urls
  for (const loopLanguage of settings.languages) {
    markup = markup.replaceAll(`!%URL-${loopLanguage.toUpperCase()}%!`, webDocument.url[loopLanguage]);
    markup = markup.replaceAll(`!%LANGCLASS-${loopLanguage.toUpperCase()}%!`, loopLanguage == language ? "selected-language" : "");
  }

  // language
  markup = markup.replaceAll(`!%LANG%!`, language);

  // custom variables
  if (customVariables != undefined) {
    for (const [key, value] of Object.entries(customVariables(language))) {
      markup = markup.replaceAll(key, value);
    }
  }

  // variables
  for (const variable of variables) {
    markup = markup.replaceAll(`%${variable.name}%`, variable.value[language]);
  }

  // maps
  let hasMap = false;
  const referenceNumbers: number[] = [];
  for (const map of maps) {
    const variable = `%MAP-${map.referenceNumber}%`;
    if (map.code != undefined && map.code[language] != undefined && markup.includes(variable)) {
      markup = markup.replaceAll(variable, map.code[language]);
      hasMap = true;
      referenceNumbers.push(map.referenceNumber);
    }
  }
  if (hasMap) {
    const mapCodeLang: string = (koruConfig.settings.mapCode as Record<string, string>)[language] as string;
    let initMaps = "";
    for (const referenceNumber of referenceNumbers) {
      initMaps += `initMap${referenceNumber}(); `;
    }

    const mapCode: string = mapCodeLang.replaceAll("!%MAPAPIKEY%!", koruConfig.mapsApiKey).replaceAll("!%INITMAPS%!", initMaps);
    markup = markup.replaceAll("!%MAPCODE!%", mapCode);
  } else {
    markup = markup.replaceAll("!%MAPCODE!%", "");
  }

  return markup;
}
