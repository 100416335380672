import { createStoreModule } from "@/core/modules/helpers";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";
import { SectionStateInterface } from "../models/SectionState.interface";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";
import { mutations } from "./mutations";

// eslint-disable-next-line prettier/prettier
export const sectionStore: KoruStoreModuleInterface<SectionStateInterface> = createStoreModule(
  "sectionState",
  initialState,
  getters,
  actions,
  mutations
);

export const useSectionStore = (): KoruStoreModuleInterface<SectionStateInterface> => {
  return sectionStore;
};
