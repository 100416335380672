
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import { DocumentInterface } from "@/core/modules/database/models/Document.interface";
import { koruStoreTypes } from "@/core/modules/store/types";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";

import { getObjectValueWithField, tryOrError, tryOrToast, userCanCreate, userCanDelete, userCanRead, userCanUpdate } from "@/core/modules/helpers";
import { RowCommandInterface } from "@/core/modules/layout/models/RowCommand.interface";

export default defineComponent({
  name: "KoruSortableBrowser",
  props: {
    canCreate: { type: Boolean, default: true },
    canDelete: { type: Boolean, default: true },
    canEdit: { type: Boolean, default: true },
    canView: { type: Boolean, default: true },
    deleteDescriptionField: { type: String, default: undefined },
    // eslint-disable-next-line prettier/prettier
    deleteAction: { type: Function, default: () => { return; } },
    // eslint-disable-next-line prettier/prettier
    loadAction: { type: Function, default: () => { return; } },
    objectName: { type: String, default: "" },
    // eslint-disable-next-line prettier/prettier
    onReorderAction: { type: Function, default: () => { return; } },
    parentQuery: { type: String, default: "" },
    path: { type: String, default: "" },
    // eslint-disable-next-line @typescript-eslint/no-array-constructor
    rowCommands: { type: Array, default: new Array() },
    suffix: { type: String, default: "list" },
    // eslint-disable-next-line @typescript-eslint/no-array-constructor
    values: { type: Array, default: new Array() },
  },
  emits: ["update:filters", "update:values"],
  setup(props, { emit }) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();

    const route = useRoute();
    const requiredModule: string = route.meta.requiredModule as string;

    const valuesRef = computed({
      get: () => props.values,
      set: (val) => emit("update:values", val),
    });

    onMounted(async () => {
      await tryOrError(
        async () => {
          await props.loadAction();
        },
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    });

    const deleteAction = async (item: Record<string, unknown>) => {
      koruStore.commit(koruStoreTypes.mutations.loadingStart);

      await tryOrToast(
        async () => {
          await props.deleteAction(item);

          const deleteIndex = valuesRef.value.indexOf(item, 0);
          if (deleteIndex > -1) {
            valuesRef.value.splice(deleteIndex, 1);
          }
        },
        "delete",
        t,
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    };

    const showDeleteItemDialog = ref(false);
    const deleteItem = ref({});

    const deleteItemAction = () => {
      showDeleteItemDialog.value = false;
      deleteAction(deleteItem.value);
    };

    const getRowCommands = (document: DocumentInterface) => {
      const renderRowCommands: Record<string, unknown>[] = [];

      if (props.canEdit && (requiredModule == undefined || userCanUpdate(requiredModule, document))) {
        renderRowCommands.push({
          label: "buttons.edit",
          icon: "pi pi-pencil",
          command: (object: Record<string, unknown>) => {
            koruStore.dispatch(
              koruStoreTypes.actions.navigateTo,
              props.path + "/" + (object.reference as Record<string, unknown>).id + props.parentQuery
            );
          },
        });
      } else if (props.canView && (requiredModule == undefined || userCanRead(requiredModule, document))) {
        renderRowCommands.push({
          label: "buttons.view",
          icon: "pi pi-eye",
          command: (object: Record<string, unknown>) => {
            koruStore.dispatch(
              koruStoreTypes.actions.navigateTo,
              props.path + "/" + (object.reference as Record<string, unknown>).id + props.parentQuery
            );
          },
        });
      }

      if (props.canDelete && (requiredModule == undefined || userCanDelete(requiredModule, document))) {
        renderRowCommands.push({
          label: "buttons.delete",
          icon: "pi pi-trash",
          command: (object: Record<string, unknown>) => {
            deleteItem.value = object.reference as Record<string, unknown>;
            showDeleteItemDialog.value = true;
          },
        });
      }

      const rowCommandsLength = renderRowCommands.length;
      props.rowCommands.forEach((rowCommand: unknown) => {
        const rowCommandObject: RowCommandInterface = rowCommand as RowCommandInterface;
        if (rowCommandObject.condition(document) == true) {
          if (renderRowCommands.length == rowCommandsLength) {
            renderRowCommands.push({ label: "separator" });
          }
          renderRowCommands.push(rowCommand as Record<string, unknown>);
        }
      });

      return renderRowCommands;
    };

    const onReorder = async (event: Record<string, unknown>) => {
      valuesRef.value = event.value as unknown[];
      await props.onReorderAction(event.value as unknown[]);
    };

    return {
      deleteItemAction,
      deleteItem,
      getObjectValueWithField,
      getRowCommands,
      koruStore,
      koruStoreTypes,
      onReorder,
      requiredModule,
      showDeleteItemDialog,
      t,
      userCanCreate,
      userCanUpdate,
    };
  },
});
