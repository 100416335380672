import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { PageInterface, pageFromFirestore, pageToFirestore } from "@/features/modules/page/models/Page.interface";
import { PageModelInterface } from "../PageModel.interface";

import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  getDocumentsHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";
import { firebaseFirestore } from "@/core/plugins/firebase";

export const pageModel: PageModelInterface = {
  collectionName: "pages",
  documentFromFirestore: pageFromFirestore,
  documentToFirestore: pageToFirestore,

  async getDocuments(): Promise<PageInterface[]> {
    return await getDocumentsHelper<PageInterface>(this.collectionName, "name", "asc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<PageInterface> {
    return await getDocumentHelper<PageInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: PageInterface, logAction: boolean): Promise<string> {
    await this.handleHomepage(document);
    await this.handleNotFound(document);
    return await createDocumentHelper<PageInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async updateDocument(document: PageInterface, logAction: boolean): Promise<void> {
    await this.handleHomepage(document);
    await this.handleNotFound(document);
    await updateDocumentHelper<PageInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: PageInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },

  async getPagesByTemplate(templateId: string): Promise<PageInterface[]> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<PageInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("template.id", "==", templateId)
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: PageInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });
      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getPagesByMapReferenceNumber(mapReferenceNumber: number): Promise<PageInterface[]> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<PageInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: PageInterface[] = [];
      snapshot.docs.map((doc) => {
        for (const section of Object.values(doc.data().sections)) {
          if (section.find((item) => item.value?.includes(`%MAP-${mapReferenceNumber}%`))) {
            if (!data.includes(doc.data())) data.push(doc.data());
            break;
          }
        }
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getPagesBySlugAndLanguageExceptId(slug: string, language: string, exceptId: string): Promise<PageInterface[]> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<PageInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where(`slug.${language}`, "==", slug)
        .where("id", "!=", exceptId)
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: PageInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });
      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async handleHomepage(page: PageInterface): Promise<void> {
    try {
      if (page.isHomepage != true) return;

      const querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await getCollectionReference(
        this.collectionName
      ).get();

      const batch = firebaseFirestore.batch();

      querySnapshot.forEach(async (doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => {
        if (doc.id == page.id) return;
        batch.update(getCollectionReference(this.collectionName).doc(doc.id), { isHomepage: false });
      });

      await batch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async handleNotFound(page: PageInterface): Promise<void> {
    try {
      if (page.isNotFound != true) return;

      const querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await getCollectionReference(
        this.collectionName
      ).get();

      const batch = firebaseFirestore.batch();

      querySnapshot.forEach(async (doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => {
        if (doc.id == page.id) return;
        batch.update(getCollectionReference(this.collectionName).doc(doc.id), { isNotFound: false });
      });

      await batch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

const linkedUpdates = async (): Promise<void> => {
  return Promise.resolve();
};
