import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";

export interface SettingInterface extends DocumentInterface {
  defaultLanguage: string;
  languages: string[];
  defaultTitle: Record<string, string>;
  defaultDescription: Record<string, string>;
  defaultKeywords: Record<string, string[]>;
}

export const createNewSetting = (): SettingInterface => {
  return createDocument<SettingInterface>({
    interfaceType: "Setting",
    defaultLanguage: "it",
    languages: ["it"],
    defaultTitle: {},
    defaultDescription: {},
    defaultKeywords: {},
  });
};

export function settingFromFirestore(data: Record<string, unknown>, id?: string): SettingInterface {
  return documentFromFirestore<SettingInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Setting",
      defaultLanguage: data.defaultLanguage as string,
      languages: data.languages as string[],
      defaultTitle: data.defaultTitle || {},
      defaultDescription: data.defaultDescription || {},
      defaultKeywords: data.defaultKeywords || {},
    },
    data
  );
}

export function settingToFirestore(setting: SettingInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      defaultLanguage: setting.defaultLanguage,
      languages: setting.languages,
      defaultTitle: setting.defaultTitle,
      defaultDescription: setting.defaultDescription,
      defaultKeywords: setting.defaultKeywords,
    },
    setting
  );
}
