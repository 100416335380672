import { SettingInterface } from "@/features/modules/setting/models/Setting.interface";
import { settingStore } from "@/features/modules/setting/store";
import { settingStoreTypes } from "@/features/modules/setting/store/types";
import { WebDocumentInterface } from "./models/WebDocument.interface";

import { slugify } from "@/core/modules/helpers";
import { additionalSlugifyCheck } from "../additional";
import { koruDb } from "@/core/modules/database";
import { PageInterface } from "../page/models/Page.interface";
import { PageModelInterface } from "../page/models/PageModel.interface";

export async function setWebDocumentFields(webDocument: WebDocumentInterface): Promise<void> {
  const settings: SettingInterface = (await settingStore.action(settingStoreTypes.actions.getSetting)) as SettingInterface;
  for (const language of settings.languages) {
    const baseSlug = slugify(webDocument.title[language]);
    webDocument.slug[language] = baseSlug;

    let suffixIndex = 0;
    let uniqueness = false;
    while (uniqueness == false && suffixIndex < 100) {
      uniqueness = await checkSlug(webDocument, language);
      if (!uniqueness) {
        suffixIndex++;
        webDocument.slug[language] = `${baseSlug}-${suffixIndex}`;
      }
    }
  }
}

async function checkSlug(webDocument: WebDocumentInterface, language: string): Promise<boolean> {
  if (webDocument.interfaceType == "Page") {
    const pages: PageInterface[] = await koruDb
      .getModule<PageModelInterface>("pages")
      .getPagesBySlugAndLanguageExceptId(webDocument.slug[language], language, webDocument.id);
    if (pages.length > 0) return false;
  } else {
    return await additionalSlugifyCheck(webDocument, language);
  }
  return true;
}
