import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { BlockInterface, blockFromFirestore, blockToFirestore } from "../Block.interface";
import { BlockModelInterface } from "../BlockModel.interface";
import { koruDb } from "@/core/modules/database";
import { LinkedBlockInterface, linkedBlockToFirestore } from "../LinkedBlock.interface";
import { SettingInterface } from "@/features/modules/setting/models/Setting.interface";
import { settingStore } from "@/features/modules/setting/store";
import { settingStoreTypes } from "@/features/modules/setting/store/types";
import { TemplateInterface } from "@/features/modules/template/models/Template.interface";
import { TemplateModelInterface } from "@/features/modules/template/models/TemplateModel.interface";

import { firebaseFirestore } from "@/core/plugins/firebase";

import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  getDocumentsHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";

export const blockModel: BlockModelInterface = {
  collectionName: "blocks",
  documentFromFirestore: blockFromFirestore,
  documentToFirestore: blockToFirestore,

  async getDocuments(): Promise<BlockInterface[]> {
    return await getDocumentsHelper<BlockInterface>(this.collectionName, "order", "asc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<BlockInterface> {
    return await getDocumentHelper<BlockInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: BlockInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<BlockInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async updateDocument(document: BlockInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<BlockInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: BlockInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },

  async reorderBlocks(blocks: BlockInterface[]): Promise<void> {
    try {
      const batch = firebaseFirestore.batch();

      let order = 1;
      for (const block of blocks) {
        block.order = order;
        batch.update(
          getCollectionReference(this.collectionName)
            .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
            .doc(block.id),
          { order: order }
        );
        order++;
      }
      await batch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

const linkedUpdates = async (batch: firebase.firestore.WriteBatch, block: BlockInterface): Promise<void> => {
  const linkedBlock: LinkedBlockInterface = {
    id: block.id,
    name: block.name,
    code: block.code,
  };

  const settings: SettingInterface | undefined = (await settingStore.action(settingStoreTypes.actions.getSetting)) as SettingInterface | undefined;
  if (settings != undefined) {
    const templates: TemplateInterface[] = await koruDb.getModule<TemplateModelInterface>("templates").getTemplatesByBlock(block.id);
    for (const template of templates) {
      for (const language of settings.languages) {
        if (template.blocks[language] != undefined && template.blocks[language].length > 0) {
          const blocks = template.blocks[language].filter((filterBlock) => filterBlock.id === block.id);
          if (blocks.length > 0) {
            const updatedBlocks = updateLanguageBlock(linkedBlock, template.blocks);
            batch.update(getCollectionReference("templates").doc(template.id), { blocks: updatedBlocks });
          }
        }
      }
    }
  }
};

const updateLanguageBlock = (
  linkedBlock: LinkedBlockInterface,
  templateBlocks: Record<string, LinkedBlockInterface[]>
): Record<string, Record<string, unknown>[]> => {
  const updatedTemplateBlocks: Record<string, Record<string, unknown>[]> = {};

  for (const [language, blocks] of Object.entries(templateBlocks)) {
    const updatedBlocks: Record<string, unknown>[] = [];
    for (const block of blocks) {
      if (block.id == linkedBlock.id) {
        updatedBlocks.push(linkedBlockToFirestore(linkedBlock));
      } else {
        updatedBlocks.push(linkedBlockToFirestore(block));
      }
    }
    updatedTemplateBlocks[language] = updatedBlocks;
  }

  return updatedTemplateBlocks;
};
