export interface LinkedMapInterface {
  id: string;
  name: string;
}

export const createNewLinkedMap = (): LinkedMapInterface => {
  return {
    id: "new",
    name: "",
  };
};

export function linkedMapFromFirestore(data: Record<string, unknown>): LinkedMapInterface {
  return {
    id: (data.id as string) || "new",
    name: (data.name as string) || "",
  };
}

export function linkedMapToFirestore(data: LinkedMapInterface): Record<string, unknown> {
  return {
    id: data.id,
    name: data.name || null,
  };
}
