import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { LogInterface, logFromFirestore, logToFirestore } from "@/core/modules/log/models/Log.interface";

import { createDocumentHelper, deleteDocumentHelper, getDocumentHelper, getDocumentsHelper } from "@/core/modules/helpers";

export const logModel: DatabaseModuleInterface<LogInterface> = {
  collectionName: "logs",
  documentFromFirestore: logFromFirestore,
  documentToFirestore: logToFirestore,

  async getDocuments(): Promise<LogInterface[]> {
    return await getDocumentsHelper<LogInterface>(this.collectionName, "createdAt", "asc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<LogInterface> {
    return await getDocumentHelper<LogInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: LogInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<LogInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async updateDocument(): Promise<void> {
    return Promise.resolve();
  },
  async deleteDocument(document: LogInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
};
