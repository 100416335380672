import { MutationTree } from "vuex";

import { SectionStateInterface } from "../models/SectionState.interface";
import { sectionStoreTypes } from "./types";

export const mutations: MutationTree<SectionStateInterface> = {
  [sectionStoreTypes.mutations.uploadStart]: (state: SectionStateInterface): void => {
    state.isUploading = true;
    state.uploadProgressValue = 0;
  },
  [sectionStoreTypes.mutations.uploadStop]: (state: SectionStateInterface): void => {
    state.isUploading = false;
    state.uploadProgressValue = 0;
  },
  [sectionStoreTypes.mutations.setUploadProgressValue]: (state: SectionStateInterface, value: number): void => {
    state.uploadProgressValue = value;
  },
};
