import { VariableInterface, variableFromFirestore, variableToFirestore } from "../Variable.interface";
import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";

import { createDocumentHelper, deleteDocumentHelper, getDocumentHelper, getDocumentsHelper, updateDocumentHelper } from "@/core/modules/helpers";

export const variableModel: DatabaseModuleInterface<VariableInterface> = {
  collectionName: "variables",
  documentFromFirestore: variableFromFirestore,
  documentToFirestore: variableToFirestore,

  async getDocuments(): Promise<VariableInterface[]> {
    return await getDocumentsHelper<VariableInterface>(this.collectionName, "name", "asc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<VariableInterface> {
    return await getDocumentHelper<VariableInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: VariableInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<VariableInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(document: VariableInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<VariableInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: VariableInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
};

const linkedUpdates = async (): Promise<void> => {
  return Promise.resolve();
};
