import { ActionTree } from "vuex";

import { ImageInterface } from "@/features/modules/common/models/Image.interface";
import { koruConfig } from "@/core/modules/config";
import { koruDb } from "@/core/modules/database";
import { koruStorage } from "@/core/modules/storage";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { MapInterface } from "@/features/modules/map/models/Map.interface";
import { MapModelInterface } from "@/features/modules/map/models/MapModel.interface";
import { MapPointInterface } from "../models/MapPoint.interface";
import { MapPointModelInterface } from "../models/MapPointModel.interface";
import { MapPointStateInterface } from "../models/MapPointState.interface";
import { mapPointStoreTypes } from "./types";
import { mapStore } from "@/features/modules/map/store";
import { mapStoreTypes } from "@/features/modules/map/store/types";
import { webRenderStore } from "@/features/modules/web-render/store";
import { webRenderStoreTypes } from "@/features/modules/web-render/store/types";

import { uniqueId } from "@/core/plugins/unique-id";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";

export const actions: ActionTree<MapPointStateInterface, KoruStateInterface> = {
  async [mapPointStoreTypes.actions.getMapPoints](): Promise<MapPointInterface[]> {
    try {
      return await koruDb.getModule<MapPointModelInterface>("mapPoints").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapPointStoreTypes.actions.getMapPointsByMap](context, mapId: string): Promise<MapPointInterface[]> {
    try {
      return await koruDb.getModule<MapPointModelInterface>("mapPoints").getMapPointsByMap(mapId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapPointStoreTypes.actions.getMapPoint](context, mapPointId: string): Promise<MapPointInterface> {
    try {
      return await koruDb.getModule<MapPointModelInterface>("mapPoints").getDocument(mapPointId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapPointStoreTypes.actions.createMapPoint](context, mapPoint: MapPointInterface): Promise<string> {
    try {
      setDocumentFields(mapPoint.id, mapPoint);
      mapPoint.id = await koruDb.getModule<MapPointModelInterface>("mapPoints").createDocument(mapPoint, true);

      await processMap(mapPoint);

      return mapPoint.id;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapPointStoreTypes.actions.updateMapPoint](context, mapPoint: MapPointInterface): Promise<void> {
    try {
      const oldMapPoint: MapPointInterface = await koruDb.getModule<MapPointModelInterface>("mapPoints").getDocument(mapPoint.id);
      if (hasDocumentChanged(mapPoint, oldMapPoint)) throw new Error("sync");

      setDocumentFields(mapPoint.id, mapPoint);
      await koruDb.getModule<MapPointModelInterface>("mapPoints").updateDocument(mapPoint, true);

      await processMap(mapPoint);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapPointStoreTypes.actions.deleteMapPoint](context, mapPoint: MapPointInterface): Promise<void> {
    try {
      if (mapPoint.image != undefined) {
        context.dispatch(mapPointStoreTypes.actions.deleteImage, mapPoint.image.uniqueName);
      }

      await koruDb.getModule<MapPointModelInterface>("mapPoints").deleteDocument(mapPoint, true);

      await processMap(mapPoint);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapPointStoreTypes.actions.uploadImage](context, file: File): Promise<ImageInterface> {
    try {
      const extension = file.name.split(".").pop();
      const uniqueName = uniqueId() + "." + extension;

      const downloadUrl: string = await koruStorage.upload(koruConfig.storage.folders.mapPointImages + uniqueName, file, (progress: number) => {
        context.commit(mapPointStoreTypes.mutations.setUploadProgressValue, progress);
      });
      const mapPointImage: ImageInterface = {
        displayName: file.name,
        uniqueName: uniqueName,
        type: extension?.toUpperCase() || "",
        size: file.size,
        url: downloadUrl,
      };

      return mapPointImage;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapPointStoreTypes.actions.deleteImage](context, uniqueName: string): Promise<void> {
    try {
      await koruStorage.delete(koruConfig.storage.folders.mapPointImages + uniqueName);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

async function processMap(mapPoint: MapPointInterface): Promise<void> {
  try {
    const map: MapInterface = (await mapStore.action(mapStoreTypes.actions.getMap, mapPoint.map?.id)) as MapInterface;
    await mapStore.action(mapStoreTypes.actions.processMapCode, map);

    setDocumentFields(map.id, map);
    await koruDb.getModule<MapModelInterface>("maps").updateDocument(map, false);

    await webRenderStore.action(webRenderStoreTypes.actions.processWebRenderForMapUpdate, map.id);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
