import { Composer, createI18n, useI18n } from "vue-i18n";

import { koruConfig } from "@/core/modules/config";

import { enCoreLabels } from "@/core/locales/en.ts";
import { itCoreLabels } from "@/core/locales/it.ts";

import { enFeaturesLabels } from "@/features/locales/en.ts";
import { itFeaturesLabels } from "@/features/locales/it.ts";

import { allCoreDateTimeFormats, allCoreNumberFormats } from "@/core/locales/formats.ts";
import { allFeaturesDateTimeFormats, allFeaturesNumberFormats } from "@/features/locales/formats.ts";

const labels = {
  en: { ...enCoreLabels, ...enFeaturesLabels },
  it: { ...itCoreLabels, ...itFeaturesLabels },
};

labels.en.buttons = { ...enCoreLabels.buttons, ...enFeaturesLabels.buttons };
labels.it.buttons = { ...itCoreLabels.buttons, ...itFeaturesLabels.buttons };

labels.en.navigation = { ...enCoreLabels.navigation, ...enFeaturesLabels.navigation };
labels.it.navigation = { ...itCoreLabels.navigation, ...itFeaturesLabels.navigation };

labels.en.roleModules = { ...enCoreLabels.roleModules, ...enFeaturesLabels.roleModules };
labels.it.roleModules = { ...itCoreLabels.roleModules, ...itFeaturesLabels.roleModules };

labels.en.toast.success = { ...enCoreLabels.toast.success, ...enFeaturesLabels.toast.success };
labels.en.toast.error = { ...enCoreLabels.toast.error, ...enFeaturesLabels.toast.error };
labels.en.toast.warning = { ...enCoreLabels.toast.warning, ...enFeaturesLabels.toast.warning };
labels.it.toast.success = { ...itCoreLabels.toast.success, ...itFeaturesLabels.toast.success };
labels.it.toast.error = { ...itCoreLabels.toast.error, ...itFeaturesLabels.toast.error };
labels.it.toast.warning = { ...itCoreLabels.toast.warning, ...itFeaturesLabels.toast.warning };

const dateTimeFormats = {
  en: { ...allCoreDateTimeFormats, ...allFeaturesDateTimeFormats },
  it: { ...allCoreDateTimeFormats, ...allFeaturesDateTimeFormats },
};

const numberFormats = {
  en: { ...allCoreNumberFormats, ...allFeaturesNumberFormats },
  it: { ...allCoreNumberFormats, ...allFeaturesNumberFormats },
};

export const koruLocale = createI18n({
  legacy: false,
  locale: koruConfig.locale.default,
  fallbackLocale: koruConfig.locale.fallback,
  messages: labels,
  datetimeFormats: dateTimeFormats,
  numberFormats: numberFormats,
});

export const useKoruLocale = (locales?: { en: Record<string, string> }): Composer<unknown, unknown, unknown, unknown, never, unknown> => {
  return useI18n({
    messages: locales,
    useScope: "global",
  });
};
