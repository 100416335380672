export const webRenderStoreTypes = {
  actions: {
    getWebRenderByTypeAndReferenceAndLanguage: "GET_WEB_RENDER_BY_TYPE_AND_REFERENCE_AND_LANGUAGE",
    processWebRenderForWebDocument: "PROCESS_WEB_RENDER_FOR_WEB_DOCUMENT",
    processWebRenderForWebDocuments: "PROCESS_WEB_RENDER_FOR_WEB_DOCUMENTS",
    processWebRenderForBlockUpdate: "PROCESS_WEB_RENDER_FOR_BLOCK_UPDATE",
    processWebRenderForTemplateUpdate: "PROCESS_WEB_RENDER_FOR_TEMPLATE_UPDATE",
    processWebRenderForMapUpdate: "PROCESS_WEB_RENDER_FOR_MAP_UPDATE",
    processWebRenderForGeneralUpdate: "PROCESS_WEB_RENDER_FOR_GENERAL_UPDATE",
    deleteWebRender: "DELETE_WEB_RENDER",
    deleteWebRenderForWebDocument: "DELETE_WEB_RENDER_FOR_WEB_DOCUMENT",
  },
};
