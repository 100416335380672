import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";
import { ImageInterface } from "@/features/modules/common/models/Image.interface";
import { LinkedMapInterface, linkedMapFromFirestore, linkedMapToFirestore } from "@/features/modules/map/models/LinkedMap.interface";
import { LocationInterface, locationFromFirestore, locationToFirestore } from "@/features/modules/common/models/Location.interface";

export interface MapPointInterface extends DocumentInterface {
  name: string;
  map: LinkedMapInterface | undefined;
  title: Record<string, string>;
  content: Record<string, string>;
  image: ImageInterface | undefined;
  location: LocationInterface;
}

export const createNewMapPoint = (): MapPointInterface => {
  return createDocument<MapPointInterface>({
    interfaceType: "MapPoint",
    name: "",
    map: undefined,
    title: {},
    content: {},
    image: undefined,
    location: undefined,
  });
};

export function mapPointFromFirestore(data: Record<string, unknown>, id?: string): MapPointInterface {
  return documentFromFirestore<MapPointInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "MapPoint",
      name: data.name as string,
      map: data.map != null ? linkedMapFromFirestore(data.map as Record<string, unknown>) : undefined,
      title: data.title || {},
      content: data.content || {},
      image: data.image || undefined,
      location: data.location != null ? locationFromFirestore(data.location as firebase.firestore.GeoPoint) : undefined,
    },
    data
  );
}

export function mapPointToFirestore(mapPoint: MapPointInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      name: mapPoint.name,
      map: mapPoint.map != undefined ? linkedMapToFirestore(mapPoint.map) : null,
      title: mapPoint.title || null,
      content: mapPoint.content || null,
      image: mapPoint.image != undefined ? mapPoint.image : null,
      location: mapPoint.location != undefined ? locationToFirestore(mapPoint.location) : null,
    },
    mapPoint
  );
}
