import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { koruDb } from "@/core/modules/database";
import { LinkedTemplateInterface } from "../LinkedTemplate.interface";
import { PageInterface } from "@/features/modules/page/models/Page.interface";
import { PageModelInterface } from "@/features/modules/page/models/PageModel.interface";
import { settingStore } from "@/features/modules/setting/store";
import { settingStoreTypes } from "@/features/modules/setting/store/types";
import { SettingInterface } from "@/features/modules/setting/models/Setting.interface";
import { TemplateInterface, templateFromFirestore, templateToFirestore } from "../Template.interface";
import { TemplateModelInterface } from "../TemplateModel.interface";

import { additionalTemplateLinkUpdates } from "@/features/modules/additional";
import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  getDocumentsHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";

export const templateModel: TemplateModelInterface = {
  collectionName: "templates",
  documentFromFirestore: templateFromFirestore,
  documentToFirestore: templateToFirestore,

  async getDocuments(): Promise<TemplateInterface[]> {
    return await getDocumentsHelper<TemplateInterface>(this.collectionName, "name", "asc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<TemplateInterface> {
    return await getDocumentHelper<TemplateInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: TemplateInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<TemplateInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(document: TemplateInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<TemplateInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: TemplateInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },

  async getTemplatesByBlock(blockId: string): Promise<TemplateInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<TemplateInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: TemplateInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });

      const settings: SettingInterface | undefined = (await settingStore.action(settingStoreTypes.actions.getSetting)) as
        | SettingInterface
        | undefined;

      const selectedTemplates: TemplateInterface[] = [];
      if (settings != undefined) {
        for (const template of data) {
          for (const language of settings.languages) {
            if (template.blocks[language] != undefined && template.blocks[language].length > 0) {
              const blocks = template.blocks[language].filter((block) => block.id === blockId);
              if (blocks.length > 0) {
                selectedTemplates.push(template);
                break;
              }
            }
          }
        }
      }

      return selectedTemplates;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

const linkedUpdates = async (batch: firebase.firestore.WriteBatch, template: TemplateInterface): Promise<void> => {
  const linkedTemplate: LinkedTemplateInterface = {
    id: template.id,
    name: template.name,
  };

  const pages: PageInterface[] = await koruDb.getModule<PageModelInterface>("pages").getPagesByTemplate(template.id);
  pages.forEach((page: PageInterface) => {
    batch.update(getCollectionReference("pages").doc(page.id), { template: linkedTemplate });
  });

  await additionalTemplateLinkUpdates(batch, linkedTemplate);
};
