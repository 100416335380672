export const workStoreTypes = {
  getters: {
    getUploadProgressValue: "GET_UPLOAD_PROGRESS_VALUE",
    isLoading: "IS_LOADING",
    isUploading: "IS_UPLOADING",
  },
  actions: {
    getWorks: "GET_WORKS",
    getWorksByTemplate: "GET_WORKS_BY_TEMPLATE",
    getWorksByWorkCategory: "GET_WORKS_BY_WORK_CATEGORY",
    getWork: "GET_WORK",
    createWork: "CREATE_WORK",
    updateWork: "UPDATE_WORK",
    processWorkUpdate: "PROCESS_WORK_UPDATE",
    processWorksUpdate: "PROCESS_WORKS_UPDATE",
    deleteWork: "DELETE_WORK",
    reorderWorks: "REORDER_WORKS",
    uploadImage: "UPLOAD_IMAGE",
    deleteImage: "DELETE_IMAGE",
  },
  mutations: {
    setUploadProgressValue: "SET_UPLOAD_PROGRESS_VALUE",
    uploadStart: "UPLOAD_START",
    uploadStop: "UPLOAD_STOP",
  },
};
