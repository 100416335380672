import { RouteRecordRaw } from "vue-router";

export const logRoutes: Array<RouteRecordRaw> = [
  {
    path: "/logs",
    name: "LogList",
    component: () => import("../views/LogList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "log",
      requiredRights: ["readAll", "readOwn"],
    },
  },
];
