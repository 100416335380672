import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { koruDb } from "@/core/modules/database";
import { LinkedMapInterface, linkedMapToFirestore } from "../LinkedMap.interface";
import { MapInterface, mapFromFirestore, mapToFirestore } from "../Map.interface";
import { MapModelInterface } from "../MapModel.interface";
import { MapPointInterface } from "@/features/modules/map-point/models/MapPoint.interface";
import { MapPointModelInterface } from "@/features/modules/map-point/models/MapPointModel.interface";

import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  getDocumentsHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";

export const mapModel: MapModelInterface = {
  collectionName: "maps",
  documentFromFirestore: mapFromFirestore,
  documentToFirestore: mapToFirestore,

  async getDocuments(): Promise<MapInterface[]> {
    return await getDocumentsHelper<MapInterface>(this.collectionName, "name", "asc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<MapInterface> {
    return await getDocumentHelper<MapInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: MapInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<MapInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async updateDocument(document: MapInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<MapInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: MapInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },

  async getMapByReferenceNumber(referenceNumber: number): Promise<MapInterface | undefined> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<MapInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("referenceNumber", "==", referenceNumber)
        .get();

      if (snapshot.empty || snapshot.docs.length <= 0) {
        return undefined;
      }

      return snapshot.docs[0].data();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

const linkedUpdates = async (batch: firebase.firestore.WriteBatch, map: MapInterface): Promise<void> => {
  const linkedMap: LinkedMapInterface = {
    id: map.id,
    name: map.name,
  };

  const mapPoints: MapPointInterface[] = await koruDb.getModule<MapPointModelInterface>("mapPoints").getMapPointsByMap(map.id);
  mapPoints.forEach((mapPoint: MapPointInterface) => {
    batch.update(getCollectionReference("mapPoints").doc(mapPoint.id), { map: linkedMapToFirestore(linkedMap) });
  });
};
