import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";

export interface BackupInterface extends DocumentInterface {
  type: string;
  url: string;
}

export const createNewBackup = (): BackupInterface => {
  return createDocument<BackupInterface>({
    interfaceType: "Backup",
    type: "",
    url: "",
  });
};

export function backupFromFirestore(data: Record<string, unknown>, id?: string): BackupInterface {
  return documentFromFirestore<BackupInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Backup",
      type: data.type,
      url: data.url,
    },
    data
  );
}

export function backupToFirestore(backup: BackupInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      type: backup.type,
      url: backup.url,
    },
    backup
  );
}
