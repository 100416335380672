import { RouteRecordRaw } from "vue-router";

export const notificationRoutes: Array<RouteRecordRaw> = [
  {
    path: "/notifications",
    name: "NotificationView",
    component: () => import("../views/NotificationView.vue"),
    props: (route) => ({ notificationType: route.query.type }),
    meta: {
      requiresAuth: true,
      requiredModule: "",
      requiredRights: [],
    },
  },
];
