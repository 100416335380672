import { ImageInterface } from "@/features/modules/common/models/Image.interface";
import {
  LinkedWorkCategoryInterface,
  linkedWorkCategoryFromFirestore,
  linkedWorkCategoryToFirestore,
} from "@/features/modules/work-category/models/LinkedWorkCategory.interface";
import { SectionInterface, sectionFromFirestore, sectionToFirestore } from "@/features/modules/section/models/Section.interface";
import {
  WebDocumentInterface,
  createWebDocument,
  webDocumentFromFirestore,
  webDocumentToFirestore,
} from "@/features/modules/web-document/models/WebDocument.interface";

export interface WorkInterface extends WebDocumentInterface {
  workCategory: LinkedWorkCategoryInterface | undefined;
  featuredImage: ImageInterface | undefined;
  sections: Record<string, SectionInterface[]>;
  order: number;
}

export const createNewWork = (): WorkInterface => {
  return createWebDocument<WorkInterface>({
    interfaceType: "Work",
    workCategory: undefined,
    featuredImage: undefined,
    sections: {},
    order: 999,
  });
};

export function workFromFirestore(data: Record<string, unknown>, id?: string): WorkInterface {
  const sections: Record<string, SectionInterface[]> = {};
  for (const [key, value] of Object.entries(data.sections as Record<string, unknown>)) {
    sections[key] = (value as Record<string, unknown>[]).map((section: Record<string, unknown>) => {
      return sectionFromFirestore(section);
    });
  }

  return webDocumentFromFirestore<WorkInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Work",
      workCategory: data.workCategory != null ? linkedWorkCategoryFromFirestore(data.workCategory as Record<string, unknown>) : undefined,
      featuredImage: data.featuredImage || undefined,
      sections: sections,
      order: data.order || 0,
    },
    data
  );
}

export function workToFirestore(work: WorkInterface): Record<string, unknown> {
  const sections: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(work.sections)) {
    sections[key] = value.map((section: SectionInterface) => {
      return sectionToFirestore(section);
    });
  }

  return webDocumentToFirestore(
    {
      workCategory: work.workCategory != undefined ? linkedWorkCategoryToFirestore(work.workCategory) : null,
      featuredImage: work.featuredImage != undefined ? work.featuredImage : null,
      sections: sections,
      order: work.order,
    },
    work
  );
}
