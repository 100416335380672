import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";

export interface ReleaseInterface extends DocumentInterface {
  date: Date;
  version: string;
  addedItems: string[];
  fixedItems: string[];
  changedItems: string[];
  removedItems: string[];
  public: boolean;
}

export const createNewRelease = (): ReleaseInterface => {
  return createDocument<ReleaseInterface>({
    interfaceType: "Release",
    date: new Date(),
    version: undefined,
    addedItems: [],
    fixedItems: [],
    changedItems: [],
    removedItems: [],
    public: false,
  });
};

export function releaseFromFirestore(data: Record<string, unknown>, id?: string): ReleaseInterface {
  return documentFromFirestore<ReleaseInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Release",
      date: (data.date as firebase.firestore.Timestamp).toDate(),
      version: data.version,
      addedItems: data.addedItems,
      fixedItems: data.fixedItems,
      changedItems: data.changedItems,
      removedItems: data.removedItems,
      public: data.public,
    },
    data
  );
}

export function releaseToFirestore(release: ReleaseInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      date: firebase.firestore.Timestamp.fromDate(release.date),
      version: release.version,
      addedItems: release.addedItems,
      fixedItems: release.fixedItems,
      changedItems: release.changedItems,
      removedItems: release.removedItems,
      public: release.public,
    },
    release
  );
}
