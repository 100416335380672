import { ActionTree } from "vuex";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { LocaleInterface } from "../models/Locale.interface";
import { LocaleStateInterface } from "../models/LocaleState.interface";
import { localeStoreTypes } from "./types";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

export const actions: ActionTree<LocaleStateInterface, KoruStateInterface> = {
  async [localeStoreTypes.actions.setSelectedLocale](context, locale: LocaleInterface) {
    const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
    user.detail.locale = locale.name;

    try {
      await koruDb.getModule<UserModelInterface>("users").updateDocument(user, false);
    } catch (error: unknown) {
      console.log((error as Error).message);
    }
  },
};
