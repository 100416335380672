import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";

export interface BlockInterface extends DocumentInterface {
  name: string;
  code: Record<string, string>;
  order: number;
}

export const createNewBlock = (): BlockInterface => {
  return createDocument<BlockInterface>({
    interfaceType: "Block",
    name: "",
    code: {},
    order: 999,
  });
};

export function blockFromFirestore(data: Record<string, unknown>, id?: string): BlockInterface {
  return documentFromFirestore<BlockInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Block",
      name: data.name as string,
      code: data.code || {},
      order: data.order || 0,
    },
    data
  );
}

export function blockToFirestore(block: BlockInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      name: block.name,
      code: block.code,
      order: block.order,
    },
    block
  );
}
