import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { koruDb } from "@/core/modules/database";
import { LinkedTemplateInterface } from "@/features/modules/template/models/LinkedTemplate.interface";
import { WorkInterface } from "@/features/modules/work/models/Work.interface";
import { WorkModelInterface } from "@/features/modules/work/models/WorkModel.interface";
import { workStore } from "@/features/modules/work/store";
import { workStoreTypes } from "@/features/modules/work/store/types";

import { getCollectionReference } from "@/core/modules/helpers";

export async function additionalTemplateChildren(templateId: string): Promise<boolean> {
  const works = (await workStore.action(workStoreTypes.actions.getWorksByTemplate, templateId)) as WorkInterface[];
  if (works.length > 0) return false;

  return true;
}

export async function additionalTemplateLinkUpdates(batch: firebase.firestore.WriteBatch, linkedTemplate: LinkedTemplateInterface): Promise<void> {
  const works: WorkInterface[] = await koruDb.getModule<WorkModelInterface>("works").getWorksByTemplate(linkedTemplate.id);
  works.forEach((work: WorkInterface) => {
    batch.update(getCollectionReference("works").doc(work.id), { template: linkedTemplate });
  });
}
