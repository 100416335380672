import { ActionTree } from "vuex";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { ThemeInterface } from "../models/Theme.interface";
import { ThemeStateInterface } from "../models/ThemeState.interface";
import { themeStoreTypes } from "./types";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

export const actions: ActionTree<ThemeStateInterface, KoruStateInterface> = {
  async [themeStoreTypes.actions.setSelectedTheme](context, theme: ThemeInterface) {
    const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
    user.detail.theme = theme.name;

    try {
      await koruDb.getModule<UserModelInterface>("users").updateDocument(user, false);
    } catch (error: unknown) {
      console.log((error as Error).message);
    }
  },
};
