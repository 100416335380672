export const mapStoreTypes = {
  actions: {
    getMaps: "GET_MAPS",
    getMap: "GET_MAP",
    getMapByReferenceNumber: "GET_MAP_BY_REFERENCE_NUMBER",
    createMap: "CREATE_MAP",
    updateMap: "UPDATE_MAP",
    processMapCode: "PROCESS_MAP_CODE",
    deleteMap: "DELETE_MAP",
  },
};
