import { koruConfig } from "@/core/modules/config";

import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";
import { linkedRoleFromFirestore, LinkedRoleInterface, linkedRoleToFirestore } from "@/core/modules/role/models/LinkedRole.interface";
import { userDetailFromFirestore, UserDetailInterface, userDetailToFirestore } from "./UserDetail.interface";

export interface UserInterface extends DocumentInterface {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  role: LinkedRoleInterface;
  detail: UserDetailInterface;
  blocked: boolean;
}

export const createNewUser = (): UserInterface => {
  return createDocument<UserInterface>({
    interfaceType: "User",
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    role: undefined,
    detail: {
      locale: koruConfig.locale.default,
      theme: koruConfig.layout.defaultTheme,
    },
    blocked: false,
  });
};

export function userFromFirestore(data: Record<string, unknown>, id?: string): UserInterface {
  return documentFromFirestore<UserInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "User",
      firstName: data.firstName || undefined,
      lastName: data.lastName || undefined,
      email: data.email || undefined,
      role: linkedRoleFromFirestore(data.role as Record<string, unknown>),
      detail: userDetailFromFirestore(data.detail as Record<string, unknown>),
      blocked: data.blocked,
    },
    data
  );
}

export function userToFirestore(user: UserInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      firstName: user.firstName || null,
      lastName: user.lastName || null,
      email: user.email || null,
      role: linkedRoleToFirestore(user.role),
      detail: userDetailToFirestore(user.detail),
      blocked: user.blocked,
    },
    user
  );
}
