import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";

import { actions } from "./actions";

// eslint-disable-next-line prettier/prettier
export const workCategoryStore: KoruStoreModuleInterface<EmptyStateInterface> = createStoreModule(
  "workCategoryState",
  undefined,
  undefined,
  actions,
);

export const useWorkCategoryStore = (): KoruStoreModuleInterface<EmptyStateInterface> => {
  return workCategoryStore;
};
