import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";

export interface WebRenderInterface extends DocumentInterface {
  urls: string[];
  markup: string;
  type: string;
  reference: string;
  language: string;
  publicAt: Date | undefined;
}

export const createNewWebRender = (): WebRenderInterface => {
  return createDocument<WebRenderInterface>({
    interfaceType: "WebRender",
    urls: [],
    markup: "",
    type: "",
    reference: "",
    language: "",
    publicAt: undefined,
  });
};

export function webRenderFromFirestore(data: Record<string, unknown>, id?: string): WebRenderInterface {
  return documentFromFirestore<WebRenderInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "WebRender",
      urls: data.urls || [],
      markup: data.markup || "",
      type: data.type || "",
      reference: data.reference || "",
      language: data.language || "",
      publicAt: data.publicAt != null ? (data.publicAt as firebase.firestore.Timestamp).toDate() : undefined,
    },
    data
  );
}

export function webRenderToFirestore(webRender: WebRenderInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      urls: webRender.urls,
      markup: webRender.markup,
      type: webRender.type,
      reference: webRender.reference,
      language: webRender.language,
      publicAt: webRender.publicAt != undefined ? firebase.firestore.Timestamp.fromDate(webRender.publicAt) : null,
    },
    webRender
  );
}
