import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { importBackup } from "@/core/plugins/import-export";
import { installerStoreTypes } from "./types";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

import installerData from "@/data/installer-data.json";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [installerStoreTypes.actions.checkInstallStatus](): Promise<string> {
    try {
      const users: UserInterface[] = await koruDb.getModule<UserModelInterface>("users").getDocuments();

      if (users.length > 0) {
        return "installed";
      }
      return "installNeeded";
    } catch (error: unknown) {
      throw new Error((error as Error).message);
      return "error";
    }
  },
  async [installerStoreTypes.actions.install](): Promise<void> {
    try {
      const options = { autoParseDates: true, autoParseGeos: false, showLogs: true };
      await importBackup(installerData, options);
      console.log("Install!");
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
