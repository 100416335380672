import { createWorkCategoryContentRenderer } from "@/features/modules/work-category/store/actions";
import { createWorkCustomVariables, worksContentRenderer } from "@/features/modules/work/store/actions";
import { KoruBatch } from "@/core/modules/batch";
import { koruConfig } from "@/core/modules/config";
import { koruDb } from "@/core/modules/database";
import { MapInterface } from "@/features/modules/map/models/Map.interface";
import { mapStore } from "@/features/modules/map/store";
import { mapStoreTypes } from "@/features/modules/map/store/types";
import { PageInterface } from "@/features/modules/page/models/Page.interface";
import { pageStore } from "@/features/modules/page/store";
import { pageStoreTypes } from "@/features/modules/page/store/types";
import { SettingInterface } from "@/features/modules/setting/models/Setting.interface";
import { settingStore } from "@/features/modules/setting/store";
import { settingStoreTypes } from "@/features/modules/setting/store/types";
import { TemplateInterface } from "@/features/modules/template/models/Template.interface";
import { templateStore } from "@/features/modules/template/store";
import { templateStoreTypes } from "@/features/modules/template/store/types";
import { variableStoreTypes } from "@/features/modules/variable/store/types";
import { variableStore } from "@/features/modules/variable/store";
import { VariableInterface } from "@/features/modules/variable/models/Variable.interface";
import { WebDocumentInterface } from "@/features/modules/web-document/models/WebDocument.interface";
import { WebRenderInterface, webRenderToFirestore } from "@/features/modules/web-render/models/WebRender.interface";
import { WorkCategoryInterface } from "@/features/modules/work-category/models/WorkCategory.interface";
import { WorkCategoryModelInterface } from "@/features/modules/work-category/models/WorkCategoryModel.interface";
import { WorkInterface } from "@/features/modules/work/models/Work.interface";
import { WorkModelInterface } from "@/features/modules/work/models/WorkModel.interface";

import { firebaseFirestore } from "@/core/plugins/firebase";

import { createWebRenderFromWebDocument } from "@/features/modules/helpers";

export async function additionalUpdateWebDocumentsByMap(map: MapInterface): Promise<void> {
  const templates: TemplateInterface[] = (await templateStore.action(templateStoreTypes.actions.getTemplates)) as TemplateInterface[];
  const variables: VariableInterface[] = (await variableStore.action(variableStoreTypes.actions.getVariables)) as VariableInterface[];
  const maps: MapInterface[] = (await mapStore.action(mapStoreTypes.actions.getMaps)) as MapInterface[];

  const settings: SettingInterface = (await settingStore.action(settingStoreTypes.actions.getSetting)) as SettingInterface;

  const koruBatch = new KoruBatch();

  // works
  const works: WorkInterface[] = await koruDb.getModule<WorkModelInterface>("works").getWorksByMapReferenceNumber(map.referenceNumber);
  for (const work of works) {
    const result: Record<string, unknown> = await createWebRenderFromWebDocument(
      work,
      templates,
      variables,
      maps,
      settings,
      undefined,
      await createWorkCustomVariables(work)
    );

    const webRenders: WebRenderInterface[] = result.webRenders as WebRenderInterface[];

    for (const webRender of webRenders) {
      if (webRender.id == "new") {
        koruBatch.set(firebaseFirestore.collection("webRenders").doc(), webRenderToFirestore(webRender));
      } else {
        koruBatch.update(firebaseFirestore.collection("webRenders").doc(webRender.id), webRenderToFirestore(webRender));
      }
    }
  }

  await koruBatch.commit();
}

export async function additionalUpdateWebDocumentsByTemplates(involvedTemplates: TemplateInterface[]): Promise<void> {
  const templates: TemplateInterface[] = (await templateStore.action(templateStoreTypes.actions.getTemplates)) as TemplateInterface[];
  const variables: VariableInterface[] = (await variableStore.action(variableStoreTypes.actions.getVariables)) as VariableInterface[];
  const maps: MapInterface[] = (await mapStore.action(mapStoreTypes.actions.getMaps)) as MapInterface[];

  const settings: SettingInterface = (await settingStore.action(settingStoreTypes.actions.getSetting)) as SettingInterface;

  const koruBatch = new KoruBatch();

  for (const involvedTemplate of involvedTemplates) {
    // works
    const works: WorkInterface[] = await koruDb.getModule<WorkModelInterface>("works").getWorksByTemplate(involvedTemplate.id);
    for (const work of works) {
      const result: Record<string, unknown> = await createWebRenderFromWebDocument(
        work,
        templates,
        variables,
        maps,
        settings,
        undefined,
        await createWorkCustomVariables(work)
      );

      const webRenders: WebRenderInterface[] = result.webRenders as WebRenderInterface[];

      for (const webRender of webRenders) {
        if (webRender.id == "new") {
          koruBatch.set(firebaseFirestore.collection("webRenders").doc(), webRenderToFirestore(webRender));
        } else {
          koruBatch.update(firebaseFirestore.collection("webRenders").doc(webRender.id), webRenderToFirestore(webRender));
        }
      }
    }

    // work categories
    const workCategories: WorkCategoryInterface[] = await koruDb
      .getModule<WorkCategoryModelInterface>("workCategories")
      .getWorkCategoriesByTemplate(involvedTemplate.id);
    for (const workCategory of workCategories) {
      const result: Record<string, unknown> = await createWebRenderFromWebDocument(
        workCategory,
        templates,
        variables,
        maps,
        settings,
        await createWorkCategoryContentRenderer(workCategory.id),
        undefined
      );

      const webRenders: WebRenderInterface[] = result.webRenders as WebRenderInterface[];

      for (const webRender of webRenders) {
        if (webRender.id == "new") {
          koruBatch.set(firebaseFirestore.collection("webRenders").doc(), webRenderToFirestore(webRender));
        } else {
          koruBatch.update(firebaseFirestore.collection("webRenders").doc(webRender.id), webRenderToFirestore(webRender));
        }
      }
    }
  }

  // works page
  const worksPage: PageInterface = (await pageStore.action(pageStoreTypes.actions.getPage, koruConfig.settings.worksPageId)) as PageInterface;
  if (involvedTemplates.filter((template) => template.id == worksPage.template?.id).length > 0) {
    const result: Record<string, unknown> = await createWebRenderFromWebDocument(
      worksPage,
      templates,
      variables,
      maps,
      settings,
      worksContentRenderer,
      undefined
    );

    const webRenders: WebRenderInterface[] = result.webRenders as WebRenderInterface[];

    for (const webRender of webRenders) {
      if (webRender.id == "new") {
        koruBatch.set(firebaseFirestore.collection("webRenders").doc(), webRenderToFirestore(webRender));
      } else {
        koruBatch.update(firebaseFirestore.collection("webRenders").doc(webRender.id), webRenderToFirestore(webRender));
      }
    }
  }

  await koruBatch.commit();
}

export async function additionalUpdateAllWebDocuments(): Promise<void> {
  const templates: TemplateInterface[] = (await templateStore.action(templateStoreTypes.actions.getTemplates)) as TemplateInterface[];
  const variables: VariableInterface[] = (await variableStore.action(variableStoreTypes.actions.getVariables)) as VariableInterface[];
  const maps: MapInterface[] = (await mapStore.action(mapStoreTypes.actions.getMaps)) as MapInterface[];

  const settings: SettingInterface = (await settingStore.action(settingStoreTypes.actions.getSetting)) as SettingInterface;

  const koruBatch = new KoruBatch();

  // works
  const works: WorkInterface[] = await koruDb.getModule<WorkModelInterface>("works").getDocuments();
  for (const work of works) {
    const result: Record<string, unknown> = await createWebRenderFromWebDocument(
      work,
      templates,
      variables,
      maps,
      settings,
      undefined,
      await createWorkCustomVariables(work)
    );

    const webRenders: WebRenderInterface[] = result.webRenders as WebRenderInterface[];

    for (const webRender of webRenders) {
      if (webRender.id == "new") {
        koruBatch.set(firebaseFirestore.collection("webRenders").doc(), webRenderToFirestore(webRender));
      } else {
        koruBatch.update(firebaseFirestore.collection("webRenders").doc(webRender.id), webRenderToFirestore(webRender));
      }
    }
  }

  // work categories
  const workCategories: WorkCategoryInterface[] = await koruDb.getModule<WorkCategoryModelInterface>("workCategories").getDocuments();
  for (const workCategory of workCategories) {
    const result: Record<string, unknown> = await createWebRenderFromWebDocument(
      workCategory,
      templates,
      variables,
      maps,
      settings,
      await createWorkCategoryContentRenderer(workCategory.id),
      undefined
    );

    const webRenders: WebRenderInterface[] = result.webRenders as WebRenderInterface[];

    for (const webRender of webRenders) {
      if (webRender.id == "new") {
        koruBatch.set(firebaseFirestore.collection("webRenders").doc(), webRenderToFirestore(webRender));
      } else {
        koruBatch.update(firebaseFirestore.collection("webRenders").doc(webRender.id), webRenderToFirestore(webRender));
      }
    }
  }

  // works page
  const worksPage: PageInterface = (await pageStore.action(pageStoreTypes.actions.getPage, koruConfig.settings.worksPageId)) as PageInterface;
  const result: Record<string, unknown> = await createWebRenderFromWebDocument(
    worksPage,
    templates,
    variables,
    maps,
    settings,
    worksContentRenderer,
    undefined
  );

  const webRenders: WebRenderInterface[] = result.webRenders as WebRenderInterface[];

  for (const webRender of webRenders) {
    if (webRender.id == "new") {
      koruBatch.set(firebaseFirestore.collection("webRenders").doc(), webRenderToFirestore(webRender));
    } else {
      koruBatch.update(firebaseFirestore.collection("webRenders").doc(webRender.id), webRenderToFirestore(webRender));
    }
  }

  await koruBatch.commit();
}

export async function additionalSlugifyCheck(webDocument: WebDocumentInterface, language: string): Promise<boolean> {
  if (webDocument.interfaceType == "Work") {
    const works: WorkInterface[] = await koruDb
      .getModule<WorkModelInterface>("works")
      .getWorksBySlugAndLanguageExceptId(webDocument.slug[language], language, webDocument.id);
    if (works.length > 0) return false;
  }
  return true;
}
