export const blockStoreTypes = {
  actions: {
    getBlocks: "GET_BLOCKS",
    getBlock: "GET_BLOCK",
    createBlock: "CREATE_BLOCK",
    updateBlock: "UPDATE_BLOCK",
    deleteBlock: "DELETE_BLOCK",
    reorderBlocks: "REORDER_BLOCKS",
  },
};
