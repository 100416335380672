
import { computed, defineComponent, onBeforeUnmount, onMounted, Ref, ref } from "vue";

import { NotificationInterface } from "../models/Notification.interface";
import { notificationStoreTypes } from "../store/types";
import { NotificationTypeInterface } from "../models/NotificationTypeInterface";
import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";
import { useNotificationStore } from "../store";

import locales from "../locales";

export default defineComponent({
  name: "NotificationSelector",
  props: {
    topbarNotificationMenuActive: Boolean,
  },
  emits: ["topbar-notification"],
  setup() {
    const { t } = useKoruLocale(locales);
    const koruConfig = useKoruConfig();
    const notificationTypes: Record<string, NotificationTypeInterface> = koruConfig.notification.types;

    const notificationStore = useNotificationStore();
    const notifications: Ref<NotificationInterface[]> = ref([]);
    const notificationsMatrix: Ref<Record<string, number>> = ref({});

    const notificationsCount = computed(() => {
      return notifications.value.length;
    });

    let notificationTimer: number;

    onMounted(() => {
      startNotificationTimer();
    });

    onBeforeUnmount(() => {
      stopNotificationTimer();
    });

    const startNotificationTimer = () => {
      checkNotifications();
      notificationTimer = window.setInterval(checkNotifications, koruConfig.notification.interval);
    };

    const stopNotificationTimer = () => {
      if (notificationTimer) {
        clearInterval(notificationTimer);
      }
    };

    const checkNotifications = async () => {
      notifications.value = (await notificationStore.action(notificationStoreTypes.actions.getUnreadNotifications)) as NotificationInterface[];
      notificationsMatrix.value = (await notificationStore.action(
        notificationStoreTypes.actions.getNotificationsMatrix,
        notifications.value
      )) as Record<string, number>;
    };

    return {
      notificationsMatrix,
      notificationsCount,
      notificationTypes,
      t,
    };
  },
  methods: {
    onTopbarNotificationMenuButtonClick(event: Event): void {
      this.$emit("topbar-notification", event);
    },
  },
});
