import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { koruDb } from "@/core/modules/database";
import { LinkedWorkCategoryInterface } from "../LinkedWorkCategory.interface";
import { WorkCategoryInterface, workCategoryFromFirestore, workCategoryToFirestore } from "../WorkCategory.interface";
import { WorkCategoryModelInterface } from "../WorkCategoryModel.interface";
import { WorkInterface } from "@/features/modules/work/models/Work.interface";
import { WorkModelInterface } from "@/features/modules/work/models/WorkModel.interface";

import { firebaseFirestore } from "@/core/plugins/firebase";

import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  getDocumentsHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";

export const workCategoryModel: WorkCategoryModelInterface = {
  collectionName: "workCategories",
  documentFromFirestore: workCategoryFromFirestore,
  documentToFirestore: workCategoryToFirestore,

  async getDocuments(): Promise<WorkCategoryInterface[]> {
    return await getDocumentsHelper<WorkCategoryInterface>(this.collectionName, "order", "asc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<WorkCategoryInterface> {
    return await getDocumentHelper<WorkCategoryInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: WorkCategoryInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<WorkCategoryInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(document: WorkCategoryInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<WorkCategoryInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: WorkCategoryInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },

  async getWorkCategoriesByTemplate(templateId: string): Promise<WorkCategoryInterface[]> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<WorkCategoryInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("template.id", "==", templateId)
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: WorkCategoryInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });
      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async reorderWorkCategories(workCategories: WorkCategoryInterface[]): Promise<void> {
    try {
      const batch = firebaseFirestore.batch();

      let order = 1;
      for (const workCategory of workCategories) {
        workCategory.order = order;
        batch.update(
          getCollectionReference(this.collectionName)
            .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
            .doc(workCategory.id),
          { order: order }
        );
        order++;
      }
      await batch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

const linkedUpdates = async (batch: firebase.firestore.WriteBatch, workCategory: WorkCategoryInterface): Promise<void> => {
  const linkedWorkCategory: LinkedWorkCategoryInterface = {
    id: workCategory.id,
    name: workCategory.name,
    title: workCategory.title,
    slug: workCategory.slug,
  };

  const works: WorkInterface[] = await koruDb.getModule<WorkModelInterface>("works").getWorksByWorkCategory(workCategory.id);
  works.forEach((work: WorkInterface) => {
    batch.update(getCollectionReference("works").doc(work.id), { workCategory: linkedWorkCategory });
  });
};
