import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";

import { actions } from "./actions";

// eslint-disable-next-line prettier/prettier
export const blockStore: KoruStoreModuleInterface<EmptyStateInterface> = createStoreModule(
  "blockState",
  undefined,
  undefined,
  actions,
);

export const useBlockStore = (): KoruStoreModuleInterface<EmptyStateInterface> => {
  return blockStore;
};
