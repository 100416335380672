export const templateStoreTypes = {
  actions: {
    getTemplates: "GET_TEMPLATES",
    getTemplatesByBlock: "GET_TEMPLATES_BY_BLOCK",
    getTemplate: "GET_TEMPLATE",
    createTemplate: "CREATE_TEMPLATE",
    updateTemplate: "UPDATE_TEMPLATE",
    deleteTemplate: "DELETE_TEMPLATE",
  },
};
