import { RouteRecordRaw } from "vue-router";

export const workRoutes: Array<RouteRecordRaw> = [
  {
    path: "/works",
    name: "WorkList",
    component: () => import("../views/WorkList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "work",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/works/:id",
    name: "WorkEdit",
    component: () => import("../views/WorkEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "work",
      requiredRights: ["readAll", "readOwn", "updateAll", "updateOwn"],
    },
  },
];
