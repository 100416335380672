import { ActionTree } from "vuex";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { NotificationInterface } from "../models/Notification.interface";
import { NotificationModelInterface } from "@/core/modules/notification/models/NotificationModel.interface";
import { notificationStoreTypes } from "./types";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [notificationStoreTypes.actions.getNotifications](): Promise<NotificationInterface[]> {
    try {
      const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);

      return await koruDb.getModule<NotificationModelInterface>("notifications").getNotificationsByOwner(user.id);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.getUnreadNotifications](): Promise<NotificationInterface[]> {
    try {
      const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);

      return await koruDb.getModule<NotificationModelInterface>("notifications").getUnreadNotifications(user.id);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  [notificationStoreTypes.actions.getNotificationsMatrix](context, notifications: NotificationInterface[]): Record<string, number> {
    try {
      const notificationsMatrix: Record<string, number> = {};
      for (const notification of notifications) {
        if (notification.type != undefined) {
          if (Object.keys(notificationsMatrix).indexOf(notification.type) >= 0) {
            notificationsMatrix[notification.type]++;
          } else {
            notificationsMatrix[notification.type] = 1;
          }
        }
      }
      return notificationsMatrix;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.getNotification](context, notificationId: string): Promise<NotificationInterface> {
    try {
      return await koruDb.getModule<NotificationModelInterface>("notifications").getDocument(notificationId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.createNotification](context, notification: NotificationInterface): Promise<void> {
    try {
      await koruDb.getModule<NotificationModelInterface>("notifications").createDocument(notification, false);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.updateNotification](context, notification: NotificationInterface): Promise<void> {
    try {
      await koruDb.getModule<NotificationModelInterface>("notifications").updateDocument(notification, false);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.deleteNotification](context, notification: NotificationInterface): Promise<void> {
    try {
      await koruDb.getModule<NotificationModelInterface>("notifications").deleteDocument(notification, false);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.markNotificationAsRead](context, notificationId: string): Promise<void> {
    try {
      const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);

      await koruDb.getModule<NotificationModelInterface>("notifications").markNotificationAsRead(notificationId, user.id);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.markNotificationAsDeleted](context, notificationId: string): Promise<void> {
    try {
      const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);

      await koruDb.getModule<NotificationModelInterface>("notifications").markNotificationAsDeleted(notificationId, user.id);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
