import { compareAsc } from "date-fns";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { DocumentInterface } from "../database/models/Document.interface";
import { firebaseFirestore } from "@/core/plugins/firebase";
import { koruDb } from "@/core/modules/database";
import { koruLog } from "@/core/modules/log";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export function createFirestoreConverter<T extends DocumentInterface>(
  documentFromFirestore: (data: Record<string, unknown>, id?: string) => T,
  documentToFirestore: (document: T) => Record<string, unknown>
): firebase.firestore.FirestoreDataConverter<T> {
  return {
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
      options: firebase.firestore.SnapshotOptions | undefined
    ): T {
      const data = snapshot.data(options);
      return documentFromFirestore(data, snapshot.id);
    },
    toFirestore: function (document: T): Record<string, unknown> {
      return documentToFirestore(document);
    },
  };
}

export function getCollectionReference(collection: string): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
  return firebaseFirestore.collection(collection);
}

export function getDocumentReference(collection: string): firebase.firestore.DocumentReference {
  return firebaseFirestore.doc(collection);
}

export function hasDocumentChanged(document: DocumentInterface, oldDocument: DocumentInterface): boolean {
  return compareAsc(document.updatedAt, oldDocument.updatedAt) != 0 || document.updatedBy != oldDocument.updatedBy;
}

export function setDocumentFields(documentId: string, document: DocumentInterface): void {
  const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);

  const timestamp = new Date();
  if (documentId == "new") {
    document.createdAt = timestamp;
    document.createdBy = user.id;
    document.updatedAt = timestamp;
    document.updatedBy = user.id;
  } else {
    document.updatedAt = timestamp;
    document.updatedBy = user.id;
  }
}

export function registerDatabaseModule<T extends DocumentInterface>(module: DatabaseModuleInterface<T>): void {
  if (!(module.collectionName in koruDb.modules)) {
    koruDb.modules[module.collectionName] = module;
  }
}

export async function getDocumentsHelper<T extends DocumentInterface>(
  collectionName: string,
  orderBy: string,
  orderDirection = "asc",
  documentFromFirestore: (data: Record<string, unknown>, id?: string) => T,
  documentToFirestore: (data: T) => Record<string, unknown>
): Promise<T[]> {
  try {
    const snapshot: firebase.firestore.QuerySnapshot<T> = await getCollectionReference(collectionName)
      .withConverter(createFirestoreConverter(documentFromFirestore, documentToFirestore))
      .orderBy(orderBy, orderDirection as firebase.firestore.OrderByDirection)
      .get();

    if (snapshot.empty) return [];

    const data: T[] = [];
    snapshot.docs.map((doc) => {
      data.push(doc.data());
    });

    return data;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}

export async function getDocumentHelper<T extends DocumentInterface>(
  documentId: string,
  collectionName: string,
  documentFromFirestore: (data: Record<string, unknown>, id?: string) => T,
  documentToFirestore: (data: T) => Record<string, unknown>
): Promise<T> {
  try {
    const doc: firebase.firestore.DocumentSnapshot<T> = await getCollectionReference(collectionName)
      .withConverter(createFirestoreConverter(documentFromFirestore, documentToFirestore))
      .doc(documentId)
      .get();

    if (doc.exists) {
      return doc.data() as T;
    } else {
      throw new Error(`#${documentId} not found in collection ${collectionName}`);
    }
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}

export async function createDocumentHelper<T extends DocumentInterface>(
  document: T,
  collectionName: string,
  documentFromFirestore: (data: Record<string, unknown>, id?: string) => T,
  documentToFirestore: (data: T) => Record<string, unknown>,
  logAction: boolean
): Promise<string> {
  try {
    const { id: newDocId } = await getCollectionReference(collectionName)
      .withConverter(createFirestoreConverter(documentFromFirestore, documentToFirestore))
      .add(document);

    if (logAction) await koruLog.logInfo(`${document.interfaceType} #${newDocId} created`);

    return newDocId;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}

export async function updateDocumentHelper<T extends DocumentInterface>(
  document: T,
  linkedUpdates: (batch: firebase.firestore.WriteBatch, document: T) => Promise<void>,
  collectionName: string,
  documentFromFirestore: (data: Record<string, unknown>, id?: string) => T,
  documentToFirestore: (data: T) => Record<string, unknown>,
  logAction: boolean
): Promise<void> {
  try {
    const batch = firebaseFirestore.batch();
    batch.set(
      // eslint-disable-next-line prettier/prettier
      getCollectionReference(collectionName)
        .withConverter(createFirestoreConverter(documentFromFirestore, documentToFirestore))
        .doc(document.id),
      document
    );
    await linkedUpdates(batch, document);
    await batch.commit();

    if (logAction) await koruLog.logInfo(`${document.interfaceType} #${document.id} updated`);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}

export async function deleteDocumentHelper<T extends DocumentInterface>(document: T, collectionName: string, logAction: boolean): Promise<void> {
  try {
    await getCollectionReference(collectionName).doc(document.id).delete();

    if (logAction) await koruLog.logInfo(`${document.interfaceType} #${document.id} deleted`);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
