export const allCoreDateTimeFormats = {
  shortDate: {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  },
  mediumDate: {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  },
  longDate: {
    weekday: "short",
    day: "2-digit",
    month: "long",
    year: "numeric",
  },
  hugeDate: {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
  },
  shortDateTime: {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  },
  mediumDateTime: {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  },
  longDateTime: {
    weekday: "short",
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  },
  hugeDateTime: {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  },
  shortMonth: {
    month: "short",
    year: "numeric",
  },
  longMonth: {
    month: "long",
    year: "numeric",
  },
};

export const allCoreNumberFormats = {
  number0: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  number1: {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },
  number2: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  number3: {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  },
  number4: {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  },
  number5: {
    minimumFractionDigits: 5,
    maximumFractionDigits: 5,
  },
  number6: {
    minimumFractionDigits: 6,
    maximumFractionDigits: 6,
  },
  currencyCHF: {
    currency: "CHF",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
  },
  currencyEUR: {
    currency: "EUR",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
  },
  currencyGBP: {
    currency: "GBP",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
  },
  currencyUSD: {
    currency: "USD",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
  },
};
