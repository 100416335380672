import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";

export interface FeedbackInterface extends DocumentInterface {
  date: Date;
  sender: string;
  subject: string;
  message: string;
}

export const createNewFeedback = (): FeedbackInterface => {
  return createDocument<FeedbackInterface>({
    interfaceType: "Feedback",
    date: new Date(),
    sender: undefined,
    subject: undefined,
    message: undefined,
  });
};

export function feedbackFromFirestore(data: Record<string, unknown>, id?: string): FeedbackInterface {
  return documentFromFirestore<FeedbackInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Feedback",
      date: (data.date as firebase.firestore.Timestamp).toDate(),
      sender: data.sender,
      subject: data.subject,
      message: data.message,
    },
    data
  );
}

export function feedbackToFirestore(feedback: FeedbackInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      date: firebase.firestore.Timestamp.fromDate(feedback.date),
      sender: feedback.sender,
      subject: feedback.subject,
      message: feedback.message,
    },
    feedback
  );
}
