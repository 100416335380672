export const settingStoreTypes = {
  actions: {
    getSettings: "GET_SETTINGS",
    getSetting: "GET_SETTING",
    createSetting: "CREATE_SETTING",
    updateSetting: "UPDATE_SETTING",
    deleteSetting: "DELETE_SETTING",
    loadSetting: "LOAD_SETTING",
  },
};
