import { SectionInterface, sectionFromFirestore, sectionToFirestore } from "@/features/modules/section/models/Section.interface";
import {
  WebDocumentInterface,
  createWebDocument,
  webDocumentFromFirestore,
  webDocumentToFirestore,
} from "@/features/modules/web-document/models/WebDocument.interface";

export interface PageInterface extends WebDocumentInterface {
  sections: Record<string, SectionInterface[]>;
  isHomepage: boolean;
  isNotFound: boolean;
}

export const createNewPage = (): PageInterface => {
  return createWebDocument<PageInterface>({
    interfaceType: "Page",
    sections: {},
    isHomepage: false,
    isNotFound: false,
  });
};

export function pageFromFirestore(data: Record<string, unknown>, id?: string): PageInterface {
  const sections: Record<string, SectionInterface[]> = {};
  for (const [key, value] of Object.entries(data.sections as Record<string, unknown>)) {
    sections[key] = (value as Record<string, unknown>[]).map((section: Record<string, unknown>) => {
      return sectionFromFirestore(section);
    });
  }

  return webDocumentFromFirestore<PageInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Page",
      sections: sections,
      isHomepage: (data.isHomepage as boolean) || false,
      isNotFound: (data.isNotFound as boolean) || false,
    },
    data
  );
}

export function pageToFirestore(page: PageInterface): Record<string, unknown> {
  const sections: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(page.sections)) {
    sections[key] = value.map((section: SectionInterface) => {
      return sectionToFirestore(section);
    });
  }

  return webDocumentToFirestore(
    {
      sections: sections,
      isHomepage: page.isHomepage,
      isNotFound: page.isNotFound,
    },
    page
  );
}
