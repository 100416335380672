import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";
import { koruConfig } from "@/core/modules/config";
import { LocationInterface, locationFromFirestore, locationToFirestore } from "@/features/modules/common/models/Location.interface";

export interface MapInterface extends DocumentInterface {
  name: string;
  type: string;
  theme: string;
  center: LocationInterface;
  zoom: number;
  referenceNumber: number;
  code: Record<string, string>;
}

export const createNewMap = (): MapInterface => {
  return createDocument<MapInterface>({
    interfaceType: "Map",
    name: "",
    type: "roadmap",
    theme: "standard",
    center: {
      latitude: koruConfig.settings.mapsDefaultLatitude,
      longitude: koruConfig.settings.mapsDefaultLongitude,
    },
    zoom: koruConfig.settings.mapsDefaultZoom,
    referenceNumber: 0,
    code: {},
  });
};

export function mapFromFirestore(data: Record<string, unknown>, id?: string): MapInterface {
  return documentFromFirestore<MapInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Map",
      name: data.name as string,
      type: data.type as string,
      theme: data.theme as string,
      center: locationFromFirestore(data.center as firebase.firestore.GeoPoint),
      zoom: data.zoom as number,
      referenceNumber: data.referenceNumber as number,
      code: data.code || {},
    },
    data
  );
}

export function mapToFirestore(map: MapInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      name: map.name,
      type: map.type,
      theme: map.theme,
      center: locationToFirestore(map.center),
      zoom: map.zoom,
      referenceNumber: map.referenceNumber,
      code: map.code || null,
    },
    map
  );
}
