import { BackupInterface, createNewBackup, backupFromFirestore, backupToFirestore } from "@/core/modules/backup/models/Backup.interface";
import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";

import { deleteDocumentHelper, getDocumentsHelper } from "@/core/modules/helpers";

export const backupModel: DatabaseModuleInterface<BackupInterface> = {
  collectionName: "backups",
  documentFromFirestore: backupFromFirestore,
  documentToFirestore: backupToFirestore,

  async getDocuments(): Promise<BackupInterface[]> {
    return await getDocumentsHelper<BackupInterface>(this.collectionName, "createdAt", "desc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(): Promise<BackupInterface> {
    return createNewBackup();
  },
  async createDocument(): Promise<string> {
    return "";
  },
  async updateDocument(): Promise<void> {
    return;
  },
  async deleteDocument(document: BackupInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
};
