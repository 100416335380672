import { uniqueId } from "@/core/plugins/unique-id";

export interface SectionInterface {
  id: string;
  width: number;
  type: string;
  value: string | undefined;
  url: string | undefined;
}

export const createNewSection = (sectionType: string): SectionInterface => {
  return {
    id: uniqueId(),
    width: 12,
    type: sectionType,
    value: undefined,
    url: undefined,
  };
};

export function sectionFromFirestore(data: Record<string, unknown>): SectionInterface {
  return {
    id: uniqueId(),
    width: (data.width as number) || 12,
    type: data.type as string,
    value: (data.value as string) || undefined,
    url: (data.url as string) || undefined,
  };
}

export function sectionToFirestore(section: SectionInterface): Record<string, unknown> {
  return {
    width: section.width,
    type: section.type,
    value: section.value || null,
    url: section.url || null,
  };
}
