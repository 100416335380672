import { ActionTree } from "vuex";
import { compareAsc } from "date-fns";

import { ImageInterface } from "@/features/modules/common/models/Image.interface";
import { koruConfig } from "@/core/modules/config";
import { KoruBatch } from "@/core/modules/batch";
import { koruDb } from "@/core/modules/database";
import { koruStorage } from "@/core/modules/storage";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { MapInterface } from "@/features/modules/map/models/Map.interface";
import { mapStore } from "@/features/modules/map/store";
import { mapStoreTypes } from "@/features/modules/map/store/types";
import { PageInterface } from "@/features/modules/page/models/Page.interface";
import { pageStore } from "@/features/modules/page/store";
import { pageStoreTypes } from "@/features/modules/page/store/types";
import { SettingInterface } from "@/features/modules/setting/models/Setting.interface";
import { settingStore } from "@/features/modules/setting/store";
import { settingStoreTypes } from "@/features/modules/setting/store/types";
import { TemplateInterface } from "@/features/modules/template/models/Template.interface";
import { templateStore } from "@/features/modules/template/store";
import { templateStoreTypes } from "@/features/modules/template/store/types";
import { VariableInterface } from "@/features/modules/variable/models/Variable.interface";
import { variableStore } from "@/features/modules/variable/store";
import { variableStoreTypes } from "@/features/modules/variable/store/types";
import { WebDocumentInterface } from "@/features/modules/web-document/models/WebDocument.interface";
import { WebRenderInterface, webRenderToFirestore } from "@/features/modules/web-render/models/WebRender.interface";
import { webRenderStore } from "@/features/modules/web-render/store";
import { webRenderStoreTypes } from "@/features/modules/web-render/store/types";
import { WorkCategoryInterface } from "@/features/modules/work-category/models/WorkCategory.interface";
import { WorkCategoryModelInterface } from "@/features/modules/work-category/models/WorkCategoryModel.interface";
import { workCategoryStore } from "@/features/modules/work-category/store";
import { workCategoryStoreTypes } from "@/features/modules/work-category/store/types";
import { WorkInterface, workToFirestore } from "../models/Work.interface";
import { WorkModelInterface } from "../models/WorkModel.interface";
import { WorkStateInterface } from "../models/WorkState.interface";
import { workStore } from ".";
import { workStoreTypes } from "./types";

import { firebaseFirestore } from "@/core/plugins/firebase";
import { uniqueId } from "@/core/plugins/unique-id";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";
import { setWebDocumentFields } from "@/features/modules/web-document";

import { createWebRenderFromWebDocument } from "@/features/modules/helpers";

export const actions: ActionTree<WorkStateInterface, KoruStateInterface> = {
  async [workStoreTypes.actions.getWorks](): Promise<WorkInterface[]> {
    try {
      return await koruDb.getModule<WorkModelInterface>("works").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workStoreTypes.actions.getWorksByTemplate](context, templateId: string): Promise<WorkInterface[]> {
    try {
      return await koruDb.getModule<WorkModelInterface>("works").getWorksByTemplate(templateId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workStoreTypes.actions.getWorksByWorkCategory](context, workCategoryId: string): Promise<WorkInterface[]> {
    try {
      return await koruDb.getModule<WorkModelInterface>("works").getWorksByWorkCategory(workCategoryId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workStoreTypes.actions.getWork](context, workId: string): Promise<WorkInterface> {
    try {
      return await koruDb.getModule<WorkModelInterface>("works").getDocument(workId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workStoreTypes.actions.createWork](context, work: WorkInterface): Promise<string> {
    try {
      await setWebDocumentFields(work);
      setDocumentFields(work.id, work);
      work.id = await koruDb.getModule<WorkModelInterface>("works").createDocument(work, true);

      await context.dispatch(workStoreTypes.actions.processWorkUpdate, work);

      const works: WorkInterface[] = [];
      if (work.order > 1) {
        const prevWork: WorkInterface | undefined = await koruDb.getModule<WorkModelInterface>("works").getWorkByOrder(work.order - 1);
        if (prevWork != undefined) works.push(prevWork);
      }
      const nextWork: WorkInterface | undefined = await koruDb.getModule<WorkModelInterface>("works").getWorkByOrder(work.order + 1);
      if (nextWork != undefined) works.push(nextWork);
      if (works.length > 0) await context.dispatch(workStoreTypes.actions.processWorksUpdate, works);

      return work.id;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workStoreTypes.actions.updateWork](context, work: WorkInterface): Promise<void> {
    try {
      const oldWork: WorkInterface = await koruDb.getModule<WorkModelInterface>("works").getDocument(work.id);
      if (hasDocumentChanged(work, oldWork)) throw new Error("sync");

      await setWebDocumentFields(work);
      setDocumentFields(work.id, work);
      await koruDb.getModule<WorkModelInterface>("works").updateDocument(work, true);

      await context.dispatch(workStoreTypes.actions.processWorkUpdate, work);

      const works: WorkInterface[] = [];
      if (work.order > 1) {
        const prevWork: WorkInterface | undefined = await koruDb.getModule<WorkModelInterface>("works").getWorkByOrder(work.order - 1);
        if (prevWork != undefined) works.push(prevWork);
      }
      const nextWork: WorkInterface | undefined = await koruDb.getModule<WorkModelInterface>("works").getWorkByOrder(work.order + 1);
      if (nextWork != undefined) works.push(nextWork);
      if (works.length > 0) await context.dispatch(workStoreTypes.actions.processWorksUpdate, works);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workStoreTypes.actions.processWorkUpdate](context, work: WorkInterface): Promise<void> {
    try {
      const payload: Record<string, unknown> = {
        webDocument: work,
        contentRenderer: undefined,
        customVariables: await createWorkCustomVariables(work),
      };
      const updatedWork: WorkInterface = (await webRenderStore.action(
        webRenderStoreTypes.actions.processWebRenderForWebDocument,
        payload
      )) as WorkInterface;

      const workCategory: WorkCategoryInterface = await koruDb
        .getModule<WorkCategoryModelInterface>("workCategories")
        .getDocument(work.workCategory?.id as string);
      await workCategoryStore.action(workCategoryStoreTypes.actions.processWorkCategoryUpdate, workCategory);

      await koruDb.getModule<WorkModelInterface>("works").updateDocument(updatedWork, false);

      await processWorksPage();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workStoreTypes.actions.processWorksUpdate](context, works: WorkInterface[]): Promise<void> {
    try {
      const templates: TemplateInterface[] = (await templateStore.action(templateStoreTypes.actions.getTemplates)) as TemplateInterface[];
      const variables: VariableInterface[] = (await variableStore.action(variableStoreTypes.actions.getVariables)) as VariableInterface[];
      const maps: MapInterface[] = (await mapStore.action(mapStoreTypes.actions.getMaps)) as MapInterface[];

      const settings: SettingInterface = (await settingStore.action(settingStoreTypes.actions.getSetting)) as SettingInterface;

      const koruBatch = new KoruBatch();

      for (const work of works) {
        const result: Record<string, unknown> = await createWebRenderFromWebDocument(
          work,
          templates,
          variables,
          maps,
          settings,
          undefined,
          await createWorkCustomVariables(work)
        );

        const webRenders: WebRenderInterface[] = result.webRenders as WebRenderInterface[];
        const updatedWebDocument: WebDocumentInterface = result.webDocument as WebDocumentInterface;

        for (const webRender of webRenders) {
          if (webRender.id == "new") {
            koruBatch.set(firebaseFirestore.collection("webRenders").doc(), webRenderToFirestore(webRender));
          } else {
            koruBatch.update(firebaseFirestore.collection("webRenders").doc(webRender.id), webRenderToFirestore(webRender));
          }
        }
        koruBatch.set(firebaseFirestore.collection("works").doc(updatedWebDocument.id), workToFirestore(updatedWebDocument as WorkInterface));
      }

      await koruBatch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workStoreTypes.actions.deleteWork](context, work: WorkInterface): Promise<void> {
    try {
      if (work.featuredImage != undefined) {
        context.dispatch(workStoreTypes.actions.deleteImage, work.featuredImage.uniqueName);
      }

      await koruDb.getModule<WorkModelInterface>("works").deleteDocument(work, true);

      await workCategoryStore.action(workCategoryStoreTypes.actions.processWorkCategoryUpdate, work);

      const payload: Record<string, unknown> = { webDocument: work };
      await webRenderStore.action(webRenderStoreTypes.actions.deleteWebRenderForWebDocument, payload);

      const works: WorkInterface[] = await koruDb.getModule<WorkModelInterface>("works").getDocuments();
      await context.dispatch(workStoreTypes.actions.processWorksUpdate, works);

      await processWorksPage();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workStoreTypes.actions.reorderWorks](context, works: WorkInterface[]): Promise<void> {
    try {
      await koruDb.getModule<WorkModelInterface>("works").reorderWorks(works);

      await context.dispatch(workStoreTypes.actions.processWorksUpdate, works);

      const workCategories: WorkCategoryInterface[] = (await workCategoryStore.action(
        workCategoryStoreTypes.actions.getWorkCategories
      )) as WorkCategoryInterface[];

      await workCategoryStore.action(workCategoryStoreTypes.actions.processWorkCategoriesUpdate, workCategories);

      await processWorksPage();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workStoreTypes.actions.uploadImage](context, file: File): Promise<ImageInterface> {
    try {
      const extension = file.name.split(".").pop();
      const uniqueName = uniqueId() + "." + extension;

      const downloadUrl: string = await koruStorage.upload(koruConfig.storage.folders.workFeaturedImages + uniqueName, file, (progress: number) => {
        context.commit(workStoreTypes.mutations.setUploadProgressValue, progress);
      });
      const workImage: ImageInterface = {
        displayName: file.name,
        uniqueName: uniqueName,
        type: extension?.toUpperCase() || "",
        size: file.size,
        url: downloadUrl,
      };

      return workImage;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [workStoreTypes.actions.deleteImage](context, uniqueName: string): Promise<void> {
    try {
      await koruStorage.delete(koruConfig.storage.folders.workFeaturedImages + uniqueName);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

export async function processWorksPage() {
  const page: PageInterface = (await pageStore.action(pageStoreTypes.actions.getPage, koruConfig.settings.worksPageId)) as PageInterface;

  const payload: Record<string, unknown> = { webDocument: page, contentRenderer: worksContentRenderer };
  await webRenderStore.action(webRenderStoreTypes.actions.processWebRenderForWebDocument, payload);
}

export async function worksContentRenderer(language: string): Promise<string> {
  let markup = "";

  const works: WorkInterface[] = (await workStore.action(workStoreTypes.actions.getWorks)) as WorkInterface[];

  const today: Date = new Date();
  today.setHours(0, 0, 0, 0);

  for (const work of works) {
    if (work.publicAt !== undefined && compareAsc(work.publicAt, today) > 0) continue;

    markup += '<div class="col-lg-4 col-md-6">';
    markup += '<div class="single__gallery__thumb" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">';
    markup += `<a href="${work.url[language]}" id="item__link"></a>`;
    markup += '<div class="rario ratio-4x3">';
    markup += `<img src="${work.featuredImage?.url}" alt="">`;
    markup += "</div>";
    markup += '<div class="gallery__overlay">';
    markup += '<div class="overlay__text__wrap">';
    markup += "<span></span>";
    markup += '<div class="gallery__text">';
    markup += `<p>${work.workCategory?.title[language]}</p>`;
    markup += `<p>${work.title[language]}</p>`;
    markup += "</div>";
    markup += "</div>";
    markup += "</div>";
    markup += "</div>";
    markup += "</div>";
  }

  return markup;
}

export async function createWorkCustomVariables(work: WorkInterface): Promise<(language: string) => Record<string, string>> {
  let prevWork: WorkInterface | undefined = undefined;
  if (work.order > 1) {
    prevWork = await koruDb.getModule<WorkModelInterface>("works").getWorkByOrder(work.order - 1);
  }

  let nextWork: WorkInterface | undefined = undefined;
  nextWork = await koruDb.getModule<WorkModelInterface>("works").getWorkByOrder(work.order + 1);

  let workCategory: WorkCategoryInterface | undefined = undefined;
  try {
    workCategory = (await workCategoryStore.action(workCategoryStoreTypes.actions.getWorkCategory, work.workCategory?.id)) as WorkCategoryInterface;
  } catch (error: unknown) {
    console.log("no work category");
  }

  return (language: string): Record<string, string> => {
    const variables: Record<string, string> = {};

    let markup = "";
    if (prevWork == undefined) {
      markup += '<span><i class="left fal fa-angle-left"></i>%PREVIOUS%</span>';
    } else {
      markup += `<a href="${prevWork.url[language]}"><i class="left fal fa-angle-left"></i>%PREVIOUS%</a>`;
    }

    if (workCategory != undefined) {
      markup += `<a href="${workCategory.url[language]}" class="text__center">${workCategory.title[language]}</a>`;
    }

    if (nextWork == undefined) {
      markup += '<span>%NEXT%<i class="right fal fa-angle-right"></i></span>';
    } else {
      markup += `<a href="${nextWork.url[language]}">%NEXT%<i class="right fal fa-angle-right"></i></a>`;
    }

    variables["!%WORKPAGINATION%!"] = markup;

    return variables;
  };
}
