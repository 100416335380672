import { RouteRecordRaw } from "vue-router";

export const settingRoutes: Array<RouteRecordRaw> = [
  {
    path: "/settings",
    name: "SettingEdit",
    component: () => import("../views/SettingEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "block",
      requiredRights: ["updateAll", "updateOwn"],
    },
  },
];
