import { GetterTree } from "vuex";
import { KoruStateInterface } from "./models/KoruState.interface";
import { koruStoreTypes } from "./types";

export const getters: GetterTree<KoruStateInterface, KoruStateInterface> = {
  [koruStoreTypes.getters.isEditing]: (state: KoruStateInterface): boolean => {
    return state.editing;
  },
  [koruStoreTypes.getters.isLoading]: (state: KoruStateInterface): boolean => {
    return state.loading;
  },
  [koruStoreTypes.getters.getColorScheme]: (state: KoruStateInterface): string => {
    return state.colorScheme;
  },
  [koruStoreTypes.getters.getPreviousRoute]: (state: KoruStateInterface): string | undefined => {
    return state.previousRoute;
  },
  [koruStoreTypes.getters.showEditingDialog]: (state: KoruStateInterface): boolean => {
    return state.editingDialog;
  },
};
