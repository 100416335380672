export const enFeaturesLabels = {
  buttons: {
    applyToOtherLanguages: "Apply to other languages",
    goToPoints: "Go to Points",
  },
  navigation: {
    blocks: "Blocks",
    content: "Content",
    maps: "Maps",
    pages: "Pages",
    portfolio: "Portfolio",
    settings: "Settings",
    structure: "Structure",
    templates: "Templates",
    variables: "Variables",
    works: "Works",
    workCategories: "Categories",
  },
  roleModules: {
    block: "Blocks",
    map: "Maps",
    mapPoint: "Maps - Points",
    page: "Pages",
    setting: "Settings",
    template: "Templates",
    variable: "Variables",
    workCategory: "Portfolio - Categories",
    work: "Portfolio - Works",
  },
  toast: {
    error: {
      deleteImage: "Unable to delete the image",
      geocodeError: "Unable to find the address",
    },
    success: {
      deleteImage: "Image deleted successfully",
    },
  },
};
