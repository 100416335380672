import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { LinkedUserInterface } from "@/core/modules/user/models/LinkedUser.interface";
import { UserInterface, userFromFirestore, userToFirestore } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "../UserModel.interface";

import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  getDocumentsHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";
import { setUserCascadeUpdates } from "@/features/modules/helpers";

export const userModel: UserModelInterface = {
  collectionName: "users",
  documentFromFirestore: userFromFirestore,
  documentToFirestore: userToFirestore,

  async getDocuments(): Promise<UserInterface[]> {
    return await getDocumentsHelper<UserInterface>(this.collectionName, "lastName", "asc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<UserInterface> {
    return await getDocumentHelper<UserInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: UserInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<UserInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async updateDocument(document: UserInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<UserInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: UserInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },

  async getUsersByRole(roleId: string): Promise<UserInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<UserInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("role.id", "==", roleId)
        .orderBy("lastName")
        .orderBy("firstName")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: UserInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getUserIdsByModuleAndRight(module: string, right: string): Promise<string[]> {
    try {
      let snapshot: firebase.firestore.QuerySnapshot<UserInterface>;

      if (module == "any" || right == "any") {
        // eslint-disable-next-line prettier/prettier
        snapshot = await getCollectionReference(this.collectionName)
          .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
          .orderBy("lastName")
          .get();
      } else {
        snapshot = await getCollectionReference(this.collectionName)
          .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
          .where(`role.${right}`, "array-contains", module)
          .orderBy("lastName")
          .get();
      }

      if (snapshot.empty) {
        return [];
      }

      const data: string[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<UserInterface>) => {
        data.push(doc.data().id);
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getUserCountByEmail(email: string, oldEmail: string): Promise<number> {
    try {
      if (email == undefined) return 0;
      if (oldEmail == undefined) oldEmail = "nil";
      const snapshot: firebase.firestore.QuerySnapshot<UserInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("email", "==", email)
        .where("email", "!=", oldEmail)
        .get();

      return snapshot.size;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

const linkedUpdates = async (batch: firebase.firestore.WriteBatch, user: UserInterface): Promise<void> => {
  const linkedUser: LinkedUserInterface = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  };
  await setUserCascadeUpdates(batch, linkedUser);
};
