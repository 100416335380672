export const it = {
  notification: {
    alert: "Hai {count} nuova notifica | Hai {count} nuove notifiche",
    messages: {
      backup: {
        failed: "Non è stato possibile completare il backup",
        success: "Backup completato con successo",
      },
      releaseCreated: "È stata rilasciata la versione {message}",
      userCreated: "È stato creato un utente per {message}",
    },
    none: "Nessuna notifica da leggere",
    notifications: "Notifiche",
    types: {
      backup: "Backup",
      releaseCreated: "Nuovi Rilasci",
      userCreated: "Nuovi Utenti",
    },
  },
};
