import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { WorkInterface, workFromFirestore, workToFirestore } from "@/features/modules/work/models/Work.interface";
import { WorkModelInterface } from "../WorkModel.interface";

import { firebaseFirestore } from "@/core/plugins/firebase";

import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  getDocumentsHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";

export const workModel: WorkModelInterface = {
  collectionName: "works",
  documentFromFirestore: workFromFirestore,
  documentToFirestore: workToFirestore,

  async getDocuments(): Promise<WorkInterface[]> {
    return await getDocumentsHelper<WorkInterface>(this.collectionName, "order", "asc", this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<WorkInterface> {
    return await getDocumentHelper<WorkInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: WorkInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<WorkInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async updateDocument(document: WorkInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<WorkInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: WorkInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },

  async getWorksByTemplate(templateId: string): Promise<WorkInterface[]> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<WorkInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("template.id", "==", templateId)
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: WorkInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });
      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getWorksByWorkCategory(workCategoryId: string): Promise<WorkInterface[]> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<WorkInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("workCategory.id", "==", workCategoryId)
        .orderBy("order", "asc")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: WorkInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });
      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getWorksByMapReferenceNumber(mapReferenceNumber: number): Promise<WorkInterface[]> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<WorkInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: WorkInterface[] = [];
      snapshot.docs.map((doc) => {
        for (const section of Object.values(doc.data().sections)) {
          if (section.find((item) => item.value?.includes(`%MAP-${mapReferenceNumber}%`))) {
            if (!data.includes(doc.data())) data.push(doc.data());
            break;
          }
        }
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getWorksBySlugAndLanguageExceptId(slug: string, language: string, exceptId: string): Promise<WorkInterface[]> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<WorkInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where(`slug.${language}`, "==", slug)
        .where("id", "!=", exceptId)
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: WorkInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });
      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getWorkByOrder(order: number): Promise<WorkInterface | undefined> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<WorkInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("order", "==", order)
        .get();

      if (snapshot.empty || snapshot.docs.length <= 0) {
        return undefined;
      }

      return snapshot.docs[0].data();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async reorderWorks(works: WorkInterface[]): Promise<void> {
    try {
      const batch = firebaseFirestore.batch();

      let order = 1;
      for (const work of works) {
        work.order = order;
        batch.update(
          getCollectionReference(this.collectionName)
            .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
            .doc(work.id),
          { order: order }
        );
        order++;
      }
      await batch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

const linkedUpdates = async (): Promise<void> => {
  return Promise.resolve();
};
