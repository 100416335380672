import firebase from "firebase/compat/app";
import "firebase/compat/storage";

import { firebaseStorage } from "@/core/plugins/firebase";
import { StorageInterface } from "../models/Storage.interface";

const storageRef = firebaseStorage.ref();

export class FirebaseStorageModel implements StorageInterface {
  upload(name: string, file: File, callback: (progress: number) => void): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const uploadTask: firebase.storage.UploadTask = storageRef.child(name).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot: firebase.storage.UploadTaskSnapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          callback(Math.floor(progress));
        },
        (error: firebase.storage.FirebaseStorageError) => {
          // Handle unsuccessful uploads
          console.log(error);
          reject();
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl: string) => {
            console.log("File available at", downloadUrl);
            resolve(downloadUrl);
          });
        }
      );
    });
  }

  async delete(name: string): Promise<void> {
    try {
      // eslint-disable-next-line prettier/prettier
      storageRef
        .child(name)
        .delete();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }
}
