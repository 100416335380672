import { KoruStateInterface } from "./models/KoruState.interface";

export const initialState: KoruStateInterface = {
  colorScheme: "light",
  editing: false,
  editingDialog: false,
  loading: false,
  previousRoute: undefined,
  route: undefined,
};
