export const itCoreLabels = {
  auth: {
    askNewPassword: "Richiedi Nuova Password",
    authenticationSubtitle: "Accedi con il tuo account",
    authenticationTitle: "Autenticazione",
    checkInbox: "Controlla la tua casella e-mail",
    choosePassword: "Scegli una nuova password",
    email: "E-mail",
    emailBlank: "Inserisci l'indirizzo e-mail",
    emailError: "L'indirizzo e-mail non è valido",
    forgotError: "Non è stato possibile recuperare la password",
    forgotPasswordTitle: "Recupera Password",
    forgotPasswordStart: "Non ricordi la password?",
    forgotPasswordMiddle: "Richiedi",
    forgotPasswordEnd: "il recupero subito!",
    inputEmail: "Inserisci il tuo indirizzo e-mail",
    login: "Accedi",
    loginFailed: "Autenticazione non riuscita",
    password: "Password",
    passwordBlank: "Inserisci la nuova password",
    passwordConfirm: "Conferma Nuova Password",
    passwordConfirmBlank: "Inserisci la conferma della nuova password",
    passwordConfirmError: "Le due password non coincidono",
    passwordError: "Inserisci una password valida",
    passwordErrorChars: "La password deve essere di almeno 8 caratteri",
    passwordErrorLowercase: "Inserisci almeno un carattere minuscolo",
    passwordErrorNumber: "Inserisci almeno una cifra numerica",
    passwordErrorUppercase: "Inserisci almeno un carattere maiuscolo",
    passwordRequirementsTitle: "Requisiti",
    passwordRequirementsChars: "Minimo 8 caratteri",
    passwordRequirementsLowercase: "Almeno un carattere minuscolo",
    passwordRequirementsNumber: "Almeno una cifra numerica",
    passwordRequirementsUppercase: "Almeno un carattere maiuscolo",
    passwordRememberedStart: "Ricordi la password? Torna all'",
    passwordRememberedEnd: "autenticazione",
    recoverPasswordTitle: "Ripristina Password",
    resetPassword: "Reimposta Password",
    resetPasswordError: "Non è stato possibile reimpostare la password",
    resetPasswordSuccessStart: "La nuova password è stata impostata. Vai all'",
    resetPasswordSuccessEnd: "autenticazione",
  },
  buttons: {
    clear: "Pulisci",
    clone: "Duplica",
    close: "Chiudi",
    delete: "Elimina",
    download: "Scarica",
    disableUser: "Disattiva Utente",
    edit: "Modifica",
    enableUser: "Attiva Utente",
    export: "Esporta",
    import: "Importa",
    markAsRead: "Segna come letto",
    print: "Stampa",
    save: "Salva",
    send: "Invia",
    sendNotification: "Invia Notifica",
    toggleUser: "Inverti Stato",
    view: "Visualizza",
  },
  dialog: {
    confirmCloseWithoutSave: "Chiudere senza salvare?",
    confirmDelete: "Confermi l'eliminazione?",
    editingConfirm: "I dati non salvati verranno persi, sei sicuro?",
    editingTitle: "Uscita senza salvataggio",
  },
  gen: {
    actions: "Azioni",
    any: "Qualsiasi",
    all: "Tutti",
    backTo: "Torna a",
    dashboard: "Plancia",
    deleteConfirm: "Sei sicuro di voler eliminare",
    deleteObject: "l'oggetto selezionato?",
    deleteTitle: "Conferma Eliminazione",
    developedby: "sviluppato da",
    editPrefix: "Modifica",
    empty: "Vuoto",
    errorContact: "C'è stato un errore, contatta lo sviluppatore",
    filter: "Filtra",
    guest: "Ospite",
    inputAddress: "Inserisci un indirizzo",
    loading: "Caricamento in corso",
    logout: "Esci",
    missingPrivileges: "Non hai i privilegi necessari per visualizzare la pagina",
    newPrefix: "Crea",
    no: "No",
    noData: "Nessun dato disponibile",
    ok: "Ok",
    oops: "OOPS!",
    pageNotFound: "La pagina richiesta non è disponibile",
    pageResults: "Risultati da [[first]] a [[last]] di [[totalRecords]] totali",
    profile: "Profilo",
    reorder: "Ordina",
    search: "Cerca",
    user: "Utente",
    viewPrefix: "Visualizza",
    yes: "Sì",
  },
  navigation: {
    about: "Informazioni",
    administration: "Amministrazione",
    backup: "Backup",
    changelog: "Note di rilascio",
    dashboard: "Plancia",
    exchange: "Scambio dati",
    feedback: "Feedback",
    general: "Generale",
    log: "Registro azioni",
    roles: "Ruoli",
    users: "Utenti",
    who: "Chi siamo",
    workbench: "Banco di lavoro",
  },
  profile: {
    changeEmail: "Cambio E-mail",
    changePassword: "Cambio Password",
    changePasswordButton: "Cambia Password",
    email: "E-mail",
    oldPassword: "Vecchia Password",
    oldPasswordBlank: "Inserisci la vecchia password",
    newEmail: "Nuovo Indirizzo E-mail",
    newEmailError: "Inserisci un indirizzo e-mail valido",
    newPassword: "Nuova Password",
    newPasswordBlank: "Inserisci la nuova password",
  },
  rights: {
    readAll: "Leggi Tutti",
    readOwn: "Leggi Propri",
    create: "Crea",
    updateAll: "Modifica Tutti",
    updateOwn: "Modifica Propri",
    deleteAll: "Elimina Tutti",
    deleteOwn: "Elimina Propri",
  },
  roleModules: {
    backup: "Backup",
    changelog: "Note di rilascio",
    exchange: "Scambio dati",
    log: "Registro azioni",
    role: "Ruoli",
    user: "Utenti",
    workbench: "Banco di lavoro",
  },
  themes: {
    dim: "Notte",
    light: "Chiaro",
    solarized: "Solarizzato",
  },
  toast: {
    error: {
      actionError: "Non è stato possibile completare l'azione",
      backupDataNotSet: "I dati di backup non sono stati caricati",
      children: "Sono presenti elementi collegati",
      delete: "Non è stato possibile effettuare l'eliminazione",
      deleteFile: "Non è stato possibile eliminare il file",
      emailChanged: "Non è stato possibile modificare l'indirizzo e-mail",
      feedbackSent: "Non è stato possibile inviare il feedback",
      fiscalCode: "Non è stato possibile calcolare il codice fiscale",
      generic: "È stato riscontrato un errore non previsto",
      noAction: "Nessuna azione è stata selezionata",
      notificationSent: "Non è stato possibile inviare la notifica",
      notSupportedFileExtension: "Il tipo di file caricato non è supportato",
      passwordChanged: "Non è stato possibile modificare la password",
      save: "Non è stato possibile effettuare il salvataggio",
      sync: "I dati sono stati modificati da un altro utente",
      title: "Errore!",
      toggleUser: "Errore durante la modifica dello stato",
      uploadFile: "Non è stato possibile caricare il file",
    },
    success: {
      actionSuccess: "Azione eseguita correttamente",
      backupDownloadSuccess: "Backup generato correttamente",
      backupRestoreSuccess: "Backup ripristinato correttamente",
      delete: "Eliminazione eseguita correttamente",
      emailChanged: "Indirizzo e-mail modificato correttamente",
      feedbackSent: "Feedback inviato correttamente",
      generic: "Operazione completata correttamente",
      notificationSent: "Notifica inviata correttamente",
      passwordChanged: "Password modificata correttamente",
      save: "Salvataggio eseguito correttamente",
      title: "Completato!",
      toggleUser: "Stato modificato correttamente",
      uploadFile: "Il file è stato caricato correttamente",
    },
    warning: {
      formInvalid: "Verificare i campi inseriti",
      title: "Attenzione!",
    },
  },
};
