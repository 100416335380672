import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { SettingInterface, settingFromFirestore, settingToFirestore } from "../Setting.interface";

import { createDocumentHelper, deleteDocumentHelper, getDocumentHelper, getDocumentsHelper, updateDocumentHelper } from "@/core/modules/helpers";

export const settingModel: DatabaseModuleInterface<SettingInterface> = {
  collectionName: "settings",
  documentFromFirestore: settingFromFirestore,
  documentToFirestore: settingToFirestore,

  async getDocuments(): Promise<SettingInterface[]> {
    return await getDocumentsHelper<SettingInterface>(
      this.collectionName,
      "defaultLanguage",
      "asc",
      this.documentFromFirestore,
      this.documentToFirestore
    );
  },
  async getDocument(documentId: string): Promise<SettingInterface> {
    return await getDocumentHelper<SettingInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: SettingInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<SettingInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(document: SettingInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<SettingInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: SettingInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
};

const linkedUpdates = async (): Promise<void> => {
  return Promise.resolve();
};
