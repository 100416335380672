import { ActionTree } from "vuex";

import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { SettingInterface, createNewSetting } from "../models/Setting.interface";
import { settingStoreTypes } from "./types";
import { webRenderStore } from "@/features/modules/web-render/store";
import { webRenderStoreTypes } from "@/features/modules/web-render/store/types";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [settingStoreTypes.actions.getSettings](): Promise<SettingInterface[]> {
    try {
      return await koruDb.getModule<DatabaseModuleInterface<SettingInterface>>("settings").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [settingStoreTypes.actions.getSetting](): Promise<SettingInterface | undefined> {
    try {
      const settings: SettingInterface[] = await koruDb.getModule<DatabaseModuleInterface<SettingInterface>>("settings").getDocuments();
      if (settings != undefined && settings.length > 0) {
        saveSettingToLocalStorage(settings[0]);
        return settings[0];
      }
      return undefined;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [settingStoreTypes.actions.createSetting](context, setting: SettingInterface): Promise<string> {
    try {
      setDocumentFields(setting.id, setting);
      return await koruDb.getModule<DatabaseModuleInterface<SettingInterface>>("settings").createDocument(setting, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [settingStoreTypes.actions.updateSetting](context, setting: SettingInterface): Promise<void> {
    try {
      const oldSetting: SettingInterface = await koruDb.getModule<DatabaseModuleInterface<SettingInterface>>("settings").getDocument(setting.id);
      if (hasDocumentChanged(setting, oldSetting)) throw new Error("sync");

      setDocumentFields(setting.id, setting);
      await koruDb.getModule<DatabaseModuleInterface<SettingInterface>>("settings").updateDocument(setting, true);

      await webRenderStore.action(webRenderStoreTypes.actions.processWebRenderForGeneralUpdate);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [settingStoreTypes.actions.deleteSetting](context, setting: SettingInterface): Promise<void> {
    try {
      await koruDb.getModule<DatabaseModuleInterface<SettingInterface>>("settings").deleteDocument(setting, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

const saveSettingToLocalStorage = (setting: SettingInterface): void => {
  localStorage.setItem("setting", JSON.stringify(setting));
};

export const loadSettingFromLocalStorage = (): SettingInterface => {
  let setting: SettingInterface = createNewSetting();
  const localSetting: string | null = localStorage.getItem("setting");

  if (localSetting != null) {
    setting = JSON.parse(localSetting);
  }
  return setting;
};
