import { koruDb } from "@/core/modules/database";
import { MapInterface } from "@/features/modules/map/models/Map.interface";
import { SettingInterface } from "@/features/modules/setting/models/Setting.interface";
import { TemplateInterface } from "@/features/modules/template/models/Template.interface";
import { VariableInterface } from "@/features/modules/variable/models/Variable.interface";
import { WebDocumentInterface } from "@/features/modules/web-document/models/WebDocument.interface";
import { WebRenderInterface, createNewWebRender } from "@/features/modules/web-render/models/WebRender.interface";
import { WebRenderModelInterface } from "@/features/modules/web-render/models/WebRenderModel.interface";

import { setDocumentFields } from "@/core/modules/helpers";

import { generateMarkup, generateUrls } from "./markup-helpers";

export async function createWebRenderFromWebDocument(
  webDocument: WebDocumentInterface,
  templates: TemplateInterface[],
  variables: VariableInterface[],
  maps: MapInterface[],
  settings: SettingInterface,
  contentRenderer: ((language: string) => Promise<string>) | undefined,
  customVariables: ((language: string) => Record<string, string>) | undefined
): Promise<{ webRenders: WebRenderInterface[]; webDocument: WebDocumentInterface }> {
  for (const language of settings.languages) {
    const isHomepage: boolean = Object.keys(webDocument).includes("isHomepage") ? (webDocument.isHomepage as boolean) : false;
    const urls = generateUrls(webDocument, settings, language, isHomepage);
    webDocument.url[language] = urls[0];
  }

  const webRenders: WebRenderInterface[] = [];
  for (const language of settings.languages) {
    const isHomepage: boolean = Object.keys(webDocument).includes("isHomepage") ? (webDocument.isHomepage as boolean) : false;
    const isNotFound: boolean = Object.keys(webDocument).includes("isNotFound") ? (webDocument.isNotFound as boolean) : false;

    if (isNotFound && language != settings.defaultLanguage) continue;

    const webRender: WebRenderInterface = createNewWebRender();
    webRender.urls = generateUrls(webDocument, settings, language, isHomepage);
    webDocument.url[language] = webRender.urls[0];
    webRender.markup = await generateMarkup(webDocument, templates, variables, maps, settings, language, contentRenderer, customVariables);
    webRender.type = webDocument.interfaceType;
    webRender.reference = isNotFound && language == settings.defaultLanguage ? "404" : webDocument.id;
    webRender.language = language;
    webRender.publicAt = webDocument.publicAt;

    const checkWebRender: WebRenderInterface | undefined = await koruDb
      .getModule<WebRenderModelInterface>("webRenders")
      .getWebRenderByTypeAndReferenceAndLanguage(webRender.type, webRender.reference, webRender.language);

    if (checkWebRender != undefined) {
      webRender.id = checkWebRender.id;
      webRender.createdAt = checkWebRender.createdAt;
      webRender.createdBy = checkWebRender.createdBy;
    }
    setDocumentFields(webRender.id, webRender);

    webRenders.push(webRender);
  }

  return { webRenders: webRenders, webDocument: webDocument };
}
