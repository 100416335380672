import { RouteRecordRaw } from "vue-router";

export const workCategoryRoutes: Array<RouteRecordRaw> = [
  {
    path: "/work-categories",
    name: "WorkCategoryList",
    component: () => import("../views/WorkCategoryList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "workCategory",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/work-categories/:id",
    name: "WorkCategoryEdit",
    component: () => import("../views/WorkCategoryEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "workCategory",
      requiredRights: ["readAll", "readOwn", "updateAll", "updateOwn"],
    },
  },
];
