import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { AuthInterface } from "../models/Auth.interface";
import { authStoreTypes } from "../store/types";
import { firebaseAuth } from "@/core/plugins/firebase";
import { koruDb } from "@/core/modules/database";
import { useAuthStore } from "../store";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

export class FirebaseAuthModel implements AuthInterface {
  async init(): Promise<void> {
    const authStore = useAuthStore();
    try {
      return new Promise<void>((resolve) => {
        firebaseAuth.onAuthStateChanged(async (user: firebase.User | null): Promise<void> => {
          if (user) {
            const dbUser: UserInterface = await koruDb.getModule<UserModelInterface>("users").getDocument(user.uid);
            authStore.commit(authStoreTypes.mutations.loginSuccess, dbUser);
            resolve();
          } else {
            authStore.commit(authStoreTypes.mutations.loginError);
            resolve();
          }
        });
      });
    } catch {
      authStore.commit(authStoreTypes.mutations.loadingStop);
      this.logout();
    }
  }

  async login(email: string, password: string): Promise<UserInterface> {
    try {
      const userCredential: firebase.auth.UserCredential = await firebaseAuth.signInWithEmailAndPassword(email, password);
      if (userCredential.user !== null) {
        const user: UserInterface = await koruDb.getModule<UserModelInterface>("users").getDocument(userCredential.user.uid);
        return user;
      }
      throw new Error("User credentials are null");
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  async logout(): Promise<void> {
    try {
      await firebaseAuth.signOut();
    } catch {
      throw new Error("Unable to logout");
    }
  }

  async forgotPassword(email: string): Promise<void> {
    try {
      await firebaseAuth.sendPasswordResetEmail(email);
    } catch {
      throw new Error("Unable to send password reset email");
    }
  }

  async recoverPassword(code: string, newPassword: string): Promise<void> {
    try {
      await firebaseAuth.confirmPasswordReset(code, newPassword);
    } catch {
      throw new Error("Unable to recover password");
    }
  }

  async updatePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      if (!firebaseAuth.currentUser || !firebaseAuth.currentUser.email) {
        throw new Error("Invalid data");
      }

      const credentials: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(firebaseAuth.currentUser.email, oldPassword);
      await firebaseAuth.currentUser.reauthenticateWithCredential(credentials);

      if (firebaseAuth.currentUser == null) {
        throw new Error("Unable to reauthenticate");
      }

      await firebaseAuth.currentUser.updatePassword(newPassword);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  async updateEmail(newEmail: string, password: string): Promise<void> {
    const authStore = useAuthStore();

    try {
      if (!firebaseAuth.currentUser || !firebaseAuth.currentUser.email) {
        throw new Error("Invalid data");
      }

      const credentials: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(firebaseAuth.currentUser.email, password);
      await firebaseAuth.currentUser.reauthenticateWithCredential(credentials);

      if (firebaseAuth.currentUser == null) {
        throw new Error("Unable to reauthenticate");
      }

      await firebaseAuth.currentUser.updateEmail(newEmail);

      const currentUser: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
      currentUser.email = newEmail;
      currentUser.updatedAt = new Date();
      currentUser.updatedBy = currentUser.id;
      await koruDb.getModule<UserModelInterface>("users").updateDocument(currentUser, false);

      const newCredentials: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(newEmail, password);
      await firebaseAuth.currentUser.reauthenticateWithCredential(newCredentials);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  isLoggedIn(): boolean {
    return firebaseAuth.currentUser ? true : false;
  }

  async registerUser(user: UserInterface): Promise<string> {
    try {
      return user.id;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  async updateUser(): Promise<void> {
    try {
      return;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  async blockUser(): Promise<void> {
    try {
      return;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  async unblockUser(): Promise<void> {
    try {
      return;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  async deleteUser(): Promise<void> {
    try {
      return;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }
}
