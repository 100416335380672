
import { defineComponent } from "vue";

export default defineComponent({
  name: "KoruContainer",
  props: {
    type: { type: String, default: "full" },
    title: { type: String, default: undefined },
  },
  setup(props) {
    const classes = {
      // eslint-disable-next-line prettier/prettier
      "card": true,
      "w-full": true,
      "xl:w-8": props.type == "boxed",
    };

    return {
      classes,
    };
  },
});
