import { delay } from "./function-helpers";

export async function resizeImageWithCenteredCrop(file: File, targetWidth: number, targetHeight: number): Promise<string | undefined> {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const img = document.createElement("img");

  let resizedImageData: string | undefined = undefined;

  img.onload = function () {
    let sourceWidth = img.width;
    let sourceHeight = img.height;
    let sourceCropX = 0;
    let sourceCropY = 0;

    const sourceRatio = sourceWidth / sourceHeight;
    const targetRatio = targetWidth / targetHeight;

    if (sourceRatio >= targetRatio) {
      sourceWidth = sourceHeight * targetRatio;
      sourceCropX = Math.max((img.width - sourceWidth) / 2, 0);
    } else {
      sourceHeight = sourceWidth / targetRatio;
      sourceCropY = Math.max((img.height - sourceHeight) / 2, 0);
    }

    canvas.width = targetWidth;
    canvas.height = targetHeight;
    context?.drawImage(img, sourceCropX, sourceCropY, sourceWidth, sourceHeight, 0, 0, targetWidth, targetHeight);
    resizedImageData = canvas.toDataURL("image/jpeg");
  };
  img.src = URL.createObjectURL(file);
  await delay(1000);
  return resizedImageData;
}

export async function dataUrlToFile(url: string, fileName: string, mimeType: string) {
  const response: Response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();

  return new File([arrayBuffer], fileName, { type: mimeType });
}
