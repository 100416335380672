import { MutationTree } from "vuex";

import { MapPointStateInterface } from "../models/MapPointState.interface";
import { mapPointStoreTypes } from "./types";

export const mutations: MutationTree<MapPointStateInterface> = {
  [mapPointStoreTypes.mutations.uploadStart]: (state: MapPointStateInterface): void => {
    state.isUploading = true;
    state.uploadProgressValue = 0;
  },
  [mapPointStoreTypes.mutations.uploadStop]: (state: MapPointStateInterface): void => {
    state.isUploading = false;
    state.uploadProgressValue = 0;
  },
  [mapPointStoreTypes.mutations.setUploadProgressValue]: (state: MapPointStateInterface, value: number): void => {
    state.uploadProgressValue = value;
  },
};
