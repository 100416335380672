import { ActionTree } from "vuex";

import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { VariableInterface } from "../models/Variable.interface";
import { variableStoreTypes } from "./types";
import { webRenderStore } from "@/features/modules/web-render/store";
import { webRenderStoreTypes } from "@/features/modules/web-render/store/types";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [variableStoreTypes.actions.getVariables](): Promise<VariableInterface[]> {
    try {
      return await koruDb.getModule<DatabaseModuleInterface<VariableInterface>>("variables").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [variableStoreTypes.actions.getVariable](context, variableId: string): Promise<VariableInterface> {
    try {
      return await koruDb.getModule<DatabaseModuleInterface<VariableInterface>>("variables").getDocument(variableId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [variableStoreTypes.actions.createVariable](context, variable: VariableInterface): Promise<string> {
    try {
      setDocumentFields(variable.id, variable);
      return await koruDb.getModule<DatabaseModuleInterface<VariableInterface>>("variables").createDocument(variable, true);

      await webRenderStore.action(webRenderStoreTypes.actions.processWebRenderForGeneralUpdate);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [variableStoreTypes.actions.updateVariable](context, variable: VariableInterface): Promise<void> {
    try {
      const oldVariable: VariableInterface = await koruDb.getModule<DatabaseModuleInterface<VariableInterface>>("variables").getDocument(variable.id);
      if (hasDocumentChanged(variable, oldVariable)) throw new Error("sync");

      setDocumentFields(variable.id, variable);
      await koruDb.getModule<DatabaseModuleInterface<VariableInterface>>("variables").updateDocument(variable, true);

      await webRenderStore.action(webRenderStoreTypes.actions.processWebRenderForGeneralUpdate);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [variableStoreTypes.actions.deleteVariable](context, variable: VariableInterface): Promise<void> {
    try {
      await koruDb.getModule<DatabaseModuleInterface<VariableInterface>>("variables").deleteDocument(variable, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
