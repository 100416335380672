import { GetterTree } from "vuex";

import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { koruStoreTypes } from "@/core/modules/store/types";
import { MapPointStateInterface } from "../models/MapPointState.interface";
import { mapPointStoreTypes } from "./types";

export const getters: GetterTree<MapPointStateInterface, KoruStateInterface> = {
  [mapPointStoreTypes.getters.isLoading]: (state: MapPointStateInterface, getters: GetterTree<KoruStateInterface, KoruStateInterface>): boolean => {
    return getters[koruStoreTypes.getters.isLoading] as unknown as boolean;
  },
  [mapPointStoreTypes.getters.isUploading]: (state: MapPointStateInterface): boolean => {
    return state.isUploading;
  },
  [mapPointStoreTypes.getters.getUploadProgressValue]: (state: MapPointStateInterface): number => {
    return state.uploadProgressValue;
  },
};
