import { RouteRecordRaw } from "vue-router";

export const mapRoutes: Array<RouteRecordRaw> = [
  {
    path: "/maps",
    name: "MapList",
    component: () => import("../views/MapList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "map",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/maps/:id",
    name: "MapEdit",
    component: () => import("../views/MapEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "map",
      requiredRights: ["readAll", "readOwn", "updateAll", "updateOwn"],
    },
  },
];
