export const workCategoryStoreTypes = {
  actions: {
    getWorkCategories: "GET_WORK_CATEGORIES",
    getWorkCategory: "GET_WORK_CATEGORY",
    createWorkCategory: "CREATE_WORK_CATEGORY",
    updateWorkCategory: "UPDATE_WORK_CATEGORY",
    processWorkCategoryUpdate: "PROCESS_WORK_CATEGORY_UPDATE",
    processWorkCategoriesUpdate: "PROCESS_WORK_CATEGORIES_UPDATE",
    deleteWorkCategory: "DELETE_WORK_CATEGORY",
    reorderWorkCategories: "REORDER_WORK_CATEGORIES",
  },
};
