import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruBatch } from "@/core/modules/batch";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { MapInterface } from "@/features/modules/map/models/Map.interface";
import { mapStore } from "@/features/modules/map/store";
import { mapStoreTypes } from "@/features/modules/map/store/types";
import { PageInterface } from "@/features/modules/page/models/Page.interface";
import { pageStore } from "@/features/modules/page/store";
import { pageStoreTypes } from "@/features/modules/page/store/types";
import { SettingInterface } from "@/features/modules/setting/models/Setting.interface";
import { settingStore } from "@/features/modules/setting/store";
import { settingStoreTypes } from "@/features/modules/setting/store/types";
import { TemplateInterface } from "@/features/modules/template/models/Template.interface";
import { templateStore } from "@/features/modules/template/store";
import { templateStoreTypes } from "@/features/modules/template/store/types";
import { VariableInterface } from "@/features/modules/variable/models/Variable.interface";
import { variableStore } from "@/features/modules/variable/store";
import { variableStoreTypes } from "@/features/modules/variable/store/types";
import { WebDocumentInterface } from "@/features/modules/web-document/models/WebDocument.interface";
import { WebRenderInterface, webRenderToFirestore } from "../models/WebRender.interface";
import { WebRenderModelInterface } from "../models/WebRenderModel.interface";
import { webRenderStoreTypes } from "./types";

import { firebaseFirestore } from "@/core/plugins/firebase";

import {
  additionalUpdateAllWebDocuments,
  additionalUpdateWebDocumentsByMap,
  additionalUpdateWebDocumentsByTemplates,
} from "@/features/modules/additional";
import { createWebRenderFromWebDocument } from "@/features/modules/helpers";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [webRenderStoreTypes.actions.getWebRenderByTypeAndReferenceAndLanguage](
    context,
    payload: Record<string, string>
  ): Promise<WebRenderInterface | undefined> {
    try {
      return await koruDb
        .getModule<WebRenderModelInterface>("webRenders")
        .getWebRenderByTypeAndReferenceAndLanguage(payload.type, payload.reference, payload.language);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async [webRenderStoreTypes.actions.processWebRenderForWebDocument](context, payload: Record<string, unknown>): Promise<WebDocumentInterface> {
    try {
      const webDocument: WebDocumentInterface = payload.webDocument as WebDocumentInterface;
      // eslint-disable-next-line prettier/prettier
      const contentRenderer: ((language: string) => Promise<string>) | undefined = payload.contentRenderer as ((language: string) => Promise<string>) | undefined;
      // eslint-disable-next-line prettier/prettier
      const customVariables: ((language: string) => Record<string, string>) | undefined = payload.customVariables as ((language: string) => Record<string, string>) | undefined;

      if (webDocument == undefined) {
        throw new Error("invalid parameters");
      }

      const templates: TemplateInterface[] = (await templateStore.action(templateStoreTypes.actions.getTemplates)) as TemplateInterface[];
      const variables: VariableInterface[] = (await variableStore.action(variableStoreTypes.actions.getVariables)) as VariableInterface[];
      const maps: MapInterface[] = (await mapStore.action(mapStoreTypes.actions.getMaps)) as MapInterface[];

      const settings: SettingInterface = (await settingStore.action(settingStoreTypes.actions.getSetting)) as SettingInterface;

      const result: Record<string, unknown> = await createWebRenderFromWebDocument(
        webDocument,
        templates,
        variables,
        maps,
        settings,
        contentRenderer,
        customVariables
      );
      const updatedWebDocument: WebDocumentInterface = result.webDocument as WebDocumentInterface;
      const webRenders: WebRenderInterface[] = result.webRenders as WebRenderInterface[];

      const koruBatch = new KoruBatch();
      for (const webRender of webRenders) {
        if (webRender.id == "new") {
          koruBatch.set(firebaseFirestore.collection("webRenders").doc(), webRenderToFirestore(webRender));
        } else {
          koruBatch.update(firebaseFirestore.collection("webRenders").doc(webRender.id), webRenderToFirestore(webRender));
        }
      }
      await koruBatch.commit();

      return updatedWebDocument;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async [webRenderStoreTypes.actions.processWebRenderForWebDocuments](context, payload: Record<string, unknown>): Promise<void> {
    try {
      const webDocuments: WebDocumentInterface[] = payload.webDocuments as WebDocumentInterface[];
      // eslint-disable-next-line prettier/prettier
      const contentRenderer: ((language: string) => Promise<string>) | undefined = payload.contentRenderer as ((language: string) => Promise<string>) | undefined;
      // eslint-disable-next-line prettier/prettier
      const customVariables: ((language: string) => Record<string, string>) | undefined = payload.customVariables as ((language: string) => Record<string, string>) | undefined;

      if (webDocuments == undefined) {
        throw new Error("invalid parameters");
      }

      const templates: TemplateInterface[] = (await templateStore.action(templateStoreTypes.actions.getTemplates)) as TemplateInterface[];
      const variables: VariableInterface[] = (await variableStore.action(variableStoreTypes.actions.getVariables)) as VariableInterface[];
      const maps: MapInterface[] = (await mapStore.action(mapStoreTypes.actions.getMaps)) as MapInterface[];

      const settings: SettingInterface = (await settingStore.action(settingStoreTypes.actions.getSetting)) as SettingInterface;

      const koruBatch = new KoruBatch();

      for (const webDocument of webDocuments) {
        const result: Record<string, unknown> = await createWebRenderFromWebDocument(
          webDocument,
          templates,
          variables,
          maps,
          settings,
          contentRenderer,
          customVariables
        );

        const webRenders: WebRenderInterface[] = result.webRenders as WebRenderInterface[];

        for (const webRender of webRenders) {
          if (webRender.id == "new") {
            koruBatch.set(firebaseFirestore.collection("webRenders").doc(), webRenderToFirestore(webRender));
          } else {
            koruBatch.update(firebaseFirestore.collection("webRenders").doc(webRender.id), webRenderToFirestore(webRender));
          }
        }
      }

      await koruBatch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async [webRenderStoreTypes.actions.processWebRenderForBlockUpdate](context, blockId: string): Promise<void> {
    try {
      const templates: TemplateInterface[] = (await templateStore.action(
        templateStoreTypes.actions.getTemplatesByBlock,
        blockId
      )) as TemplateInterface[];

      const pagesBox: PageInterface[] = [];
      for (const template of templates) {
        const pages: PageInterface[] = (await pageStore.action(pageStoreTypes.actions.getPagesByTemplate, template.id)) as PageInterface[];
        pagesBox.push(...pages);
      }
      await context.dispatch(webRenderStoreTypes.actions.processWebRenderForWebDocuments, { webDocuments: pagesBox });

      await additionalUpdateWebDocumentsByTemplates(templates);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async [webRenderStoreTypes.actions.processWebRenderForTemplateUpdate](context, templateId: string): Promise<void> {
    try {
      const template: TemplateInterface = (await templateStore.action(templateStoreTypes.actions.getTemplate, templateId)) as TemplateInterface;

      const pages: PageInterface[] = (await pageStore.action(pageStoreTypes.actions.getPagesByTemplate, template.id)) as PageInterface[];
      await context.dispatch(webRenderStoreTypes.actions.processWebRenderForWebDocuments, { webDocuments: pages });

      await additionalUpdateWebDocumentsByTemplates([template]);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async [webRenderStoreTypes.actions.processWebRenderForMapUpdate](context, mapId: string): Promise<void> {
    try {
      const map: MapInterface = (await mapStore.action(mapStoreTypes.actions.getMap, mapId)) as MapInterface;

      const pages: PageInterface[] = (await pageStore.action(
        pageStoreTypes.actions.getPagesByMapReferenceNumber,
        map.referenceNumber
      )) as PageInterface[];
      await context.dispatch(webRenderStoreTypes.actions.processWebRenderForWebDocuments, { webDocuments: pages });

      await additionalUpdateWebDocumentsByMap(map);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async [webRenderStoreTypes.actions.processWebRenderForGeneralUpdate](context): Promise<void> {
    try {
      const pages: PageInterface[] = (await pageStore.action(pageStoreTypes.actions.getPages)) as PageInterface[];
      await context.dispatch(webRenderStoreTypes.actions.processWebRenderForWebDocuments, { webDocuments: pages });

      await additionalUpdateAllWebDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async [webRenderStoreTypes.actions.deleteWebRender](context, webRender: WebRenderInterface): Promise<void> {
    try {
      await koruDb.getModule<WebRenderModelInterface>("webRenders").deleteDocument(webRender, false);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async [webRenderStoreTypes.actions.deleteWebRenderForWebDocument](context, payload: Record<string, unknown>): Promise<void> {
    try {
      const webDocument: WebDocumentInterface = payload.webDocument as WebDocumentInterface;

      if (webDocument == undefined) {
        throw new Error("invalid parameters");
      }

      const settings: SettingInterface = (await settingStore.action(settingStoreTypes.actions.getSetting)) as SettingInterface;
      for (const language of settings.languages) {
        const webRender: WebRenderInterface | undefined = await koruDb
          .getModule<WebRenderModelInterface>("webRenders")
          .getWebRenderByTypeAndReferenceAndLanguage(webDocument.interfaceType, webDocument.id, language);

        if (webRender != undefined) {
          await koruDb.getModule<WebRenderModelInterface>("webRenders").deleteDocument(webRender, false);
        }
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
