import { createStoreModule } from "@/core/modules/helpers";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";
import { MapPointStateInterface } from "../models/MapPointState.interface";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";
import { mutations } from "./mutations";

// eslint-disable-next-line prettier/prettier
export const mapPointStore: KoruStoreModuleInterface<MapPointStateInterface> = createStoreModule(
  "mapPointState",
  initialState,
  getters,
  actions,
  mutations
);

export const useMapPointStore = (): KoruStoreModuleInterface<MapPointStateInterface> => {
  return mapPointStore;
};
