import { koruConfig } from "@/core/modules/config";

import { DatabaseInterface } from "./models/Database.interface";

let db: DatabaseInterface;
if (koruConfig.database.type === "live") {
  db = {
    modules: {},
    getModule: function <T>(collectionName: string): T {
      return this.modules[collectionName] as T;
    },
  };
} else {
  throw Error("Invalid or undefined config.database.type");
}

export const koruDb = db;

export const useKoruDb = (): DatabaseInterface => {
  return koruDb;
};
