import { ActionTree } from "vuex";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { BackupInterface } from "../models/Backup.interface";
import { BackupStateInterface } from "../models/BackupState.interface";
import { backupStoreTypes } from "./types";
import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";

import { importBackup } from "@/core/plugins/import-export";

export const actions: ActionTree<BackupStateInterface, KoruStateInterface> = {
  async [backupStoreTypes.actions.getBackups](): Promise<BackupInterface[]> {
    try {
      return await koruDb.getModule<DatabaseModuleInterface<BackupInterface>>("backups").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [backupStoreTypes.actions.deleteBackup](context, backup: BackupInterface): Promise<void> {
    try {
      await koruDb.getModule<DatabaseModuleInterface<BackupInterface>>("backups").deleteDocument(backup, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [backupStoreTypes.actions.createBackup](): Promise<void> {
    try {
      const createBackup = firebase.functions().httpsCallable("functionsDbBackupCreate");
      createBackup();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [backupStoreTypes.actions.restoreBackup](context): Promise<void> {
    try {
      const options = { autoParseDates: true, autoParseGeos: false, showLogs: true };
      const uploadedData: string | undefined = await context.getters[backupStoreTypes.getters.getUploadedData];
      if (uploadedData == undefined) {
        throw new Error("backupDataNotSet");
      }
      await importBackup(JSON.parse(uploadedData), options);
    } catch (error: unknown) {
      context.commit(backupStoreTypes.mutations.resetUploadedData);
      throw new Error((error as Error).message);
    } finally {
      context.commit(backupStoreTypes.mutations.resetUploadedData);
    }
  },
};
