
import { computed, defineComponent, Ref, watch } from "vue";
import { google } from "google-maps";
import { Loader } from "@googlemaps/js-api-loader";

import { uniqueId } from "@/core/plugins/unique-id";
import { useKoruConfig } from "@/core/modules/config";

export default defineComponent({
  name: "KoruMap",
  props: {
    centerChangeCallback: { type: Function, default: undefined },
    clickCallback: { type: Function, default: undefined },
    height: { type: Number, default: 600 },
    initCallback: { type: Function, default: undefined },
    latitude: { type: Number, default: undefined },
    longitude: { type: Number, default: undefined },
    mapObject: { type: Object, default: undefined },
    mapType: { type: String, default: undefined },
    mapTypeChangeCallback: { type: Function, default: undefined },
    theme: { type: Array, default: undefined },
    zoom: { type: Number, default: undefined },
    zoomChangeCallback: { type: Function, default: undefined },
  },
  emits: ["update:mapObject"],
  setup(props, { emit }) {
    const koruConfig = useKoruConfig();

    const elementId = "map-" + uniqueId();

    const mapObjectRef: Ref<google.maps.Map> = computed({
      get: () => props.mapObject as google.maps.Map,
      set: (val) => emit("update:mapObject", val),
    });

    const mapTypeRef = computed(() => props.mapType);
    const latitudeRef = computed(() => props.latitude);
    const longitudeRef = computed(() => props.longitude);
    const themeRef = computed(() => props.theme as google.maps.MapTypeStyle[]);
    const zoomRef = computed(() => props.zoom);

    const mapTypeId: string = props.mapType != undefined ? props.mapType : "roadmap";

    const mapCenter: google.maps.LatLngLiteral = {
      lat: props.latitude != undefined ? props.latitude : (koruConfig.settings.mapsDefaultLatitude as number),
      lng: props.longitude != undefined ? props.longitude : (koruConfig.settings.mapsDefaultLongitude as number),
    };
    const mapZoom: number = props.zoom != undefined ? props.zoom : (koruConfig.settings.mapsDefaultZoom as number);

    const loader = new Loader({
      apiKey: koruConfig.mapsApiKey,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      const mapDiv: HTMLElement = document.getElementById(elementId) as HTMLElement;
      const map: google.maps.Map = new window.google.maps.Map(mapDiv, {
        mapTypeId: mapTypeId,
        center: mapCenter,
        zoom: mapZoom,
        streetViewControl: false,
        mapTypeControlOptions: {
          style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          mapTypeIds: ["roadmap", "satellite", "hybrid", "terrain"],
        },
      });
      map.addListener("maptypeid_changed", () => {
        if (props.mapTypeChangeCallback != undefined) {
          props.mapTypeChangeCallback(map.getMapTypeId());
        }
      });
      map.addListener("dragend", () => {
        if (props.centerChangeCallback != undefined) {
          props.centerChangeCallback(map.getCenter());
        }
      });
      map.addListener("zoom_changed", () => {
        if (props.zoomChangeCallback != undefined) {
          props.zoomChangeCallback(map.getZoom());
        }
      });
      map.addListener("click", (e) => {
        if (props.clickCallback != undefined) {
          props.clickCallback(e.latLng);
        }
      });

      if (props.initCallback != undefined) {
        props.initCallback(map);
      }

      watch(mapTypeRef, (currentValue) => {
        if (currentValue != undefined) {
          map.setMapTypeId(currentValue);
        }
      });

      watch(latitudeRef, (currentValue) => {
        if (currentValue != undefined && longitudeRef.value != undefined) {
          map.setCenter(new window.google.maps.LatLng(currentValue as number, longitudeRef.value as number));
        }
      });

      watch(longitudeRef, (currentValue) => {
        if (latitudeRef.value != undefined && currentValue != undefined) {
          map.setCenter(new window.google.maps.LatLng(latitudeRef.value as number, currentValue as number));
        }
      });

      watch(themeRef, (currentValue) => {
        if (currentValue != undefined) {
          map.setOptions({ styles: currentValue });
        }
      });

      watch(zoomRef, (currentValue) => {
        if (currentValue != undefined) {
          map.setZoom(currentValue);
        }
      });

      mapObjectRef.value = map;
    });

    return {
      elementId,
    };
  },
});
