export const backupStoreTypes = {
  getters: {
    getUploadedData: "GET_UPLOADED_DATA",
  },
  actions: {
    getBackups: "GET_BACKUPS",
    createBackup: "CREATE_BACKUP",
    deleteBackup: "DELETE_BACKUP",
    restoreBackup: "RESTORE_BACKUP",
  },
  mutations: {
    resetUploadedData: "RESET_UPLOADED_DATA",
    setUploadedData: "SET_UPLOADED_DATA",
  },
};
