export interface LinkedWorkCategoryInterface {
  id: string;
  name: string;
  title: Record<string, string>;
  slug: Record<string, string>;
}

export const createNewLinkedWorkCategory = (): LinkedWorkCategoryInterface => {
  return {
    id: "new",
    name: "",
    title: {},
    slug: {},
  };
};

export function linkedWorkCategoryFromFirestore(data: Record<string, unknown>): LinkedWorkCategoryInterface {
  return {
    id: (data.id as string) || "new",
    name: (data.name as string) || "",
    title: (data.title as Record<string, string>) || {},
    slug: (data.slug as Record<string, string>) || {},
  };
}

export function linkedWorkCategoryToFirestore(data: LinkedWorkCategoryInterface): Record<string, unknown> {
  return {
    id: data.id,
    name: data.name || null,
    title: data.title,
    slug: data.slug,
  };
}
