import { ActionTree } from "vuex";

import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { LogInterface } from "../models/Log.interface";
import { logStoreTypes } from "./types";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [logStoreTypes.actions.getLogs](): Promise<LogInterface[]> {
    try {
      return await koruDb.getModule<DatabaseModuleInterface<LogInterface>>("logs").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
