import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { NotificationInterface, notificationFromFirestore, notificationToFirestore } from "@/core/modules/notification/models/Notification.interface";
import { NotificationModelInterface } from "../NotificationModel.interface";

import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  getDocumentsHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";

export const notificationModel: NotificationModelInterface = {
  collectionName: "notifications",
  documentFromFirestore: notificationFromFirestore,
  documentToFirestore: notificationToFirestore,

  async getDocuments(): Promise<NotificationInterface[]> {
    return await getDocumentsHelper<NotificationInterface>(
      this.collectionName,
      "createdAt",
      "desc",
      this.documentFromFirestore,
      this.documentToFirestore
    );
  },
  async getDocument(documentId: string): Promise<NotificationInterface> {
    return await getDocumentHelper<NotificationInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: NotificationInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<NotificationInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(document: NotificationInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<NotificationInterface>(
      document,
      linkedUpdates,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async deleteDocument(document: NotificationInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },

  async getNotificationsByOwner(userId: string): Promise<NotificationInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<NotificationInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("owners", "array-contains", userId)
        .orderBy("createdAt", "desc")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: NotificationInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getUnreadNotifications(userId: string): Promise<NotificationInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<NotificationInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("unread", "array-contains", userId)
        .orderBy("createdAt", "desc")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: NotificationInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async markNotificationAsRead(notificationId: string, userId: string): Promise<void> {
    try {
      const notification: NotificationInterface = await this.getDocument(notificationId);

      const readIndex = notification.unread.indexOf(userId);
      if (readIndex < 0) {
        throw new Error(`User #${userId} not found in notification #${notificationId}`);
      }
      notification.unread.splice(readIndex, 1);

      await this.updateDocument(notification, false);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async markNotificationAsDeleted(notificationId: string, userId: string): Promise<void> {
    try {
      const notification: NotificationInterface = await this.getDocument(notificationId);

      const readIndex = notification.unread.indexOf(userId);
      if (readIndex >= 0) {
        notification.unread.splice(readIndex, 1);
      }

      const deleteIndex = notification.owners.indexOf(userId);
      if (deleteIndex < 0) {
        throw new Error(`User #${userId} not found in notification #${notificationId}`);
      }
      notification.owners.splice(deleteIndex, 1);

      if (notification.owners.length == 0) {
        await this.deleteDocument(notification, false);
      } else {
        await this.updateDocument(notification, false);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

const linkedUpdates = async (): Promise<void> => {
  Promise.resolve();
};
