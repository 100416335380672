import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, createVNode as _createVNode, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "layout-menu",
  role: "menu"
}
const _hoisted_2 = { class: "layout-menuitem-text" }
const _hoisted_3 = {
  key: 0,
  class: "pi pi-fw pi-angle-down layout-submenu-toggler"
}
const _hoisted_4 = ["href", "target", "onClick", "onMouseenter"]
const _hoisted_5 = { class: "layout-menuitem-text" }
const _hoisted_6 = {
  key: 0,
  class: "pi pi-fw pi-angle-down layout-submenu-toggler"
}
const _hoisted_7 = {
  key: 2,
  class: "layout-root-menuitem"
}
const _hoisted_8 = {
  class: "layout-menuitem-root-text",
  style: {"text-transform":"uppercase"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_submenu = _resolveComponent("submenu")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_ctx.items)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (_ctx.visible(item) && (_ctx.hasVisibleChildren(item) || _ctx.isChildVisible(item)))
              ? (_openBlock(), _createElementBlock("li", {
                  key: item.label || i,
                  class: _normalizeClass([
          {
            'layout-root-menuitem': _ctx.root,
            'active-menuitem': _ctx.activeIndex === i && !item.disabled,
          },
        ]),
                  role: "menuitem"
                }, [
                  (item.to)
                    ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: item.to,
                        style: _normalizeStyle(item.style),
                        class: _normalizeClass([item.class, 'p-ripple', { 'p-disabled': item.disabled }]),
                        "active-class": "active-route",
                        target: item.target,
                        exact: "",
                        onClick: ($event: any) => (_ctx.onMenuItemClick($event, item, i)),
                        onMouseenter: ($event: any) => (_ctx.onMenuItemMouseEnter(i))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("i", {
                            class: _normalizeClass(['layout-menuitem-icon', item.icon])
                          }, null, 2),
                          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t(`navigation.${item.label}`)), 1),
                          (item.items)
                            ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["to", "style", "class", "target", "onClick", "onMouseenter"])), [
                        [_directive_ripple]
                      ])
                    : _createCommentVNode("", true),
                  (!item.to)
                    ? _withDirectives((_openBlock(), _createElementBlock("a", {
                        key: 1,
                        href: item.url || '#',
                        style: _normalizeStyle(item.style),
                        class: _normalizeClass([item.class, 'p-ripple', { 'p-disabled': item.disabled }]),
                        target: item.target,
                        onClick: ($event: any) => (_ctx.onMenuItemClick($event, item, i)),
                        onMouseenter: ($event: any) => (_ctx.onMenuItemMouseEnter(i))
                      }, [
                        _createElementVNode("i", {
                          class: _normalizeClass(['layout-menuitem-icon', item.icon])
                        }, null, 2),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t(`navigation.${item.label}`)), 1),
                        (item.items)
                          ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                          : _createCommentVNode("", true)
                      ], 46, _hoisted_4)), [
                        [_directive_ripple]
                      ])
                    : _createCommentVNode("", true),
                  (_ctx.root)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t(`navigation.${item.label}`)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_Transition, { name: "layout-menu" }, {
                    default: _withCtx(() => [
                      _withDirectives(_createVNode(_component_submenu, {
                        items: _ctx.visible(item) && item.items,
                        menuActive: _ctx.menuActive,
                        layoutMode: _ctx.layoutMode,
                        parentMenuItemActive: _ctx.activeIndex === i,
                        onMenuitemClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('menuitem-click', $event)))
                      }, null, 8, ["items", "menuActive", "layoutMode", "parentMenuItemActive"]), [
                        [_vShow, item.items && (_ctx.root && (!_ctx.isSlim() || (_ctx.isSlim() && (_ctx.mobileMenuActive || _ctx.activeIndex !== undefined))) ? true : _ctx.activeIndex === i)]
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.visible(item) && !_ctx.isLastItem(i) && _ctx.hasVisibleChildren(item))
              ? (_openBlock(), _createElementBlock("li", {
                  class: "menu-separator",
                  style: _normalizeStyle(item.style),
                  key: 'separator' + i,
                  role: "separator"
                }, null, 4))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}