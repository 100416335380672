export const sectionStoreTypes = {
  getters: {
    getUploadProgressValue: "GET_UPLOAD_PROGRESS_VALUE",
    isLoading: "IS_LOADING",
    isUploading: "IS_UPLOADING",
  },
  actions: {
    uploadImage: "UPLOAD_IMAGE",
    deleteImage: "DELETE_IMAGE",
  },
  mutations: {
    setUploadProgressValue: "SET_UPLOAD_PROGRESS_VALUE",
    uploadStart: "UPLOAD_START",
    uploadStop: "UPLOAD_STOP",
  },
};
