import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";

export interface LogInterface extends DocumentInterface {
  interfaceType: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
  };
  firstName: string;
  message: string;
  type: string;
}

export const createNewLog = (): LogInterface => {
  return createDocument<LogInterface>({
    interfaceType: "Log",
    user: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
    },
    message: undefined,
    type: undefined,
  });
};

export function logFromFirestore(data: Record<string, unknown>, id?: string): LogInterface {
  return documentFromFirestore<LogInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Log",
      user: {
        firstName: (data.user as Record<string, string>).firstName,
        lastName: (data.user as Record<string, string>).lastName,
        email: (data.user as Record<string, string>).email,
      },
      message: data.message as string,
      type: data.type as string,
    },
    data
  );
}

export function logToFirestore(log: LogInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      user: {
        firstName: log.user.firstName,
        lastName: log.user.lastName,
        email: log.user.email,
      },
      message: log.message,
      type: log.type,
    },
    log
  );
}
