import { koruConfig } from "@/core/modules/config";
import { koruRouter } from "@/core/modules/router";

export function resetLocalStorageIfNeeded(): void {
  if (!localStorage.getItem("koruVersion")) {
    localStorage.clear();
    localStorage.setItem("koruVersion", koruConfig.app.version);
  }

  const currentAppVersion: string = localStorage.getItem("koruVersion") as string;

  console.log("KORU Version", currentAppVersion);

  if (currentAppVersion !== koruConfig.app.version) {
    localStorage.clear();
    localStorage.setItem("koruVersion", koruConfig.app.version);
  }
}

export function goToErrorPage(error: string): void {
  localStorage.setItem("lastError", error);
  koruRouter.push("/error");
}
