import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _renderSlot(_ctx.$slots, "title", {}, () => [
      (_ctx.$props.title != undefined)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.$props.title), 1))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}