import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";

export interface RoleInterface extends DocumentInterface {
  name: string | undefined;
  readAll: string[];
  readOwn: string[];
  create: string[];
  updateAll: string[];
  updateOwn: string[];
  deleteAll: string[];
  deleteOwn: string[];
}

export const createNewRole = (): RoleInterface => {
  return createDocument<RoleInterface>({
    interfaceType: "Role",
    name: undefined,
    readAll: [],
    readOwn: [],
    create: [],
    updateAll: [],
    updateOwn: [],
    deleteAll: [],
    deleteOwn: [],
  });
};

export function roleFromFirestore(data: Record<string, unknown>, id?: string): RoleInterface {
  return documentFromFirestore<RoleInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Role",
      name: data.name || undefined,
      readAll: data.readAll || [],
      readOwn: data.readOwn || [],
      create: data.create || [],
      updateAll: data.updateAll || [],
      updateOwn: data.updateOwn || [],
      deleteAll: data.deleteAll || [],
      deleteOwn: data.deleteOwn || [],
    },
    data
  );
}

export function roleToFirestore(role: RoleInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      name: role.name || null,
      readAll: role.readAll || [],
      readOwn: role.readOwn || [],
      create: role.create || [],
      updateAll: role.updateAll || [],
      updateOwn: role.updateOwn || [],
      deleteAll: role.deleteAll || [],
      deleteOwn: role.deleteOwn || [],
    },
    role
  );
}
