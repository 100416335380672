import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { MapInterface } from "../models/Map.interface";
import { MapModelInterface } from "../models/MapModel.interface";
import { MapPointInterface } from "@/features/modules/map-point/models/MapPoint.interface";
import { mapPointStore } from "@/features/modules/map-point/store";
import { mapPointStoreTypes } from "@/features/modules/map-point/store/types";
import { mapStoreTypes } from "./types";
import { SettingInterface } from "@/features/modules/setting/models/Setting.interface";
import { settingStore } from "@/features/modules/setting/store";
import { settingStoreTypes } from "@/features/modules/setting/store/types";
import { webRenderStore } from "@/features/modules/web-render/store";
import { webRenderStoreTypes } from "@/features/modules/web-render/store/types";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";

import mapStyles from "@/data/map-styles.json";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [mapStoreTypes.actions.getMaps](): Promise<MapInterface[]> {
    try {
      return await koruDb.getModule<MapModelInterface>("maps").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapStoreTypes.actions.getMap](context, mapId: string): Promise<MapInterface> {
    try {
      return await koruDb.getModule<MapModelInterface>("maps").getDocument(mapId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapStoreTypes.actions.getMapByReferenceNumber](context, referenceNumber: number): Promise<MapInterface | undefined> {
    try {
      return await koruDb.getModule<MapModelInterface>("maps").getMapByReferenceNumber(referenceNumber);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapStoreTypes.actions.createMap](context, map: MapInterface): Promise<string> {
    try {
      setDocumentFields(map.id, map);
      return await koruDb.getModule<MapModelInterface>("maps").createDocument(map, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapStoreTypes.actions.updateMap](context, map: MapInterface): Promise<void> {
    try {
      const oldMap: MapInterface = await koruDb.getModule<MapModelInterface>("maps").getDocument(map.id);
      if (hasDocumentChanged(map, oldMap)) throw new Error("sync");

      setDocumentFields(map.id, map);
      await koruDb.getModule<MapModelInterface>("maps").updateDocument(map, true);

      await webRenderStore.action(webRenderStoreTypes.actions.processWebRenderForMapUpdate, map.id);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapStoreTypes.actions.processMapCode](context, map: MapInterface): Promise<void> {
    try {
      let referenceNumber = 1;
      const maps: MapInterface[] = await koruDb.getModule<MapModelInterface>("maps").getDocuments();
      while (maps.find((searchMap) => searchMap.referenceNumber == referenceNumber && searchMap.id != map.id)) {
        referenceNumber++;
      }
      map.referenceNumber = referenceNumber;

      let styles: google.maps.MapTypeStyle[] = mapStyles.standard as google.maps.MapTypeStyle[];
      for (const [key, value] of Object.entries(mapStyles)) {
        if (key == map.theme) {
          styles = value as google.maps.MapTypeStyle[];
          break;
        }
      }

      const settings: SettingInterface = (await settingStore.action(settingStoreTypes.actions.getSetting)) as SettingInterface;

      const mapPoints: MapPointInterface[] = (await mapPointStore.action(
        mapPointStoreTypes.actions.getMapPointsByMap,
        map.id
      )) as MapPointInterface[];

      map.code = {};
      for (const language of settings.languages) {
        map.code[language] = `<div id="map-${referenceNumber}" class="map-canvas inti-google-maps"></div>`;
        map.code[language] += '<script type="text/javascript">';
        map.code[language] += `function initMap${referenceNumber}() {const map = new google.maps.Map(`;
        map.code[language] += `document.getElementById("map-${referenceNumber}"), {`;
        map.code[language] += `center: { lat: ${map.center.latitude}, lng: ${map.center.longitude} },`;
        map.code[language] += `zoom: ${map.zoom},`;
        map.code[language] += `styles: ${JSON.stringify(styles)},`;
        map.code[language] += "zoomControl: true,";
        map.code[language] += "mapTypeControl: true,";
        map.code[language] += "scaleControl: false,";
        map.code[language] += "streetViewControl: false,";
        map.code[language] += "rotateControl: false,";
        map.code[language] += "fullscreenControl: true";
        map.code[language] += "});";

        map.code[language] += "const markerIcon = {";
        map.code[language] += 'url: "/assets/images/marker.svg",';
        map.code[language] += "scaledSize: new google.maps.Size(24, 34),";
        map.code[language] += "origin: new google.maps.Point(0, 0),";
        map.code[language] += "anchor: new google.maps.Point(12, 34),";
        map.code[language] += "};";

        map.code[language] += "infoWindow = new google.maps.InfoWindow();";
        map.code[language] += "const markers = [];";

        let markerIndex = 1;
        for (const mapPoint of mapPoints) {
          if (mapPoint.location != undefined) {
            map.code[language] += processMapPointCode(mapPoint, language, markerIndex);

            markerIndex++;
          }
        }

        //map.code[language] += "new MarkerClusterer({ markers, map });";

        map.code[language] += "}";
        map.code[language] += "</script>";
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [mapStoreTypes.actions.deleteMap](context, map: MapInterface): Promise<void> {
    try {
      const mapPoints = (await mapPointStore.action(mapPointStoreTypes.actions.getMapPointsByMap, map.id)) as MapPointInterface[];
      if (mapPoints.length > 0) throw new Error("children");

      await koruDb.getModule<MapModelInterface>("maps").deleteDocument(map, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};

function processMapPointCode(mapPoint: MapPointInterface, language: string, markerIndex: number): string {
  let code = `const marker${markerIndex} = new google.maps.Marker({`;
  code += `position: { lat: ${mapPoint.location.latitude}, lng: ${mapPoint.location.longitude} },`;
  code += "map,";
  code += "icon: markerIcon";
  code += "});";

  let mapPointCode = '<div class="inti-google-maps-marker">';
  mapPointCode += '<div class="inti-google-maps-marker-close" inti-link="close" onclick="infoWindow.close();">';
  mapPointCode += '<svg width="8" height="8" viewBox="0 0 14 14">';
  // eslint-disable-next-line prettier/prettier
  mapPointCode += '<path transform="translate(-18 -13)" d="M32 14.4L30.6 13 25 18.6 19.4 13 18 14.4l5.6 5.6-5.6 5.6 1.4 1.4 5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6z"></path>';
  mapPointCode += "</svg>";
  mapPointCode += "</div>";
  mapPointCode += '<div class="inti-google-maps-marker-header">';
  mapPointCode += `<div class="inti-google-maps-marker-image"><img alt="" src="${mapPoint.image?.url}"></div>`;
  mapPointCode += "</div>";
  mapPointCode += '<div class="inti-google-maps-marker-title-wrapper">';
  mapPointCode += `<span class="inti-google-maps-marker-title">${mapPoint.title[language]}</span>`;
  mapPointCode += "</div>";
  mapPointCode += '<div class="inti-google-maps-marker-content">';
  mapPointCode += '<div class="inti-google-maps-marker-info">';
  mapPointCode += '<div class="inti-google-maps-marker-info-item-address inti-google-maps-marker-info-item">';
  mapPointCode += '<div class="inti-google-maps-marker-info-item-icon-wrapper">';
  mapPointCode += '<svg class="inti-google-maps-marker-info-item-icon" width="12px" height="12px" viewBox="0 0 510 510">';
  // eslint-disable-next-line prettier/prettier
  mapPointCode += '<path d="M255,0C155.55,0,76.5,79.05,76.5,178.5C76.5,311.1,255,510,255,510s178.5-198.9,178.5-331.5C433.5,79.05,354.45,0,255,0zM255,242.25c-35.7,0-63.75-28.05-63.75-63.75s28.05-63.75,63.75-63.75s63.75,28.05,63.75,63.75S290.7,242.25,255,242.25z"></path>';
  mapPointCode += "</svg>";
  mapPointCode += "</div>";
  mapPointCode += `<span class="inti-google-maps-marker-info-item-text">${mapPoint.content[language]}</span>`;
  mapPointCode += "</div>";
  mapPointCode += "</div>";
  mapPointCode += "</div>";
  mapPointCode += "</div>";

  code += `marker${markerIndex}.addListener("click", () => {`;
  code += `infoWindow.setContent("${mapPointCode.replace(/"/g, '\\"')}");`;
  code += `infoWindow.open(map, marker${markerIndex});`;
  code += "});";

  code += `markers.push(marker${markerIndex});`;

  return code;
}
