export const notificationStoreTypes = {
  actions: {
    getNotifications: "GET_NOTIFICATIONS",
    getUnreadNotifications: "GET_UNREAD_NOTIFICATIONS",
    getNotificationsMatrix: "GET_NOTIFICATIONS_MATRIX",
    getNotification: "GET_NOTIFICATION",
    createNotification: "CREATE_NOTIFICATION",
    updateNotification: "UPDATE_NOTIFICATION",
    deleteNotification: "DELETE_NOTIFICATION",
    markNotificationAsRead: "MARK_NOTIFICATION_AS_READ",
    markNotificationAsDeleted: "MARK_NOTIFICATION_AS_DELETED",
  },
};
