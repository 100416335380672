import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export interface LocationInterface {
  latitude: number;
  longitude: number;
}

export function locationFromFirestore(data: firebase.firestore.GeoPoint): LocationInterface {
  return {
    latitude: data.latitude,
    longitude: data.longitude,
  };
}

export function locationToFirestore(location: LocationInterface): firebase.firestore.GeoPoint {
  return new firebase.firestore.GeoPoint(location.latitude, location.longitude);
}
