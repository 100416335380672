export const mapPointStoreTypes = {
  getters: {
    getUploadProgressValue: "GET_UPLOAD_PROGRESS_VALUE",
    isLoading: "IS_LOADING",
    isUploading: "IS_UPLOADING",
  },
  actions: {
    getMapPoints: "GET_MAP_POINTS",
    getMapPointsByMap: "GET_MAP_POINTS_BY_MAP",
    getMapPoint: "GET_MAP_POINT",
    createMapPoint: "CREATE_MAP_POINT",
    updateMapPoint: "UPDATE_MAP_POINT",
    deleteMapPoint: "DELETE_MAP_POINT",
    uploadImage: "UPLOAD_IMAGE",
    deleteImage: "DELETE_IMAGE",
  },
  mutations: {
    setUploadProgressValue: "SET_UPLOAD_PROGRESS_VALUE",
    uploadStart: "UPLOAD_START",
    uploadStop: "UPLOAD_STOP",
  },
};
