export const en = {
  notification: {
    alert: "You have {count} new notification | You have {count} new notifications",
    none: "No unread notification",
    messages: {
      releaseCreated: "È stata rilasciata la versione {message}",
      userCreated: "È stato creato un utente per {message}",
    },
    notifications: "Notifications",
    types: {
      backup: "Backup",
      releaseCreated: "New Releases",
      userCreated: "New Users",
    },
  },
};
