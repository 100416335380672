import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { LinkedUserInterface } from "@/core/modules/user/models/LinkedUser.interface";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setUserCascadeUpdates = async (batch: firebase.firestore.WriteBatch, linkedUser: LinkedUserInterface): Promise<void> => {
  // NIL
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const checkUserDeleteConstraints = async (userId: string): Promise<string | undefined> => {
  return undefined;
};
